/**
 * Import NPM Packages
 */
import React from 'react';

/**
 * Logo Component
 */
const Logo = ({ className }) => {
  return (
    <div className={className ? className : 'logo-block mr-b20'}>
      <a href="/">
        <img src="/assets/images/logo.png" alt="Stemuli" title="Stemuli" />
      </a>
    </div>
  );
};

/**
 * Export Logo Component
 */
export default Logo;
