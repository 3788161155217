/**
 * Import NPM Packages
 */
import React from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Spin, Pagination } from 'antd';

/**
 * Import Redux functions
 */
import { dashboard } from '../../redux/user/user.action';
import {
  getUpcomingEvents,
  getPendingEvents,
  getAllEvents
} from '../../redux/event/event.action';
import { getUsers } from '../../redux/employees/employees.actions';
import {
  dashboardDetails,
  userDetails,
  employees
} from '../../redux/user/user.selector';
import api from '../../axios';

/**
 * Import styles
 */
import './dashboard.styles.scss';

/**
 * Import Components
 */
import DashboardCard from '../dashboard-card/dashboard-card.component';
import { jwtDecode } from '../../jwt-decode';
import Spinner from '../spinner/spinner.component';
import CustomButton from '../custom-button/custom-button.component';
import EventCard from '../events/components/EventCard';
import EmptyEvent from '../events/empty-event/empty-event.component';
import PendingApproval from '../pending-approval/pending-approval.component';
import ListContainer from '../employees/containers/ListContainer';

/**
 * Dashboard Class
 */
class Dashboard extends React.Component {
  state = {
    totalEvents: 0,
    totalVolunteers: 0,
    avg: 0,
    students: 0,
    attendanceMarked: 0,
    eventInfo: [],
    chartData: [],
    isLoading: true,
    isGettingUpcomingEvent: true,
    upcomingEvents: [],
    totalPendingEvent: 0,
    pendingEvents: [],
    upcomingPagination: {},
    pendingPagination: {},
    eventStat: {},
    user: jwtDecode()
  };

  componentDidMount() {
    // this.setState({ isLoading: true });

    this.getUpcomingEventsRequest();
    this.getPendingUsersRequest();

    const user = jwtDecode();

    this.setState({ user });

    console.log('user-state:', this.state.user);

    api
      .get(`/stats/${user._id}`)
      .then((response) => {
        this.setState({ eventStat: response.data.data });
      })
      .catch((err) => {
        console.log('err :', err);

        // this.props.history.push('/events');
      });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateObj = prevState;
    if (nextProps.dashboardInfo !== prevState.dashboardInfo) {
      stateObj.totalEvents =
        nextProps.dashboardInfo?.statusCode === 200
          ? nextProps.dashboardInfo.result.eventsCount
          : 0;

      stateObj.totalVolunteers =
        nextProps.dashboardInfo?.statusCode === 200
          ? nextProps.dashboardInfo.result.volunteersCount
          : 0;

      stateObj.avg =
        nextProps.dashboardInfo?.statusCode === 200
          ? nextProps.dashboardInfo.result.avgVolunteers
          : 0;

      stateObj.students =
        nextProps.dashboardInfo?.statusCode === 200
          ? nextProps.dashboardInfo.result.students
          : 0;

      stateObj.attendanceMarked =
        nextProps.dashboardInfo?.statusCode === 200
          ? nextProps.dashboardInfo.result.attendanceMarked
          : 0;

      stateObj.eventInfo =
        nextProps.dashboardInfo?.statusCode === 200
          ? nextProps.dashboardInfo.result.events
          : [];

      stateObj.chartData =
        nextProps.dashboardInfo?.statusCode === 200
          ? nextProps.dashboardInfo.result.charts
          : [];
    }
    stateObj.isLoading = false;
    return prevState === stateObj ? null : stateObj;
  }

  getPendingUsersRequest = (undefined, page = 1, per_page = 6) => {
    const decoded = jwtDecode(localStorage.getItem('token'));
    console.log('decoded-value:', decoded);
    const params = {
      page,
      per_page,
      current_profile_type: (decoded && decoded.current_profile_type) ? decoded.current_profile_type : 'Admin'
    };

    this.props.getPendingEvents(params)
      .then((res) => {
        console.log('pending-res:', res);
        this.setState({ totalPendingEvent: res.data.data.length });
      });
  };

  getUpcomingEventsRequest = (page = 1, per_page = 5) => {
    const user = jwtDecode();

    const params = {
      page,
      per_page
    };

    if (user.current_profile_type === 'Volunteer') params.invitee_id = user._id;

    this.props
      .getUpcomingEvents(params)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ upcomingEvents: res.data.data });
          this.setState({ upcomingPagination: res.data._meta.pagination });
        }

        this.setState({ isGettingUpcomingEvent: false });
      })
      .catch((err) => {
        console.log('err :', err);
      });
  };

  convertToHighChart = (chartInfo, axis) => {
    if (axis === 'yAxis') {
      const yAxis = [];
      if (chartInfo.length > 0) {
        for (let i = 0; i < chartInfo.length; i++) {
          yAxis.push(chartInfo[i].count);
        }
      }
      return yAxis;
    } else {
      const yAxis = [];
      if (chartInfo.length > 0) {
        for (let i = 0; i < chartInfo.length; i++) {
          yAxis.push(moment(chartInfo[i].event_startdatetime).format('DD MMM'));
        }
      }
      return yAxis;
    }
  };

  handleUpcomingPaginationChange = (page, pageSize) => {
    this.getUpcomingEventsRequest(page, pageSize);
  };

  render() {
    const userDetail = jwtDecode();
    const currentUser = JSON.parse(localStorage.getItem('cte_user'));
    const profileTypes = { Admin: 'District Admin', IndustryPartner: 'Industry Partner', Volunteer: 'Volunteer' };
    const profileType = userDetail.current_profile_type;
    const { user } = this.props;
    console.log('this.props.employees :', this.props.employees);
    // console.log('this.state.pendingEvents :', this.state.pendingEvents);
    const options = {
      chart: {
        type: 'area'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: this.convertToHighChart(this.state.chartData, 'xAxis'),
        tickmarkPlacement: 'on',
        tickInterval: 5,
        title: {
          text: 'Dates'
        }
      },
      yAxis: {
        title: {
          text: 'Number of Events'
        },
        tickInterval: 5
      },
      tooltip: {
        shared: true,
        pointFormat: '{point.y} Events '
      },

      plotOptions: {
        area: {
          pointStart: 0,
          marker: {
            enabled: true,
            radius: 3,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      series: [
        {
          lineColor: 'transparent',
          data: this.convertToHighChart(this.state.chartData, 'yAxis')
        }
      ]
    };
    if (
      user?.verification_status === 'unverified' ||
      user?.verification_status === 'pending'
    ) {
      return <PendingApproval user={user}/>;
    }
    const { event_details, total_logged_hours, total_users } = this.state.eventStat;
    console.log('stats', this.state.eventStat);
    return this.state.isLoading || !user ? (
      <Spinner/>
    ) : (
      <div className="row">
        <div className="col-sm-12">
          {/* <h3 className="page-heading mr-b30">Dashboard</h3> */}
          <div className="row">
            <div className="col-sm-8">
              <div className="event-arrow">
                <h3
                  className="page-heading mr-b30">{currentUser ? profileTypes[currentUser.current_profile_type] : 'District Admin'} Dashboard</h3>
              </div>
            </div>
            {profileType === 'Admin' || profileType === 'IndustryPartner' ? (
              <div className="col-sm-4">
                <CustomButton
                  className="add-e-btn ml-auto "
                  onClick={() => this.props.history.push('/create-event')}
                >
                  <span className="flex1">Add Event</span>
                  <img src="/assets/images/pluse-icon.svg" alt="" title=""/>
                </CustomButton>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-sm-8">
          <div className="row">
            {this.state.user.current_profile_type !== 'Volunteer' && (
              <div className="col-sm-4"
                   onClick={() => this.props.history.push('/employees')}
                   style={{ cursor: 'pointer' }}>
                <DashboardCard
                  count={total_users || 0}
                  imgUrl="/assets/images/green-cal-icon.png"
                  label="Total Users"
                />
              </div>
            )}
            <div className="col-sm-4"
                 onClick={() => this.props.history.push('/events')}
                 style={{ cursor: 'pointer' }}
            >
              <DashboardCard
                count={event_details?.total_events_created_by_cte || 0}
                imgUrl="/assets/images/vol-icon.png"
                label="Total Events"
                handleClick={this.redirectToEvent}
              />
            </div>
            <div
              className="col-sm-4"
              onClick={() => this.props.history.push('/events')}
              style={{ cursor: 'pointer' }}
            >
              <DashboardCard
                count={total_logged_hours || 0}
                imgUrl="/assets/images/watch-icon.png"
                label="Total Logged Hours"
                handleClick={this.redirectToEvent}
              />
            </div>
            {this.state.user.current_profile_type === 'Volunteer' && (
              <div
                className="col-sm-4"
                onClick={() => this.props.history.push('/events?cardIndex=2')}
                style={{ cursor: 'pointer' }}
              >
                <DashboardCard
                  count={total_logged_hours || 0}
                  imgUrl="/assets/images/watch-icon.png"
                  label="Invited Events"
                  handleClick={this.redirectToEvent}
                />
              </div>
            )}

            {this.state.user.current_profile_type === 'IndustryPartner' && (
              <div
                className="col-sm-4"
                onClick={() => this.props.history.push('/events')}
                style={{ cursor: 'pointer' }}
              >
                <DashboardCard
                  count={total_logged_hours || 0}
                  imgUrl="/assets/images/watch-icon.png"
                  label="Awaiting Approval Events"
                  handleClick={this.redirectToEvent}
                />
              </div>
            )}

            <div className="col-sm-12 mr-t30 mr-b30">
              <div className="white-box upcoming-event">
                <h4 className="mr-b30">Upcoming events</h4>
                {this.state.isGettingUpcomingEvent && (
                  <div className="upcoming-event__loading">
                    <Spin/> getting upcoming events ...
                  </div>
                )}
                <div className="row">
                  {!this.state.isGettingUpcomingEvent ? (
                    this.state.upcomingEvents !== null &&
                    this.state.upcomingEvents.length > 0 ? (
                      this.state.upcomingEvents.map((event, index) => (
                        <EventCard
                          type="Upcoming"
                          event={event}
                          index={index}
                          key={index}
                          handleDeleteClick={this.handleDeleteClick}
                        />
                      ))
                    ) : (
                      <EmptyEvent
                        title="There are currently no upcoming events"
                        subText=""
                      />
                    )
                  ) : null}
                  {this.state.upcomingEvents.length > 0 && (
                    <div className="d-flex justify-content-center w-100">
                      <Pagination
                        responsive
                        current={this.state.upcomingPagination.current}
                        pageSize={this.state.upcomingPagination.per_page || 6}
                        total={this.state.upcomingPagination.total_count}
                        onChange={this.handleUpcomingPaginationChange}
                      />
                    </div>
                  )}
                </div>
                {/* <h4 className="box-heading mr-b20">Sessions Overview</h4>
                <div className="chart-wrap">
                  <div
                    id="container"
                    style={{ width: '100%', height: '400px' }}
                  >
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/*<div*/}
        {/*className="col-sm-4"*/}
        {/*onClick={() => this.props.history.push('/events')}*/}
        {/*style={{ cursor: 'pointer' }}*/}
        {/*>*/}
        {/*<DashboardCard*/}
        {/*count={this.state.totalPendingEvent}*/}
        {/*imgUrl="/assets/images/watch-icon.png"*/}
        {/*label="Total Awaiting Events"*/}
        {/*handleClick={this.redirectToEvent}*/}
        {/*/>*/}
        {/*</div>*/}
      </div>
    );
  }
}

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({
  dashboardInfo: dashboardDetails,
  user: userDetails,
  employees: employees
});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({
  dashboard: () => dispatch(dashboard()),
  getAllEvents: (params) => dispatch(getAllEvents(params)),
  getUpcomingEvents: (params) => dispatch(getUpcomingEvents(params)),
  getPendingEvents: (params) => dispatch(getPendingEvents(params)),
  getUsers: (params) => dispatch(getUsers(params))
});

/**
 * Export Dashboard Class
 */
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
