export const activityTypes = [
  {id: 1, title: 'Career Fair'},
  {id: 2, title: 'Career Workshop'},
  {id: 3, title: 'Field Trip'},
  {id: 4, title: 'Guest Speaker'},
  {id: 5, title: 'Mock Interviews'},
  {id: 6, title: 'Student Internships'},
  {id: 7, title: 'Teacher Externships'},
  {id: 10, title: 'Virtual Worksite Tour'},
  {id: 8, title: 'Worksite Tours'},
  {id: 9, title: 'Other'},
]
