import React from 'react';

const ListHeader = ({ status, hideItem }) => {
  return (
    <thead className="userTable-table-header">
      <tr>
        <th scope="col">User</th>
        {!hideItem && <th scope="col">Email</th>}
        {!hideItem && <th scope="col">Total Logged Hours</th>}
        {!hideItem && <th scope="col">User Type</th>}
        <th scope="col">{status ? 'Status' : 'Action'}</th>
      </tr>
    </thead>
  );
};

export default ListHeader;
