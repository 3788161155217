import React, { useState, useEffect } from 'react';
import {
  PushpinOutlined,
  BulbOutlined,
  AlignLeftOutlined,
  FileImageOutlined
} from '@ant-design/icons';
import { activityTypes } from '../../../../data/activity-types';
import { parseBlobUrl } from '../../../../utils/helper';
import { FileOutlined } from '@ant-design/icons';
import Geocode from 'react-geocode';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from 'react-google-maps';
import GoogleMapReact from 'google-map-react';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
// Geocode.enableDebug();


const EventDetailCard = ({ eventDetail }) => {

  const [coordinate, setCoordinate] = useState({
    lat: null,
    lng: null
  });

  // const [coordinate2, setCoordinate2] = useState({
  //   lat: 32.8102566,
  //   lng: -96.6881388
  // });
  const [mapLoaded, setMapLoaded] = useState(null);
  const [markerLoaded, setMarkerLoaded] = useState(null);


  useEffect(() => {
    if (eventDetail.location) {
      Geocode.fromAddress(eventDetail.location).then(
        response => {
          const { lat, lng } = response.results[ 0 ].geometry.location;
          setCoordinate({
            lat,
            lng
          });
        },
        error => {
          console.error(error);
        }
      );
    }

  }, [eventDetail.location]);

  useEffect(() => {
    if (coordinate.lng != null && mapLoaded && !markerLoaded) {
      setMarkerLoaded(new mapLoaded.maps.Marker({
        position: coordinate,
        map: mapLoaded.map,
        title: eventDetail.location
      }));
    }
  }, [coordinate, mapLoaded]);

  const apiHasLoaded = (map, maps) => {
    setMapLoaded({map, maps});
  };


  return (
    <div className="row mt-2">
      <div className="col-sm-12">
        <div className="event-info-wrap">
          <div className="info-icon">
            <BulbOutlined/>
          </div>
          <div className="info-con">
            <h4 style={{ marginBottom: 5 }}>Event Type</h4>
            <p>
              {(activityTypes.find(({ id }) => eventDetail.activity_type == id))?.title}
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <div className="event-info-wrap">
          <div className="info-icon">
            <AlignLeftOutlined/>
          </div>
          <div className="info-con">
            <h4 style={{ marginBottom: 5 }}>Description</h4>
            <p>{eventDetail.description}</p>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <div className="event-info-wrap">
          <div className="info-icon">
            <PushpinOutlined height="55" width="55"/>
          </div>
          <div className="info-con">
            <h4 style={{ marginBottom: 5 }}>Location</h4>
            <p>{eventDetail.location}</p>
            <div style={{ height: '40vh', width: '100%' }}>
              {coordinate.lng != null && <GoogleMapReact
                apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
                defaultCenter={coordinate}
                defaultZoom={9}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
              >
                 {/*<div*/}
                 {/* key={'_id'}*/}
                 {/* lat={coordinate.lat}*/}
                 {/* lng={coordinate.lng}*/}
                 {/* marker={coordinate}/>*/}

              </GoogleMapReact>}
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="event-info-wrap">
          <div className="info-icon">
            <PushpinOutlined height="55" width="55"/>
          </div>
          <div className="info-con">
            <h4 style={{ marginBottom: 5 }}>Contact Email</h4>
            <p>Question about this event, please contact the event host at:
              <a href={`mailto: ${eventDetail.contact_email}`}> <span
                style={{ fontWeight: 'bold' }}> {eventDetail.contact_email} </span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <div className="event-info-wrap">
          {typeof eventDetail.attachments !== 'undefined' &&
          eventDetail.attachments.length > 0 && (
            <div className="info-icon">
              <FileImageOutlined/>
            </div>
          )}
          <div className="info-con">
            {typeof eventDetail.attachments !== 'undefined' &&
            eventDetail.attachments.length > 0 && (
              <h4 style={{ marginBottom: 5 }}>
                {eventDetail.attachments.length > 1
                  ? 'Attachments'
                  : 'Attachment'}
              </h4>
            )}
            <div className="files-list">
              {typeof eventDetail.attachments !== 'undefined' &&
              eventDetail.attachments.length > 0 &&
              eventDetail.attachments.map((attach, index) => (
                <div className="d-file" key={index}>

                  <a target="_blank" href={parseBlobUrl(attach.file.url)}>
                    {attach.file.mime_type.includes('image') &&
                    <img src={parseBlobUrl(attach.file.url)} alt="" title=""/>}
                    {!attach.file.mime_type.includes('image') && <div><FileOutlined/></div>}

                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <div className="event-info-wrap">
          {/*<div className="info-icon">*/}
          {/*  <img*/}
          {/*    src="/assets/images/comment-icon.svg"*/}
          {/*    alt=""*/}
          {/*    title=""*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="info-con">*/}
          {/*  <h4>Comments (1)</h4>*/}
          {/*  <div className="comments-warp">*/}
          {/*    <div className="comments-box">*/}
          {/*      <div className="com-user-profile">*/}
          {/*        <h5>KA</h5>*/}
          {/*      </div>*/}
          {/*      <div className="user-pro-info">*/}
          {/*        <div className="comm-head d-flex align-items-center">*/}
          {/*          <h5 className="flex1">*/}
          {/*            Joel Hopkins, <span>Wellsfargo</span>*/}
          {/*          </h5>*/}
          {/*          <span className="comm-date">Dec 14, 2018</span>*/}
          {/*          <div className="drop-box">*/}
          {/*            <button*/}
          {/*              className="trans-btn dropdown-toggle"*/}
          {/*              data-toggle="dropdown"*/}
          {/*              aria-haspopup="true"*/}
          {/*              aria-expanded="false"*/}
          {/*            >*/}
          {/*              <img*/}
          {/*                src="/assets/images/d-icon.png"*/}
          {/*                alt=""*/}
          {/*                title=""*/}
          {/*              />*/}
          {/*            </button>*/}
          {/*            <div className="dropdown-menu dropdown-menu-right">*/}
          {/*              <button className="dropdown-item" type="button">*/}
          {/*                Action*/}
          {/*              </button>*/}
          {/*              <button className="dropdown-item" type="button">*/}
          {/*                Another action*/}
          {/*              </button>*/}
          {/*              <button className="dropdown-item" type="button">*/}
          {/*                Something else here*/}
          {/*              </button>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*        <div className="comm-body mr-t40">*/}
          {/*          <p>*/}
          {/*            - We would like to have Pageviews, click,*/}
          {/*            conversion and total revenue up in the right*/}
          {/*            corner of the graph. And maybe design them so the*/}
          {/*            look more like buttons that you can turn on/off?*/}
          {/*          </p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="comments-box mr-t20">*/}
          {/*      <div className="com-user-profile">*/}
          {/*        <img src="/assets/images/pro1.png" alt="" title="" />*/}
          {/*      </div>*/}
          {/*      <div className="comm-input">*/}
          {/*        <input*/}
          {/*          type="text"*/}
          {/*          placeholder="Add a comment or upload a file…"*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default EventDetailCard;
