import { type } from './company.types';
const INITIAL_STATE = {
  companyList: null,
  error: null
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.COMPANY_LIST_START:
      return {
        ...state,
        error: null
      };
    case type.COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companyList: action.payload,
        error: null
      };
    case type.COMPANY_LIST_ERROR:
      return {
        ...state,
        companyList: null,
        error: action.error
      };

    default:
      return state;
  }
};

export default companyReducer;
