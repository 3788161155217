/**
 * Import NPM Packages
 */
import React, { useEffect, useState, Fragment } from 'react';
import { requestApproval } from '../../redux/user/user.action';
/**
 * Import styles
 */
import './pending-approval.styles.scss';

/**
 * Import Components
 */
import CustomButton from '../custom-button/custom-button.component';
import { connect } from 'react-redux';
import {
  getEmployees,
  getUsers
} from '../../redux/employees/employees.actions';

const PendingApproval = ({ user, requestApproval }) => {
  return (
    <div className="row pending-approval justify-content-center">
      <div className="col-md-8">
        <div className="media pending-approval__card">
          <img
            src="/assets/images/timeIcon.svg"
            alt="Time Icon"
            className="time-icon img-fluid"
          />
          <div className="media-body">
            <h3 class="mt-0 mb-1">Pending Approval</h3>
            <p>A background check is required.</p>
            {user?.account?.background_check_link && (
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={user?.account?.background_check_link || ''}
                >
                  Learn more at {user?.account?.background_check_link}
                </a>
                .
              </p>
            )}
          </div>
        </div>
        <div className={'text-center col-sm-12 mt-3'}>
          Only click the <b>"Request Approval"</b> button after you have
          completed the background check requirement.
        </div>

        <div className="pending-approval__cta">
          {/*<CustomButton className="inverted">*/}
          {/*  {user?.account?.background_check_link}*/}
          {/*</CustomButton>*/}

          <CustomButton
            disabled={user.verification_status == 'pending'}
            onClick={requestApproval}
          >
            {user.verification_status == 'pending'
              ? 'Approval Requested'
              : 'Request approval'}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { requestApproval })(PendingApproval);
