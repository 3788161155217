/**
 * Import NPM Packages
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

/**
 * Import Redux functions
 */
import { upcomingEvent } from '../../redux/event/event.action';
import { upcomingEventDetail } from '../../redux/event/event.selector';

/**
 * Import styles
 */
import './upcoming-event.styles.scss';

/**
 * Import Components
 */
import Attending from '../attending/attending.component';
import EventTime from '../event-time/event-time.component';

const UpcomingEvent = ({ eventId, upcomingEvent, upcomingEventInfo }) => {
  const [upcomingEventData, setUpcomingEventData] = useState({});
  const [attendingData, setAttendingData] = useState([]);
  useEffect(() => {
    upcomingEvent({ eventId: eventId });
  }, [eventId]);

  useEffect(() => {
    const details =
      upcomingEventInfo?.statusCode === 200 &&
      upcomingEventInfo?.result.length > 0
        ? upcomingEventInfo?.result[0]
        : {};
    setUpcomingEventData(details);

    const attendingInfo =
      upcomingEventInfo?.statusCode === 200 &&
      upcomingEventInfo?.result.length > 0
        ? upcomingEventInfo?.result[0].attending
        : [];
    setAttendingData(attendingInfo);
  }, [upcomingEventInfo]);

  return (
    <div className="sign-left-block">
      <h1>
        You have been invited to <br /> the events below
      </h1>
      <div className="sign-event-box mr-t30">
        <h5>Upcoming events</h5>
        <div className="e-card">
          <time className="e-time">
            {moment(upcomingEventData.event_startdatetime).format('hh:mm a')}
          </time>
          <h6 className="">{upcomingEventData.event_title}</h6>
          <span>{upcomingEventData.invitees?.length} Volunteers Invited</span>
          <h4 className="mr-t20 mr-b20">
            Invited by <span>{upcomingEventData.createdBy}</span>,{' '}
            {moment(upcomingEventData.created_on).format('ddd, DD MMM yyyy')}
          </h4>
          <div className="row">
            <div className="col-sm-6">
              <Attending attendingData={attendingData} />
            </div>
            <div className="col-sm-6">
              <EventTime dateTime={upcomingEventData.event_startdatetime} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({
  upcomingEventInfo: upcomingEventDetail
});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({
  upcomingEvent: (eventInfo) => dispatch(upcomingEvent(eventInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingEvent);
