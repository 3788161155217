/**
 * Import NPM Packages
 */
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Checkbox, Modal, notification, Tag } from 'antd';
import { trim } from 'jquery';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import FilePreviewer from 'react-file-previewer';
/**
 * Import Redux functions
 */

import {
  createEvent,
  editEvent,
  getEventById,
  sendInvite
} from '../../../redux/event/event.action';
import {
  createEventDetails,
  editEventDetails,
  getActivityType,
  getEventDetail
} from '../../../redux/event/event.selector';
import { allUserDetails } from '../../../redux/user/user.selector';
/**
 * Import styles
 */
import './event-operation.styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
/**
 * Import Components
 */
import FormInput from '../../form-input/form-input.component';
import CustomButton from '../../custom-button/custom-button.component';
import Spinner from '../../spinner/spinner.component';
import { jwtDecode } from '../../../jwt-decode';
import DateSelector from '../components/DateSelector';
import { activityTypes } from '../../../data/activity-types';
import { SearchLocationInput } from '../../../utils/LocationSearch';
import { isValidHttpUrl } from '../../../utils/helper';

/**
 * EventOperation Class
 */
const dt = new Date();
dt.setMinutes(dt.getMinutes() + 60);

const EventOperation = (props) => {
  const { location, getEventById, createEvent, editEvent, history } = props;

  const initState = {
    activityTypeId: '',
    title: '',
    description: '',
    date: moment().add(1, 'days').format('MM/DD/YYYY'),
    contact_email: '',
    contact_first_name: '',
    contact_last_name: '',
    volunteer_limit: '',
    survey_url: '',
    startTime: new Date(),
    endTime: dt,
    location: '',
    selectedFiles: [],
    selectedInvite: [],
    activityData: [],
    uploadPopup: false,
    showFormError: false,
    inviteAllButton: false,
    inviteAllButtonConfirmation: false,
    eventId: null,
    eventOperationError: false,
    userData: [],
    heading: 'Create event',
    unknownEmail: '',
    attachments: []
  };

  const [state, setState] = useState(initState);
  const [eventConfirmation, setEventConfirmation] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [timeError, setTimeError] = useState(null);
  const [eventLocation, setEventLocation] = useState('');

  const [errorMessage, setErrorMessage] = useState('Please enter a valid URL');

  const validate = (value) => {
    if (isValidHttpUrl(value)) {
      console.log('validated...');
      setErrorMessage('');
    }else {
      setErrorMessage('Please enter a valid URL');
    }
  };

  useEffect(() => {
    const userDetail = jwtDecode();
    setisLoading(true);
    if (location.state?.eventId) {
      setState({ ...state, eventId: null, heading: 'Update Event' });
      getEventById({
        eventId: location.state?.eventId
      }).then((event) => {
        console.log('received event', event);
        setState({
          ...state,
          activityTypeId: event.activity_type,
          title: event.title || '',
          description: event.description || '',
          eventId: event._id,
          date: moment(event.start_date).format('MM/DD/YYYY'),
          startTime: new Date(event.start_date),
          endTime: new Date(event.end_date),
          location: event.location || '',
          contact_email: event.contact_email || '',
          contact_first_name: event.contact_first_name || '',
          contact_last_name: event.contact_last_name || '',
          volunteer_limit: event.volunteer_limit || '',
          survey_url: event.survey_url || '',
          attachments: event.attachments,
          heading: 'Update Event',
          send_survey_after_event: !!event.send_survey_after_event
        });
      });
    } else {
      setState(initState);
    }
  }, []);

  /**
   * Change date picker function
   */
  const handleDateChange = (date) => {
    setState({
      ...state,
      date: moment(date).format('MM/DD/YYYY')
    });
  };

  const dateChangeHandler = () => {
    const date = window.$('#datepicker').val();
    setState({
      ...state,
      date: moment(date).format('MM/DD/YYYY')
    });
  };

  const handleTimeError = () => {
    setTimeError(
      <Tag className="timeError-tag" color="error">
        End time must be after start time
      </Tag>
    );
    setTimeout(() => setTimeError(null), 1500);
  };

  /**
   * Changes hours and time
   * @param {*} date
   * @param {*} time
   */
  const timeChangeHandler = (date, time) => {
    if (time === 'startTime') {
      setState({
        ...state,
        startTime: date
      });
    } else {
      const start = moment(state.startTime, 'hh:mm');
      const selectedTime = moment(date);
      if (selectedTime.isBefore(start)) return handleTimeError();
      setState({
        ...state,
        endTime: date
      });
    }
  };

  /**
   * Show date picker function
   */
  const showDateHandler = () => {
    window
      .$('#datepicker')
      .datepicker({ autoclose: true, startDate: '+1d' })
      .on('change', dateChangeHandler());
  };

  /**
   * Input changes state get updated
   * @param {*} event
   */
  const formHandleChange = (event) => {
    let { name, value, checked, type } = event.target;
    console.log('formHandleChange-name:::', name);
    if (name === 'survey_url') {
      validate(value);
    }
    if (type == 'checkbox') {
      value = !!checked;
    }
    console.log('type', type, name, value);
    setState({
      ...state,
      [name]: value || ''
    });
  };

  const handleOnChange = (e) => {
    // console.log('handleOnSelect-value:', e);
  };

  const handleOnSelect = (location) => {
    console.log('selected-location:', location);
    setEventLocation(location);
  };

  /**
   * On drag add files to state
   * @param {*} e
   */
  const onDragFiles = (e) => {
    console.log('E', e);
    const prevFiles = state.selectedFiles;
    let arrTemp = [...prevFiles, ...e];
    setState({ ...state, selectedFiles: arrTemp, uploadPopup: true });
  };

  /**
   * Remove files from selected files
   * @param {*} index
   */
  const removeFromSelectFiles = (index) => {
    const selectedFileData = state.selectedFiles;
    selectedFileData.splice(index, 1);
    setState({
      ...state,
      selectedFiles: selectedFileData
    });
  };

  /**
   * Check validation
   */
  const eventOperationValidationHandler = () => {
    console.log('event-location-state:::', eventLocation);
    const {
      activityTypeId,
      title,
      description,
      date,
      startTime,
      endTime
    } = state;
    if (
      activityTypeId !== '' &&
      title !== '' &&
      description !== '' &&
      (eventLocation !== '' || state.location !== '') &&
      startTime !== '' &&
      endTime !== '' &&
      date !== ''
    ) {
      setEventConfirmation({
        eventConfirmation: true
      });
    } else {
      setState({
        ...state,
        showFormError: true
      });
    }
  };

  console.log('eventLocation:', eventLocation);

  /**
   * Check validation and call create-event API
   */
  console.log('state :>> ', state);
  const eventOperationHandler = () => {
    const {
      activityTypeId,
      title,
      description,
      date,
      startTime,
      endTime,
      location,
      volunteer_limit,
      contact_email,
      contact_first_name,
      contact_last_name,
      selectedFiles,
      survey_url,
      heading,
      eventId,
      send_survey_after_event
    } = state;
    setEventConfirmation(false);
    setisLoading(true);
    const eventInfo = new FormData();
    eventInfo.append('invitation_url', `${window.location.protocol}//${window.location.host}/event`);
    // eventInfo.append('invitation_url', process.env.REACT_APP_INVITATION_URL);
    eventInfo.append('title', title);
    eventInfo.append('description', description);
    eventInfo.append('volunteer_limit', volunteer_limit);
    eventInfo.append('survey_url', survey_url);
    eventInfo.append('activity_type', activityTypeId.toString());
    eventInfo.append('send_survey_after_event', !!send_survey_after_event);
    eventInfo.append(
      'start_date',
      moment(
        `${moment(date).format('YYYY-MM-DD')} ${moment(startTime).format(
          'HH:mm:ss'
        )}`
      )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    );

    eventInfo.append(
      'end_date',
      moment(
        `${moment(date).format('YYYY-MM-DD')} ${moment(endTime).format(
          'HH:mm:ss'
        )}`
      )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    );

    // eventInfo.append('location', eventLocation);
    eventInfo.append('location', location);
    eventInfo.append('contact_email', contact_email);
    eventInfo.append('contact_first_name', contact_first_name);
    eventInfo.append('contact_last_name', contact_last_name);
    eventInfo.append('eventId', eventId ? eventId.toString() : null);

    if (selectedFiles.length > 0) {
      selectedFiles.forEach((file) => eventInfo.append('file', file));
    }

    // for (let i = 0; i < selectedFiles.length; i++) {
    //   eventInfo.append('file', selectedFiles[i]);
    // }

    console.log(
      'eventinfo',
      eventInfo.get('location'),
      eventInfo.get('file'),
      selectedFiles
    );
    console.log('heading:', heading);
    console.log('createEvent-eventInfo:', eventInfo);
    if (heading === 'Create event') {
      createEvent(eventInfo).then((response) => {
        console.log('response ::::: ', response);
        if (response.status === 201) {
          setisLoading(false);
          setState({
            ...state,
            eventId: response.data.data._id
          });
          notification['success']({
            message: 'Event Info',
            description: 'Event created successfully',
            style: {
              width: 600,
              marginLeft: 335 - 600
            }
          });
          history.push({
            pathname: '/event-detail',
            state: {
              eventId: response.data.data._id
            }
          });
        } else if (response.status !== 200) {
          setisLoading(false);
          setState({
            ...state,
            eventOperationError: true
          });
        }
      });
    } else {
      console.log('evenInfo:', eventInfo);
      console.log('updateEvent-state:', state);
      editEvent(state.eventId, { ...state }).then((response) => {
        console.log('editEvent-response:', response);
        if (response.status === 200) {
          setisLoading(false);
          setState({ ...state, eventOperationError: false });
          notification.open({
            message: 'Event Info',
            description: 'Event updated successfully',
            style: {
              width: 600,
              marginLeft: 335 - 600
            }
          });
        } else {
          setisLoading(false);
          setState({
            ...state,
            eventOperationError: true
          });
        }
      });
    }
  };

  /**
   * Call send invitation API
   */
  const emailInvitationHandler = (email, id) => {
    let emailArray = [];
    if (email) {
      emailArray = email.split(',');
      emailArray = emailArray.map((email) => trim(email));
    } else {
      emailArray = state.userData.map((user) => user.email);
    }

    setState({
      unknownEmail: '',
      inviteAllButtonConfirmation: false,
      unknownEmailConfirmation: false
    });

    // You are to send
  };

  const removeAttachment = (e, index) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to remove this file')) {
      const { attachments } = state;
      attachments.splice(index, 1);
      setState({
        ...state,
        attachments
      });
      console.log('state-attachments:', { ...state });
    }
  };

  return state.isLoading ? (
    <Spinner/>
  ) : (
    <div className="row">
      <div className="col-sm-8">
        <h3 className="page-heading mr-b30">{state.heading}</h3>
      </div>
      <div className="col-sm-4"></div>
      <div className="col-sm-8">
        <div className="white-box event-form-wrap">
          <div className="event-form-block">
            <div className="form-group">
              <label htmlFor="">Activity Type</label>
              <select
                name="activityTypeId"
                className="form-control"
                value={state.activityTypeId}
                onChange={formHandleChange}
              >
                <option value="">Select Activity Type</option>
                {activityTypes.map((activity) => (
                  <option value={activity.id} key={activity.id}>
                    {activity.title}
                  </option>
                ))}
              </select>
              {state.showFormError && state.activityTypeId === '' ? (
                <span className="signup-error">
                  Please select activity type
                </span>
              ) : null}
            </div>
            <FormInput
              label="Title"
              type="text"
              name="title"
              value={state.title}
              placeholder="Event Title"
              className="form-control"
              handleChange={formHandleChange}
              showError={
                state.showFormError && state.title === '' ? true : false
              }
              errorText="Please insert title"
            />

            <div className="form-group">
              <label htmlFor="">Event Description</label>
              <textarea
                name="description"
                value={state.description}
                rows="5"
                className="form-control"
                placeholder="Event Description"
                onChange={formHandleChange}
              ></textarea>
              {state.showFormError && state.description === '' ? (
                <span className="signup-error">
                  Please describe this event
                </span>
              ) : null}
            </div>
            <div className="form-group">
              <div className="event-date-wrap1">
                <div className="date-inputs">
                  <label htmlFor="">Date</label>
                  <div className="cal-block dateSelector">
                    <DateSelector
                      value={state.date}
                      onChange={handleDateChange}
                    />
                    {/* <input
                      type="text"
                      id="datepicker"
                      value={state.date}
                      className="blank-input date-input form-control"
                      autoComplete="off"
                      onFocus={showDateHandler}
                      readOnly
                    /> */}
                    {/* <button>
                      <img
                        src="/assets/images/calendar_icon.svg"
                        alt=""
                        title=""
                      />
                    </button> */}
                  </div>
                  {state.showFormError && state.date === '' ? (
                    <span className="signup-error">
                      Please select event date
                    </span>
                  ) : null}
                  {state.showFormError && state.startTime >= state.endTime ? (
                    <span className="signup-error">&nbsp;&nbsp;</span>
                  ) : null}
                </div>
                <div className="date-inputs">
                  <label htmlFor="">Start Time</label>
                  <div>
                    <DatePicker
                      selected={state.startTime}
                      onChange={(date) => timeChangeHandler(date, 'startTime')}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="cal-block pd-l10"
                    />
                  </div>
                  {state.showFormError && state.startTime >= state.endTime ? (
                    <span className="signup-error">
                      Please select start time
                    </span>
                  ) : null}
                </div>
                <div className="date-inputs">
                  <label htmlFor="">End Time</label>
                  <div>
                    <DatePicker
                      selected={state.endTime}
                      onChange={(date) => timeChangeHandler(date, 'endTime')}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="cal-block pd-l10"
                    />
                  </div>
                  {state.showFormError && state.startTime >= state.endTime ? (
                    <span className="signup-error">
                      Please select end time
                    </span>
                  ) : null}
                </div>
              </div>

              {timeError && (
                <div className="timeError-wrapper">{timeError}</div>
              )}
              <div className="date-note">
                <img src="/assets/images/green-tick.png" alt="" title=""/>
                <p>
                  This event will take place on the{' '}
                  {moment(state.date).format('Do of MMMM YYYY')} from{' '}
                  {moment(state.startTime).format('hh:mm')} until{' '}
                  {moment(state.endTime).format('hh:mm')}
                </p>
              </div>
            </div>

            <FormInput
              label="Location"
              type="text"
              name="location"
              value={state.location}
              placeholder="Event Location"
              className="form-control"
              handleChange={formHandleChange}
              showError={
                state.showFormError && state.location === '' ? true : false
              }
              errorText="Please enter location, field cannot be empty"
            />

            {/*<SearchLocationInput*/}
            {/*label="Location"*/}
            {/*type="text"*/}
            {/*name="location"*/}
            {/*value={state.location}*/}
            {/*placeholder="Event Location"*/}
            {/*className="form-control"*/}
            {/*onSelect={handleOnSelect}*/}
            {/*onChange={handleOnChange}*/}
            {/*showError={state.showFormError && state.location === '' ? true : false}*/}
            {/*errorText="Please enter location, field cannot be empty"*/}
            {/*/>*/}


            {/*<FormInput*/}
            {/*label="Location"*/}
            {/*type="text"*/}
            {/*name="location"*/}
            {/*value={state.location}*/}
            {/*placeholder="Event Location"*/}
            {/*className="form-control"*/}
            {/*handleChange={formHandleChange}*/}
            {/*showError={*/}
            {/*state.showFormError && state.location === '' ? true : false*/}
            {/*}*/}
            {/*errorText="Please enter location, field cannot be empty"*/}
            {/*/>*/}


            <FormInput
              label="Number of volunteers needed"
              type="number"
              name="volunteer_limit"
              value={state.volunteer_limit}
              placeholder="Number of volunteers needed e.g 6"
              className="form-control"
              handleChange={formHandleChange}
              showError={state.showFormError && state.volunteer_limit === ''}
              errorText="Please enter volunteer limit"
            />
            <FormInput
              label="Contact Person First name"
              type="text"
              name="contact_first_name"
              value={state.contact_first_name}
              placeholder="First name"
              className="form-control"
              handleChange={formHandleChange}
              showError={state.showFormError && state.contact_first_name === '' ? true : false}
              errorText="Please enter contact person first name"
            />

            <FormInput
              label="Contact Person Last name"
              type="email"
              name="contact_last_name"
              value={state.contact_last_name}
              placeholder="Last name"
              className="form-control"
              handleChange={formHandleChange}
              showError={state.showFormError && state.contact_last_name === '' ? true : false}
              errorText="Please enter contact person last name"
            />


            <FormInput
              label="Contact Email"
              type="email"
              name="contact_email"
              value={state.contact_email}
              placeholder="name@example.com"
              className="form-control"
              handleChange={formHandleChange}
              showError={state.showFormError && state.contact_email === '' ? true : false}
              errorText="Please enter contact email address"
            />

            <FormInput
              label="Survey URL"
              type="email"
              name="survey_url"
              value={state.survey_url}
              placeholder="http://example.com"
              className="form-control"
              handleChange={formHandleChange}
            />

            {/*<Checkbox*/}
              {/*checked={state.send_survey_after_event}*/}
              {/*name="send_survey_after_event"*/}
              {/*onChange={formHandleChange}*/}
              {/*className={'mb-4'}>*/}
              {/*Send Survey to All Attendees After Event*/}
            {/*</Checkbox>*/}

            {/* Attachments */}
            <div className="form-group">
              <label htmlFor="">Upload attachments</label>
              <div className="attach-list">
                <table>
                  <tbody>
                  {state.selectedFiles.length > 0
                    ? state.selectedFiles.map((file, index) => (
                      <tr key={index}>
                        <td>
                          <div className="file-name d-flex align-items-center">
                            {file.type.startsWith('image') ? (
                              <img
                                src="/assets/images/file-icon.png"
                                className="mr-r10"
                                alt=""
                                title=""
                              />
                            ) : (
                              <img
                                src="/assets/images/pdf-icon.svg"
                                className="mr-r10"
                                alt=""
                                title=""
                              />
                            )}

                            <h5 style={{ margin: '0px' }}>{file.name}</h5>
                          </div>
                        </td>
                        <td className="text-center">
                          <button
                            type="button"
                            style={{ margin: '0px' }}
                            className="trans-btn"
                            onClick={() => removeFromSelectFiles(index)}
                          >
                            Remove
                          </button>
                        </td>
                        <td
                          className="text-right"
                          className="file-cell"
                        ></td>
                      </tr>
                    ))
                    : null}
                  </tbody>
                </table>
              </div>
              <Dropzone
                onDrop={(acceptedFiles) => onDragFiles(acceptedFiles)}
                directory=""
                webkitdirectory=""
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      className="disabledFocus"
                      accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                    />
                    <div className="file-drop-box mr-t30">
                      <img
                        src="/assets/images/upload_icon.svg"
                        className="mr-r10"
                        alt=""
                        title=""
                      />
                      <span>You can click to add or drop your files here</span>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            {state.attachments.length > 0 ? (
              <div className="col-sm-12">
                <div className="event-info-wrap">
                  <div className="info-icon">
                    <img src="/assets/images/att-icon.svg" alt="" title=""/>
                  </div>
                  <div className="info-con">
                    <h4> Previous Attachment</h4>
                    <div className="files-list">
                      {state.attachments.map((attach, index) => (
                        <div className="d-file" key={index}>
                          <a target="_blank" href={attach.file.url}>
                            <img src={attach.file.url} alt="" title=""/>
                          </a>
                          <a href="" onClick={e => removeAttachment(e, index)}><span>remove</span></a>
                          <br/>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <br/>
          <br/>
          <CustomButton
            className="invite-btn w-100"
            style={{ backgroundColor: 'green' }}
            onClick={eventOperationValidationHandler}
          >
            {state.heading}
          </CustomButton>
        </div>
      </div>

      {/*<div className="col-sm-4">*/}
      {/*  <div className="white-box" style={{ position: 'relative' }}>*/}
      {/*    {state.eventId ? (*/}
      {/*      <div></div>*/}
      {/*    ) : (*/}
      {/*      <div*/}
      {/*        style={{*/}
      {/*          width: '100%',*/}
      {/*          height: '100%',*/}
      {/*          position: 'absolute',*/}
      {/*          background: 'rgba(0,0,0,0.1)'*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*    <div className="invite-tabs">*/}
      {/*      <ul className="nav nav-tabs" id="myTab" role="tablist">*/}
      {/*        <li className="nav-item">*/}
      {/*          <a*/}
      {/*            className="nav-link active"*/}
      {/*            id="invite-partners-tab"*/}
      {/*            data-toggle="tab"*/}
      {/*            href="#invite-partners"*/}
      {/*            role="tab"*/}
      {/*            aria-controls="invite-partners"*/}
      {/*            aria-selected="true"*/}
      {/*          >*/}
      {/*            Invite Partners*/}
      {/*          </a>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      <div className="tab-content" id="myTabContent">*/}
      {/*        <div*/}
      {/*          className="tab-pane fade show active"*/}
      {/*          id="invite-partners"*/}
      {/*          role="tabpanel"*/}
      {/*          aria-labelledby="invite-partners-tab"*/}
      {/*        >*/}
      {/*          <div className="invide-list-body">*/}
      {/*            <div className="invite-input">*/}
      {/*              <input*/}
      {/*                name="unknownEmail"*/}
      {/*                value={state.unknownEmail}*/}
      {/*                type="email"*/}
      {/*                placeholder="Comma seperated email"*/}
      {/*                onChange={formHandleChange}*/}
      {/*              />*/}
      {/*              <button*/}
      {/*                onClick={(e) =>*/}
      {/*                  setState({*/}
      {/*                    ...state,*/}
      {/*                    unknownEmailConfirmation: true*/}
      {/*                  })*/}
      {/*                }*/}
      {/*                disabled={!state.unknownEmail}*/}
      {/*              >*/}
      {/*                Send*/}
      {/*              </button>*/}
      {/*            </div>*/}
      {/*            <div className="d-flex mr-t30 mr-b20">*/}
      {/*              <a className="Muli-Medium color-gary font14 w-50">*/}
      {/*                PARTNERS*/}
      {/*              </a>*/}
      {/*              <CustomButton*/}
      {/*                className="Muli-Medium trans-btn color-gary"*/}
      {/*                onClick={() =>*/}
      {/*                  setState({ ...state, inviteAllButtonConfirmation: true })*/}
      {/*                }*/}
      {/*                id="buttonInviteAll"*/}
      {/*                style={{ marginLeft: '75px' }}*/}
      {/*              >*/}
      {/*                INVITE ALL*/}
      {/*              </CustomButton>*/}
      {/*            </div>*/}
      {/*            /!*<ul>*!/*/}
      {/*            /!*{userData.map((user) => (*!/*/}
      {/*            /!*<li key={user.id}>*!/*/}
      {/*            /!*<a href="#">*!/*/}
      {/*            /!*<div className="in-pro">*!/*/}
      {/*            /!*<img src={user.profile_pic_url} alt="" title=""/>*!/*/}
      {/*            /!*</div>*!/*/}
      {/*            /!*<h5 className="w-50">{user.full_name}</h5>*!/*/}
      {/*            /!*<CustomButton*!/*/}
      {/*            /!*style={{*!/*/}
      {/*            /!*marginLeft: '30px',*!/*/}
      {/*            /!*marginBottom: '5px'*!/*/}
      {/*            /!*}}*!/*/}
      {/*            /!*className="trans-btn"*!/*/}
      {/*            /!*onClick={() =>*!/*/}
      {/*            /!*emailInvitationHandler(user.email, user.id)*!/*/}
      {/*            /!*}*!/*/}
      {/*            /!*id={'button' + user.id}*!/*/}
      {/*            /!*>*!/*/}
      {/*            /!*Invite*!/*/}
      {/*            /!*</CustomButton>*!/*/}
      {/*            /!*</a>*!/*/}
      {/*            /!*</li>*!/*/}
      {/*            /!*))}*!/*/}
      {/*            /!*</ul>*!/*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div*/}
      {/*          className="tab-pane fade"*/}
      {/*          id="volunteers"*/}
      {/*          role="tabpanel"*/}
      {/*          aria-labelledby="volunteers-tab"*/}
      {/*        >*/}
      {/*          <div className="invide-list-body">Need Volunteers</div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Modal
        title={state.heading}
        visible={eventConfirmation}
        onOk={eventOperationHandler}
        onCancel={() => setEventConfirmation(false)}
      >
        <p>Do you want to {state.heading}?</p>
      </Modal>
      <Modal
        title={'Invite All'}
        visible={state.inviteAllButtonConfirmation}
        onOk={() => emailInvitationHandler('', 'InviteAll')}
        onCancel={() =>
          setState({ ...state, inviteAllButtonConfirmation: false })
        }
      >
        <p>Do you want to invite all users?</p>
      </Modal>
      <Modal
        title={'Invite All'}
        visible={state.unknownEmailConfirmation}
        onOk={() => emailInvitationHandler(state.unknownEmail)}
        onCancel={() => setState({ ...state, unknownEmailConfirmation: false })}
      >
        <p>Do you want to invite unknown users?</p>
      </Modal>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  activityInfo: getActivityType,
  usersInfo: allUserDetails,
  createEventInfo: createEventDetails,
  editEventInfo: editEventDetails,
  eventInfoById: getEventDetail
});

const dispatchToProps = (dispatch) => ({
  getEventById: (eventId) => dispatch(getEventById(eventId)),
  createEvent: (eventInfo) => dispatch(createEvent(eventInfo)),
  sendInvite: (invitationInfo) => dispatch(sendInvite(invitationInfo)),
  editEvent: (eventId, payload) => dispatch(editEvent(eventId, payload))
});

/**
 * Export LeftSidebar Component
 */
export default withRouter(
  connect(mapStateToProps, dispatchToProps)(EventOperation)
);
