import React, { useEffect, useState } from 'react';
import CustomButton from '../../../custom-button/custom-button.component';
import { connect } from 'react-redux';
import { acceptEventInvite } from '../../../../redux/event/event.action';

const AcceptInvite = ({
  userDetail,
  eventDetail,
  acceptEventInvite,
  getEvent
}) => {
  const [hasAccepted, setHasAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userDetail && eventDetail) {
      if (eventDetail.attendees.length === 0) return setIsLoading(false);
      eventDetail.attendees.map((attendee, i) => {
        if (attendee._id === userDetail._id) setHasAccepted(true);
        if (i + 1 === eventDetail.attendees.length) setIsLoading(false);
      });
    }
  }, [userDetail, eventDetail]);

  // Stops from showing anything until logic is complete on load
  if (isLoading) return <div />;
  else
    return (
      <div className="col-sm-2">
        {!hasAccepted ? (
          <CustomButton
            type="button"
            className="invite-btn w-100"
            onClick={() =>
              acceptEventInvite({ event_id: eventDetail._id }, getEvent)
            }
            style={{ backgroundColor: '#30CC9C' }}
          >
            Accept Invite
          </CustomButton>
        ) : (
          <div
            className="invite-btn w-100"
            style={{ backgroundColor: '#F66090' }}
          >
            I am attending
          </div>
        )}
      </div>
    );
};

export default connect(null, { acceptEventInvite })(AcceptInvite);
