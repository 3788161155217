import React from 'react';
import CustomButton from '../../custom-button/custom-button.component';

const EventsHeader = ({ history, profileType }) => {
  return (
    <div className="row">
      <div className="col-sm-10">
        <div className="row">
          <div className="col-sm-8">
            <div className="event-arrow">
              <h3 className="page-heading mr-b30">Events</h3>
            </div>
          </div>
          {profileType === 'Admin' || profileType === 'IndustryPartner' ? (
            <div className="col-sm-4">
              <CustomButton
                className="add-e-btn ml-auto "
                onClick={() => history.push('/create-event')}
              >
                <span className="flex1">Add Event</span>
                <img src="/assets/images/pluse-icon.svg" alt="" title="" />
              </CustomButton>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EventsHeader;
