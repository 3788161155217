import { type } from './user.types';
import api from '../../axios';
import { createBrowserHistory } from 'history';

export const signUp = (userInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.SIGNUP_START
    });

    try {
      const response = await api.post('/register', userInfo);
      localStorage.setItem('cte_user', JSON.stringify(response.data.data));
      dispatch({
        type: type.SIGNUP_SUCCESS,
        payload: response.data.data
      });

      return response;
    } catch (error) {
      dispatch({
        type: type.SIGNUP_ERROR,
        error: error.data._meta.error
      });
    }
  };
};

export const signIn = (userInfo, redirectUrl = '') => {
  console.log('redirectUrl:', redirectUrl);
  return async (dispatch) => {
    dispatch({
      type: type.SIGNIN_START
    });

    try {
      const response = await api.post('/login', userInfo);
      console.log(response?.data);
      if (response?.data?._meta?.status_code === 200) {
        localStorage.setItem('token', response?.data?._meta.token);
        localStorage.setItem('cte_user', JSON.stringify(response.data.data));
      }
      if (redirectUrl) {
        window.location = redirectUrl;
        dispatch({
          type: type.SIGNIN_SUCCESS,
          payload: (response.data || {}).data
        });
      } else {
        dispatch({
          type: type.SIGNIN_SUCCESS,
          payload: (response.data || {}).data
        });
      }
      return response;
    } catch (error) {
      console.log('login', error);
      dispatch({
        type: type.SIGNIN_ERROR,
        error: error?.data?._meta?.error || 'Invalid connection'
      });
    }
  };
};
export const signOut = (userInfo) => {
  localStorage.clear();
  return async (dispatch) => {
    dispatch({
      type: type.SIGNIN_SUCCESS,
      payload: null
    });
  };
};

export const dashboard = () => {
  return async (dispatch) => {
    dispatch({
      type: type.DASHBOARD_START
    });

    try {
      const response = await api.get('/user/dashboard', {
        headers: {
          auth: localStorage.getItem('token')
        }
      });
      dispatch({
        type: type.DASHBOARD_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.DASHBOARD_ERROR,
        error: error.response
      });
    }
  };
};

export const getUsers = (userInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.GET_USER_START
    });

    try {
      const response = await api.post('/user/getEmployees', userInfo, {
        headers: { auth: localStorage.getItem('token') }
      });
      dispatch({
        type: type.GET_USER_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.GET_USER_ERROR,
        error: error.response
      });
    }
  };
};

export const getAllUsers = (userInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.GET_ALL_USER_START
    });

    try {
      const response = await api.get('/user/getAllEmployees', {
        headers: { auth: localStorage.getItem('token') }
      });
      dispatch({
        type: type.GET_ALL_USER_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.GET_ALL_USER_ERROR,
        error: error.response
      });
    }
  };
};
export const getCurrentUser = () => {
  return async (dispatch) => {
    dispatch({
      type: type.GET_USER_START
    });

    try {
      const response = await api.get(
        `/users/current?population=["account", "company"]`,
        {
          headers: { auth: localStorage.getItem('token') }
        }
      );
      dispatch({
        type: type.GET_USER_SUCCESS,
        payload: response.data.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.GET_USER_ERROR,
        error: error.response
      });
    }
  };
};
export const uploadUserAccountLogo = (userInfo) => {
  console.log(userInfo);
  return async (dispatch) => {
    try {
      const response = await api.put(
        `/users/${userInfo._id}/avatar`,
        { file: userInfo.file },
        {
          headers: { auth: localStorage.getItem('token') }
        }
      );
      dispatch({
        type: type.UPLOAD_AVATAR,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.UPLOAD_AVATAR,
        error: error.response
      });
    }
  };
};

export const uploadUserAvatar = (id, formData) => {
  console.log('id :', id);
  return async (dispatch) => {
    try {
      const response = await api.put(`/users/${id}/avatar`, formData, {
        headers: { auth: localStorage.getItem('token') }
      });
      dispatch({
        type: type.UPLOAD_AVATAR,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.UPLOAD_AVATAR,
        error: error.response
      });
    }
  };
};

// Get settings
export const getAccount = (accountId) => async (dispatch) => {
  try {
    const res = await api.get(`/accounts/${accountId}`);
    dispatch({
      type: type.GET_ACCOUNT,
      payload: res.data.data
    });
    return res;
  } catch (err) {
    dispatch({
      type: type.GET_ACCOUNT_ERROR,
      payload: err
    });
  }
};
// Update Account
export const updateAccount = (accountId, data) => async (dispatch) => {
  try {
    const res = await api.put(`/accounts/${accountId}`, data);
    dispatch({
      type: type.UPDATE_ACCOUNT,
      payload: res.data.data
    });
    return res;
  } catch (err) {
    dispatch({
      type: type.UPDATE_ACCOUNT_ERROR,
      payload: err
    });
  }
};
// Update User
export const updateUser = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: type.UPDATE_USER_START
    });
    const res = await api.put(`/users/${id}`, data);
    dispatch({
      type: type.UPDATE_USER_SUCCESS,
      payload: res.data.data
    });
    return res;
  } catch (err) {
    dispatch({
      type: type.UPDATE_USER_ERROR,
      error: err.data._meta.error
    });
  }
};
// Update company
export const updateCompany = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: type.UPDATE_COMPANY_START
    });
    const res = await api.put(id ? `/companies/${id}` : `/companies`, data);
    dispatch({
      type: type.UPDATE_COMPANY_SUCCESS,
      payload: res.data.data.company
    });
    return res;
  } catch (err) {
    dispatch({
      type: type.UPDATE_USER_ERROR,
      error: err.data._meta.error
    });
  }
};
// UN Approve volunteers
export const requestApproval = () => async (dispatch) => {
  try {
    const response = await api.put(`/users/request-approval`);
    // CB will reset users
    dispatch({
      type: type.GET_USER_SUCCESS,
      payload: response.data.data
    });
    return response;
  } catch (err) {
    dispatch({
      type: type.UPDATE_USER_ERROR,
      payload: err.data._meta.error
    });
    return err;
  }
};
