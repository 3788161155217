import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import companyReducer from './company/company.reducer';
import eventReducer from './event/event.reducer';
import surveyReducer from './survey/survey.reducer';
import employeesReducer from './employees/employees.reducer';
import settingsReducer from './settings/settings.reducer';

export default combineReducers({
  user: userReducer,
  employees: employeesReducer,
  company: companyReducer,
  event: eventReducer,
  settings: settingsReducer,
  survey: surveyReducer
});
