/**
 * Import NPM Packages
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Link } from 'react-router-dom';
/**
 * Import styles
 */
import './survey.styles.scss';
import 'font-awesome/css/font-awesome.min.css';
import $ from 'jquery';
import Spinner from '../spinner/spinner.component';
import api from '../../axios';

const Survey = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [serveyData, setServeyData] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    api
      .get('/survey/surveys', {
        params: {
          sort: '{"createdAt": "asc"}',
          population: [{ path: 'pages', populate: 'questions' }]
        }
      })
      .then((response) => {
        setServeyData(response.data.data);
        console.log(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="row">
      <div className="col-sm-12">
        <h1 className="mb-4">Surveys</h1>
        <div className="white-box employees-wrap">
          <div className="table-wrap">
            {isLoading ? (
              <Spinner />
            ) : (
              <table className="table table-striped table-dark">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Response</th>
                    <th>End Date</th>
                    <th>Action</th>
                    <th>Download Result</th>
                  </tr>
                </thead>
                <tbody>
                  {serveyData.map((data, index) => (
                    <tr key={data._id}>
                      <td>
                        {data.title}
                        <br />
                        <a
                          style={{ fontSize: '11px' }}
                          href={`/survey/${data.uuid}`}
                          target={'_blank'}
                        >
                          {window?.location?.href}/survey/{data.uuid}
                        </a>
                      </td>
                      <td>{data.responses}</td>
                      <td>{moment(data.endDate).format('MMM d, Y')}</td>
                      <td>
                        <Link
                          to={{
                            pathname: `survey-result/${data.id}`,
                            state: { survey: { ...data } }
                          }}
                          className="btn btn-link"
                        >
                          Results
                        </Link>
                      </td>
                      <td>
                        <a
                          className="btn btn-link text-success"
                          target="_blank"
                          href={`${
                            process.env.REACT_APP_BASEURL
                          }/survey/surveys/${
                            data.id
                          }/responses/download?token=${localStorage.getItem(
                            'token'
                          )}`}
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
/**
 * Employees Class
 */
// class Survey extends React.Component {
//   state = {
//     isLoading: false
//   };

//   componentDidMount() {}

//   render() {
//     const { isLoading } = this.state;
//     return isLoading ? (
//       <Spinner />
//     ) : (
//       <div className="row">
//         <div className="col-sm-12"></div>
//       </div>
//     );
//   }
// }

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({});

/**
 * Export Employees Class
 */
export default connect(mapStateToProps, mapDispatchToProps)(Survey);
