/**
 * Import NPM Packages
 */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import api from '../../axios';
/**
 * Import Redux functions
 */
import { uploadUserAccountLogo, signUp } from '../../redux/user/user.action';

import { jwtDecode } from '../../jwt-decode';

/**
 * Import styles
 */
import './avatar-upload.styles.scss';

/**
 * Import Components
 */
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import UpcomingEvent from '../upcoming-event/upcoming-event.component';
import Logo from '../logo/logo.component';
import Spinner from '../spinner/spinner.component';

/**
 * Go back component
 */
const GoBack = ({ handleChange }) => (
  <div className="f-foot-link text-center">
    <a
      style={{ cursor: 'pointer' }}
      className="font14 muli-semiBold blue-gary"
      onClick={handleChange}
    >
      Go Back
    </a>
  </div>
);

/**
 * SignUp Component
 */
const AvatarUpload = (props) => {
  const { history } = props;
  /**
   * React Hooks
   */
  const fileUploader = useRef(null);
  const [signUpForm, setSignUpForm] = useState(1);
  const [showFormError, setShowFormError] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [eventId, setEventId] = useState(null);
  const [validateEmail, setValidateEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState(null);

  const [userDetails, setUserDetails] = useState({});

  const [avatar, setAvatar] = useState({
    profileImage: '/assets/images/s-pro.png',
    sendProfileImage: ''
  });

  useEffect(() => {
    const userInfo = jwtDecode();

    setUserDetails({
      ...userInfo
    });
  }, []);

  /**
   * Handler Functions
   */

  const validateEmailAddress = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Profile picture upload function
   * @param {*} event
   */
  const uploadProfileHandler = (event) => {
    const profilePic = event.target.files;
    setAvatar((prevState) => ({
      ...prevState,
      sendProfileImage: profilePic[0],
      profileImage: URL.createObjectURL(profilePic[0])
    }));
  };

  /**
   * When form submits if all validation are perfect then next form is called
   */
  const formSubmitHandler = async () => {
    if (avatar.sendProfileImage) {
      setShowFormError(false);
      setIsLoading(true);

      setShowFormError(false);

      const { sendProfileImage } = avatar;
      const fileInfo = new FormData();

      fileInfo.append('file', sendProfileImage);
      fileInfo.append('name', sendProfileImage.name);
      try {
        const fileResponse = await api.post(`/media`, fileInfo, {
          headers: { auth: localStorage.getItem('token') }
        });

        const response = await api.put(
          `/users/${userDetails._id}`,
          { avatar: { ...fileResponse.data.data.file } },
          {
            headers: { auth: localStorage.getItem('token') }
          }
        );
        if (response)
          //   dispatch({
          //     type: type.UPLOAD_AVATAR,
          //     payload: response.data
          //   });
          history.push({
            pathname: '/dashboard'
          });
      } catch (error) {
        setIsLoading(false);
        setShowFormError(true);
        console.error(error);
        //   dispatch({
        //     type: type.UPLOAD_AVATAR,
        //     error: error.response
        //   });
      }

      //   uploadUserAccountLogo(userInfo).then((response) => {
      //     if (response?.data?.statusCode === 200) {
      //       setAvatar({
      //         profileImage: '/assets/images/s-pro.png',
      //         sendProfileImage: ''
      //       });
      //       setIsLoading(false);
      //       history.push({
      //         pathname: '/dashboard'
      //       });
      //     } else {
      //       setIsLoading(false);
      //       setRegistrationError(true);
      //     }
      //   });
    } else {
      setShowFormError(true);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="f-inn-content">
      <div className="row align-items-center">
        <div className="col-sm-12">
          <Logo />
        </div>
        {/* <div className="col-sm-6">
                {eventId ? <UpcomingEvent eventId={eventId} /> : null}
              </div> */}
        <div className="offset-sm-1 col-sm-5">
          <div className="sign-form">
            <div className="form-inputs" style={{ cursor: 'pointer' }}>
              <h5 className="font16 muli-bold blue-gary text-center">
                {userDetails.current_profile_type === 'Admin'
                  ? 'Upload a company photo, click on image to upload your photo'
                  : 'Let the students see your face. Click on image to upload your profile'}
              </h5>
              <div className="students-profile mx-auto">
                <img
                  src={avatar.profileImage}
                  alt=""
                  title=""
                  onClick={() => {
                    fileUploader.current.click();
                  }}
                />
                <input
                  type="file"
                  name="profileImage"
                  id="file"
                  ref={fileUploader}
                  style={{ display: 'none' }}
                  onChange={uploadProfileHandler}
                />
              </div>
              {showFormError && avatar.sendProfileImage === '' ? (
                <span className="signup-error">Please upload image</span>
              ) : null}
              {showFormError && avatar.sendProfileImage !== '' ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span className="signup-error">
                    Whoops, there was an issue uploading your photo
                  </span>
                </div>
              ) : null}
              <CustomButton
                className="s-btn blue-btn w-100 mr-t30 mr-b20"
                onClick={formSubmitHandler}
              >
                Upload
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({
  uploadUserAccountLogo: (userInfo) => dispatch(uploadUserAccountLogo(userInfo))
});

/**
 * Export SignUp Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(AvatarUpload);
