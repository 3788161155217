import { createSelector } from 'reselect';

const selectUser = (state) => state.survey;

export const currentSurvey = createSelector(
  [selectUser],
  (survey) => survey.data
);

export const surveyError = createSelector(
  [selectUser],
  (survey) => survey.error
);
