/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Import NPM Packages
 */
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tabs, Modal, notification, Spin, Pagination } from 'antd';
import {
  SafetyCertificateOutlined,
  RocketOutlined,
  AlertOutlined,
  BuildOutlined
} from '@ant-design/icons';

/**
 * Import styles
 */
import './event.styles.scss';

/**
 * Import Components
 */
import EmptyEvent from './empty-event/empty-event.component';
import { jwtDecode } from '../../jwt-decode';
import EventsHeader from './components/EventsHeader';
import EventCard from './components/EventCard';

/**
 * Import Redux functions
 */
import {
  deleteEvent,
  getAwaitingEvents,
  getPendingEvents,
  getUpcomingEvents,
  getAllEvents
} from '../../redux/event/event.action';
import qs from 'qs';
import { withRouter } from 'react-router-dom';

let tabLabelsAdmin = [
  { label: 'All', Icon: BuildOutlined },
  { label: 'Upcoming', Icon: RocketOutlined }
  // { label: 'Awaiting Invite Response', Icon: SafetyCertificateOutlined },
  // { label: 'Awaiting Approval', Icon: AlertOutlined }
];
let tabLabelsIP = [
  { label: 'All', Icon: BuildOutlined },
  { label: 'Upcoming', Icon: RocketOutlined },
  { label: 'Invited', Icon: SafetyCertificateOutlined },
  // { label: 'Attending', Icon: AlertOutlined },
  { label: 'Awaiting Approval', Icon: AlertOutlined }
];
let tabLabelsVolunteer = [
  { label: 'All', Icon: BuildOutlined },
  { label: 'Upcoming', Icon: RocketOutlined },
  { label: 'Invited', Icon: SafetyCertificateOutlined }
];

let tabLabels = [];
const Event = (props) => {
  const userDetail = jwtDecode();
  const {
    getAllEvents,
    getUpcomingEvents,
    getPendingEvents,
    getAwaitingEvents,
    deleteEvent,
    location
  } = props;

  const [cardIndex, setCardIndex] = useState(qs.parse(location.search, { ignoreQueryPrefix: true }).cardIndex);

  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [eventId, setEventId] = useState(null);
  const [deleteEventConfirmation, setDeleteEventConfirmation] = useState(false);


  useEffect(() => {
    if (cardIndex) {
      console.log('cardIndex:', cardIndex);
      handleTabsOnChange(cardIndex);
    }else {
      getEventsRequest();
    }
  }, [cardIndex]);

  if (userDetail.current_profile_type == 'Admin') {
    tabLabels = tabLabelsAdmin;
  }
  if (userDetail.current_profile_type == 'IndustryPartner') {
    tabLabels = tabLabelsIP;
  }
  if (userDetail.current_profile_type == 'Volunteer') {
    tabLabels = tabLabelsVolunteer;
  }

  const getEventsRequest = (
    tabIndex,
    page = 1,
    per_page = 8,
    loading = true
  ) => {
    if (loading) {
      setLoading(true);
      setEventList([]);
    }

    const params = {
      page,
      per_page,
      population: JSON.stringify(['invitees'])
    };

    let currentRequest = getAllEvents;
    const tab = tabLabels[tabIndex];
    if (tab?.label) {
      switch (tab.label) {
        case 'All':
          currentRequest = getAllEvents;
          break;
        case 'Upcoming':
          currentRequest = getUpcomingEvents;
          break;
        case 'Invited':
          currentRequest = getAwaitingEvents;
          break;
        case 'Unapproved':
        case 'Awaiting Approval':
          currentRequest = getPendingEvents;
          params.active = false;
          break;
        case 'Attending':
          currentRequest = getPendingEvents;
          break;
        default:
          break;
      }
    }

    currentRequest(params)
      .then((res) => {
        if (res.status === 200) {
          setEventList(res.data.data);
          setPagination(res.data._meta.pagination);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err :', err);
      });
  };

  const handleTabsOnChange = (tabIndex) => {
    setTabIndex(tabIndex);
    getEventsRequest(tabIndex);
  };

  const handleDeleteClick = (event) => {
    console.log('event :', event);
    setEventId(event._id);
    setDeleteEventConfirmation(true);
  };

  const deleteEventHandler = () => {
    setDeleteEventConfirmation(false);
    deleteEvent(eventId).then((response) => {
      if (response.status === 200) {
        notification['success']({
          message: 'Success',
          description: 'Event deleted successfully',
          style: {
            width: 600,
            marginLeft: 335 - 600
          }
        });
        getEventsRequest(tabIndex);
      }
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    getEventsRequest(tabIndex, page, pageSize, false);
  };

  return (
    <Fragment>
      <EventsHeader
        history={props.history}
        profileType={userDetail.current_profile_type}
      />
      <div className="row">
        <div className="col-sm-10">
          {/* was col-sm-8  */}
          <div className="row">
            {/* Tabs start */}
            <div className="col-sm-12 mx-auto">
              <Tabs
                defaultActiveKey={tabIndex}
                tabBarStyle={{
                  color: '#5B6FE0',
                  borderBottom: '1px solid #E7E8F2'
                }}
                onChange={handleTabsOnChange}
              >
                {tabLabels.map(({ label, Icon }, i) => (
                  <Tabs.TabPane
                    tab={
                      <span>
                        <Icon/> {label}
                      </span>
                    }
                    key={i}
                    activeKey={i}
                  >
                    <div className="row">
                      {loading && (
                        <div className="d-flex justify-content-center align-items-center w-100 mt-5">
                          <Spin className="mt-1 mr-3"/>{' '}
                          <span>
                            getting{' '}
                            {tabLabels[tabIndex].label.toLocaleLowerCase()}{' '}
                            events ...
                          </span>
                        </div>
                      )}
                      {!loading &&
                      (eventList !== null && eventList.length > 0 ? (
                        eventList.map((event, index) => (
                          <EventCard
                            key={index}
                            type={label}
                            event={event}
                            index={index}
                            handleDeleteClick={handleDeleteClick}
                          />
                        ))
                      ) : (
                        <EmptyEvent
                          title={`There are currently no ${tabLabels[
                            tabIndex
                            ].label.toLocaleLowerCase()} events`}
                          subText=""
                        />
                      ))}
                    </div>
                  </Tabs.TabPane>
                ))}
              </Tabs>
              {eventList.length > 0 && (
                <div className="d-flex justify-content-center w-100">
                  <Pagination
                    responsive
                    current={pagination.current}
                    pageSize={pagination.per_page || 8}
                    total={pagination.total_count}
                    onChange={handlePaginationChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* RIGHT SIDE PANEL */}
        {/* <div className="col-sm-4 side-calendar-wrap">
            <div className="white-box">
              <div className="side-calendar pd30">
                <RightNavHeader title="Todays date" date={this.state.date} />
                <div className="calendar-block mr-t30">
                  <div id="datepicker"></div>
                </div>
                <div className="pen-mail-list"></div>
              </div>
            </div>
          </div> */}
      </div>
      <Modal
        title="Delete Confirmation"
        visible={deleteEventConfirmation}
        onOk={() => deleteEventHandler(eventId)}
        onCancel={() => setDeleteEventConfirmation(false)}
      >
        <p>Do you want to delete this event?</p>
      </Modal>
    </Fragment>
  );
};

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({
  getAllEvents: (params) => dispatch(getAllEvents(params)),
  getUpcomingEvents: (params) => dispatch(getUpcomingEvents(params)),
  getAwaitingEvents: (params) => dispatch(getAwaitingEvents(params)),
  getPendingEvents: (params) => dispatch(getPendingEvents(params)),
  deleteEvent: (eventId) => dispatch(deleteEvent(eventId))
});

/**
 * Export Dashboard Class
 */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Event));
