/**
 * Import NPM Packages
 */
import React from 'react';

/**
 * Import styles
 */
import './form-input.styles.scss';

/**
 * FormInput Component
 */
const FormInput = React.forwardRef(
  (
    { handleChange, label, className, showError, errorText, ...otherProps },
    ref
  ) => {
    return (
      <div className="form-group">
        <label htmlFor="">{label}</label>
        <input
          onChange={handleChange}
          {...otherProps}
          className={className ? className : 'form-control cus-input'}
          ref={ref}
        />
        {showError ? <span className="signup-error">{errorText}</span> : null}
      </div>
    );
  }
);

/**
 * Export FormInput Component
 */
export default FormInput;
