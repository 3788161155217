/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import TableListItem from '../components/TableListItem';
import ListHeader from '../components/ListHeader';
import EmptyState from '../components/EmptyState';
import { Pagination } from 'antd';

const ListContainer = ({
  filteredList,
  getUsersRequest,
  pagination,
  currentTab,
  hideItem
}) => {
  const { total_count, per_page, current } = pagination;

  const handlePaginationChange = (page, pageSize) => {
    getUsersRequest(undefined, page, pageSize);
  };

  if (filteredList.length === 0)
    return <EmptyState text="Currently no users to display, check back soon" />;
  else
    return (
      <Fragment>
        <table className="table mt-0">
          <ListHeader hideItem={hideItem} status />
          <tbody>
            {filteredList.map((employee, i) => (
              <TableListItem
                getUsersRequest={getUsersRequest}
                user={employee}
                key={i}
                index={i}
                currentPage={current}
                currentTab={currentTab}
                hideItem={hideItem}
              />
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-center w-100">
          <Pagination
            responsive
            current={current}
            pageSize={per_page}
            total={total_count}
            onChange={handlePaginationChange}
          />
        </div>
      </Fragment>
    );
};

export default ListContainer;
