import { type } from './event.types';
import api from '../../axios';

export const upcomingEvent = (eventInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.UPCOMING_EVENT_DETAIL_START
    });

    try {
      const response = await api.post(
        '/event/get-eventDetailsByUDID',
        eventInfo
      );
      dispatch({
        type: type.UPCOMING_EVENT_DETAIL_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.UPCOMING_EVENT_DETAIL_ERROR,
        error: error.response
      });
    }
  };
};

export const createEvent = (eventInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.CREATE_EVENT_START
    });

    try {
      const response = await api.post('/events', eventInfo, {
        headers: {
          auth: localStorage.getItem('token')
        }
      });
      dispatch({
        type: type.CREATE_EVENT_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.CREATE_EVENT_ERROR,
        error: error.response
      });
    }
  };
};

export const sendInvite = (invitationInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.SEND_EVENT_INVITATION_START
    });

    try {
      const response = await api.post('/event/send-invite', invitationInfo, {
        headers: {
          auth: localStorage.getItem('token')
        }
      });
      dispatch({
        type: type.SEND_EVENT_INVITATION_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.SEND_EVENT_INVITATION_ERROR,
        error: error.response
      });
    }
  };
};

export const editEvent = (eventId, payload) => {
  return async (dispatch) => {
    dispatch({
      type: type.EDIT_EVENT_START
    });
    try {
      console.log('payyyyload', payload);
      const response = await api.put(`/events/${eventId}`, payload, {
        headers: {
          auth: localStorage.getItem('token')
        }
      });
      console.log('editEvent-response-action:', response);
      dispatch({
        type: type.EDIT_EVENT_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.EDIT_EVENT_ERROR,
        error: error.response
      });
    }
  };
};

export const getEvent = (eventInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.GET_EVENT_START
    });

    try {
      const response = await api.get(
        '/events?population=[{"path":"attendees"},{"path":"invitees"}]&sort[createdAt]=desc',
        {
          headers: {
            auth: localStorage.getItem('token')
          }
        }
      );
      dispatch({
        type: type.GET_EVENT_SUCCESS,
        payload: response.data.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.GET_EVENT_ERROR,
        error: error.response?._meta
      });
    }
  };
};

export const getEventsForUser = (eventInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.GET_EVENT_START
    });

    try {
      const response = await api.post('/events', {
        headers: {
          auth: localStorage.getItem('token')
        }
      });
      dispatch({
        type: type.GET_EVENT_SUCCESS,
        payload: response.data.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.GET_EVENT_ERROR,
        error: error.response
      });
    }
  };
};

// Accept event invite -> NEW
export const acceptEventInvite = (eventId, cB) => async (dispatch) => {
  try {
    const response = await api.post(`/events/accept-invite`, { event_id: eventId });
    console.log('response :>> ', response);
    if (cB) cB();
  } catch (error) {
    dispatch({
      type: type.DELETE_EVENT_ERROR,
      error: error.response
    });
  }
};
// Delete event -> NEW
export const deleteEvent = (eventId) => {
  return async (dispatch) => {
    dispatch({
      type: type.DELETE_EVENT_START
    });
    try {
      const response = await api.delete(`/events/${eventId}`);
      dispatch({
        type: type.DELETE_EVENT_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.DELETE_EVENT_ERROR,
        error: error.response
      });
    }
  };
};
// GET all events -> NEW
export const getAllEvents = (params) => async (dispatch) => {
  try {
    const response = await api.get(`/events`, {
      params: {
        per_page: 10,
        ...params
      }
    });
    return response;
  } catch (error) {
    dispatch({
      type: type.GET_EVENT_ERROR,
      error: error.response?._meta
    });
  }
};
// GET upcoming events -> NEW
export const getUpcomingEvents = (params) => async (dispatch) => {
  try {
    const response = await api.get(`/events/upcomingEvents`, {
      params: {
        per_page: 10,
        ...params
      }
    });
    return response;
  } catch (error) {
    dispatch({
      type: type.GET_EVENT_ERROR,
      error: error.response?._meta
    });
  }
};

// GET awaiting events -> NEW
export const getAwaitingEvents = (params) => async (dispatch) => {
  try {
    const response = await api.get(`/events/awaiting-invite-response`, {
      params: {
        per_page: 10,
        ...params
      }
    });
    return response;
  } catch (error) {
    dispatch({
      type: type.GET_EVENT_ERROR,
      error: error.response?._meta
    });
  }
};

// GET pending events -> NEW
export const getPendingEvents = (params) => async (dispatch) => {
  try {
    const response = await api.get(`/events/pending-approval`, {
      params: {
        per_page: 10,
        ...params,
        active: false
      }
    });
    console.log('pending-events:', response);
    return response;
  } catch (error) {
    dispatch({
      type: type.GET_EVENT_ERROR,
      error: error.response?._meta
    });
  }
};

export const getEventById = (eventInfo) => {
  return async (dispatch) => {
    dispatch({
      type: type.GET_EVENT_BY_ID_START
    });
    try {
      const response = await api.get(
        `/events/${eventInfo?.eventId}?population=["attachments", "attendees", "user", "invitees"]`,
        {
          headers: {
            auth: localStorage.getItem('token')
          }
        }
      );
      dispatch({
        type: type.GET_EVENT_BY_ID_SUCCESS,
        payload: response.data.data
      });
      return response.data.data;
    } catch (error) {
      dispatch({
        type: type.GET_EVENT_BY_ID_ERROR,
        error: error.response
      });
    }
  };
};

export const getEventActivityType = () => {
  return async (dispatch) => {
    dispatch({
      type: type.GET_EVENT_ACTIVITY_TYPE_START
    });

    try {
      const response = await api.get('/activity/get-activity');
      dispatch({
        type: type.GET_EVENT_ACTIVITY_TYPE_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.GET_EVENT_ACTIVITY_TYPE_ERROR,
        error: error.response
      });
    }
  };
};

export const acceptDeclineInvite = async (invitationInfo) => {
  try {
    const response = await api.post(
      '/event/acceptDecline-event',
      invitationInfo,
      {
        headers: {
          auth: localStorage.getItem('token')
        }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const acceptDeclineEvent = async (invitationInfo) => {
  try {
    const response = await api.post(
      '/event/acceptDecline-event',
      invitationInfo,
      {
        headers: {
          auth: localStorage.getItem('token')
        }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const markAttendenceEvent = async (invitationInfo) => {
  try {
    const response = await api.post(
      '/event/mark-attendance-event',
      invitationInfo,
      {
        headers: {
          auth: localStorage.getItem('token')
        }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
