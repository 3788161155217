import { createSelector } from 'reselect';

const selectUser = (state) => state.user;
const selectEmployees = (state) => state.employees;

export const registrationDetails = createSelector(
  [selectUser],
  (user) => user.registrationDetails
);

export const loginDetails = createSelector(
  [selectUser],
  (user) => user.loginDetails
);

export const loginError = createSelector(
  [selectUser],
  (user) => user.loginError
);

export const dashboardDetails = createSelector(
  [selectUser],
  (user) => user.dashboardDetails
);

export const userDetails = createSelector(
  [selectUser],
  (user) => user.userDetails
);

export const allUserDetails = createSelector(
  [selectUser],
  (user) => user.allUserDetails
);

export const userErrors = createSelector([selectUser], (user) => user.error);
export const employees = createSelector(
  [selectEmployees],
  (employees) => employees
);
