/**
 * Import NPM Packages
 */
import React from 'react';

/**
 * Import styles
 */
import './dashboard-card.styles.scss';

/**
 * DashboardCard Component
 */
const DashboardCard = ({ count, imgUrl, label }) => {
  return (
    <div className="white-box event-s-card pd20 pd-t30 pd-b30">
      <img src={imgUrl} alt="" title="" />
      <h4 className="mr-t10 mr-b10">{count}</h4>
      <span>{label}</span>
    </div>
  );
};

/**
 * Export DashboardCard Component
 */
export default DashboardCard;
