/**
 * Import NPM Packages
 */
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import CalendarModal from './calender.modal.component';
import $ from 'jquery';
import api from '../../axios';
/**
 * Import styles
 */
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './calendar.styles.scss';
import { jwtDecode } from '../../jwt-decode';
import Spinner from '../spinner/spinner.component';

/**
 * Calendar Class
 */
class Calendar extends React.Component {
  calendarComponentRef = React.createRef();

  /**
   * State of the Class
   */
  
  state = {
    date: new Date(),
    eventId:null,
    calendarWeekends: true,
    isLoading: false,
    calendarEvents: [
      // initial event data
    ]
  };

  userDetails = jwtDecode();

  getEventsForCalender = (eventInfo) => {
    let apiUrl = '/event/get-eventsForCalendarForUser';
    if(this.userDetails.roleID == 3) {
      apiUrl = '/event/get-eventsForCalendar';
    }


    api.post(apiUrl, eventInfo, {
      headers: {
        auth: localStorage.getItem('token')
      }
    }).then((response) => {
      if(response.data) {
        let events = response.data.result;
        if(events.length > 0 && Array.isArray(events)) {
          var events_arr = []
          events.forEach(element => {
            let date = new Date(element.event_startdatetime);
            date = date.toISOString().substring(0, 10);          
            
            let startTime = new Date(element.event_startdatetime);
            let endTime = new Date(element.event_enddatetime);
            let time = startTime.getUTCHours() + ':' + startTime.getUTCMinutes();
            time += ' - ' + endTime.getUTCHours() + ':' + endTime.getUTCMinutes();

            let className = "info";
            if(element.status == 'DECLINED')
              className = "important";
            else if(element.status == 'ATTENDED')
              className = "success";
            else if(element.status == 'ACCEPTED')
              className = "chill";
              
            events_arr.push({title: element.event_title + '_' + time + '_' + element.id, start: element.event_startdatetime, classNames: [className]});
          });
          this.setState({calendarEvents: events_arr});
        }
        this.setState({ isLoading: false });
      }
    }, (error) => {
      console.log(error);
    });
 
  };

  getStartMonthDay(date) {
    let tempDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let formatted_date = tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1) + "-" + tempDate.getDate();
    return formatted_date;
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    let date = new Date();
    date = this.getStartMonthDay(date);
    this.setState({date: date})
    this.getEventsForCalender({ date: date, filter: 'month' });

    var obj = this;
    $(document).on("click", ".fc-next-button, .fc-prev-button, .fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button", function() {
      let type = $(".fc-button.fc-button-active").text();
      let date = obj.getStartDate(type);
      obj.getEventsForCalender({ date: date, filter: type });
    });

    $(document).on("click", ".eventObject", function() {
      let id = $(this).attr('data-id');
      obj.setState({eventId: id});
    });

    $(document).on("click", ".fc-today-button", function() {
      $(".fc-button.fc-button-primary").removeClass("fc-button-active");
      $(".fc-timeGridDay-button").addClass('fc-button-active');
      obj.getEventsForCalender({ date: obj.state.date, filter: 'day' });
    });
  }

  toggleWeekends = () => {
    this.setState({
      calendarWeekends: !this.state.calendarWeekends
    });
  };

  getStartDate = (type) => {
    let months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthStr = $(".fc-toolbar.fc-header-toolbar .fc-center h2").text();
    if(type == 'month') {
      monthStr = monthStr.split(" ");
      monthStr[0] = monthStr[0].trim();
      let month = months.indexOf(monthStr[0]) + 1;
      let year = monthStr[1].trim();
      var d = new Date(year, month, 0);
      return this.getStartMonthDay(d);
    }
    else if(type == 'week' || type == 'day') {
      monthStr = monthStr.split(",");
      let year = monthStr[1].trim();
      monthStr = monthStr[0].trim();
      monthStr = monthStr.split(" ");
      let month = months.findIndex(a =>a.includes(monthStr[0]));
      var d = new Date(year, month, monthStr[1]);
      d = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      return d;
    }
  }

  showData = (info) => {
    let title = info.event.title.split("_");
    let html = '<p class="eventObject" data-id="'+title[2]+'"><b>'+title[0]+'</b><br/>'+title[1]+'</p>';
    info.el.querySelector('.fc-title').innerHTML = html;
  }

  handleClick = () => {
    let date = new Date();
    date = this.getStartMonthDay(date);
    this.setState({eventId: null, isLoading: true, date: date});
    this.getEventsForCalender({ date: date, filter: 'month' });
  }

  render() {
    
    return (
        this.state.isLoading ? (
          <Spinner />
        ) : (
          <div className="row">
            <div className="col-sm-12">
              <h3
                className="page-heading mr-b30"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                Events
              </h3>
            </div>
            <div className="col-sm-12">
              <div className="white-box e-cal-wrap">
                <div className="e-cal-block">
                  <FullCalendar
                    defaultView="dayGridMonth"
                    header={{
                      left: 'dayGridMonth,timeGridWeek,timeGridDay',
                      center: 'title',
                      right: 'prev,next today'
                    }}
                    firstDay={1} 
                    displayEventTime ={false}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    ref={this.calendarComponentRef}
                    weekends={this.state.calendarWeekends}
                    events={this.state.calendarEvents}
                    eventRender={this.showData}
                    monthCellRender={(e) => this.openEvent(e)} 
                  />
                </div>
              </div>
            </div>
            {
              this.state.eventId ? <CalendarModal id={this.userDetails.id} role={this.userDetails.roleID} eventId={this.state.eventId} handleClick={this.handleClick}></CalendarModal> : null
            }
          </div>
        )
    );
  }
}

/**
 * Export Calendar Class
 */
export default (Calendar);
