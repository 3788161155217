import React from 'react';
import PropTypes from 'prop-types';
import avatar from '../avatar.png';
import { Card } from 'antd';

const InviteCard = ({ user }) => {
  return (
    <div className="col-sm-6">
      <Card
        style={{
          borderRadius: 12,
          border: '1px solid #ececf2',
          marginBottom: 10
        }}
        className="invite-card d-flex flex-column"
      >
        <div className="t-card-body flex1">
          <div className="card-pro-info text-center">
            <div className="card-pro-img">
              <img src={user.avatar ? user.avatar.url : avatar} alt="..." />
              {/* <em className="a-status"></em> */}
            </div>
            <h4>
              {user.first_name} {user.last_name}
            </h4>
            {/* <span>
                    {user.attended}/{user.ivited} Attended
                  </span> */}
            <h5>
              {user.current_profile_type === 'Volunteer'
                ? 'Volunteer'
                : user.current_profile_type === 'IndustryPartner'
                ? 'Industry Partner'
                : 'Admin'}
            </h5>
          </div>
          {/* <div className="card-drop">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="/assets/images/d-icon.png" alt="" title="" />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <button className="dropdown-item" type="button">
                Action
              </button>
              <button className="dropdown-item" type="button">
                Another action
              </button>
              <button className="dropdown-item" type="button">
                Something else here
              </button>
            </div>
          </div> */}
        </div>
        {/* <div className="t-card-food">
          <a target="_blank" href={'mailto:' + user.email}>
            <img src="/assets/images/mail.icon.png" alt="" title="" />
            Email
          </a>
       <a href="tel:9523238036">
            <img src="/assets/images/phone-icon.png" alt="" title="" />
            Call
          </a> 
        </div>*/}
      </Card>
    </div>
  );
};
InviteCard.propTypes = { user: PropTypes.object.isRequired };
export default InviteCard;
