/**
 * Import NPM Packages
 */
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
/**
 * Import Components
 */
import LeftSidebar from '../components/left-sidebar/left-sidebar.component';
import Dashboard from '../components/dashboard/dashboard.component';
import Event from '../components/events/events.component';
import EventOperation from '../components/events/event-operation/event-operation.component';
import EventDetail from '../components/events/event-detail/event-detail.component';
import Calendar from '../components/calendar/calendar.component';
import Employees from '../components/employees/employees.component';
import Survey from '../components/survey-result/survey.component';
import SettingsPage from '../components/settings/settings.component';
import SurveyDetail from '../components/survey-result/servey.detail.component';
import AvatarUpload from '../components/avatar-upload/avatar-upload.component';
import { store } from '../redux/store';
import { getCurrentUser } from '../redux/user/user.action';

/**
 * Layout Component
 */
const Main = ({ dispatch }) => {
  const history = useHistory();

  //get user
  store.dispatch(getCurrentUser());

  return (
    <div>
      <LeftSidebar history={history} />
      <main className="body-content-wrap pd-t80">
        <div className="content-block">
          <Route exact path="/" component={Dashboard} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/events" component={Event} />
          <Route path="/create-event" component={EventOperation} />
          <Route path="/edit-event" component={EventOperation} />
          <Route path="/event-detail" component={EventDetail} />
          <Route path="/calendar" component={Calendar} />
          <Route path="/survey-result" component={Survey} exact />
          <Route path="/survey-result/:id" component={SurveyDetail} exact />
          <Route path="/employees" component={Employees} />
          <Route path="/settings" component={SettingsPage} />
          {/*<Route path="/pending-approval" component={PendingApproval}/>*/}
          <Route path="/messages" />
          <Route path="/avatar-upload" component={AvatarUpload} />
        </div>
      </main>
    </div>
  );
};

/**
 * Export Layout Component
 */
export default Main;
