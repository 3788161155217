/**
 * Import NPM Packages
 */
import React from 'react';

/**
 * Import styles
 */
import './custom-button.styles.scss';

/**
 * CustomButton Component
 */
const CustomButton = ({ children, className, ...props }) => (
  <button className={`custom-button ${className}`} {...props}>
    {children}
  </button>
);

/**
 * Export CustomButton Component
 */
export default CustomButton;
