import { type } from './event.types';
const INITIAL_STATE = {
  loading: false,
  error: null,
  getEventDetails: null,
  upcomingEventDetail: null,
  getEventDetail: null,
  getActivityType: null,
  createEvent: null,
  sendInvite: null,
  deleteEventDetail: null
};

const eventReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.UPCOMING_EVENT_DETAIL_START:
      return {
        ...state,
        loading: true,
        error: null,
        upcomingEventDetail: null
      };
    case type.UPCOMING_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        upcomingEventDetail: action.payload,
        error: null
      };
    case type.UPCOMING_EVENT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        upcomingEventDetail: null,
        error: action.error
      };

    case type.CREATE_EVENT_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case type.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        createEvent: action.payload,
        error: null
      };
    case type.CREATE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        createEvent: null,
        error: action.error
      };

    case type.SEND_EVENT_INVITATION_START:
      return {
        ...state,
        loading: true,
        sendInivite: null,
        error: null
      };
    case type.SEND_EVENT_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        sendInivite: action.payload,
        error: null
      };
    case type.SEND_EVENT_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
        sendInivite: null,
        error: action.error
      };

    case type.EDIT_EVENT_START:
      return {
        ...state,
        loading: true,
        editEvent: null,
        error: null
      };
    case type.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        editEvent: action.payload,
        error: null
      };
    case type.EDIT_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        editEvent: null,
        error: action.error
      };

    case type.GET_EVENT_START:
      return {
        ...state,
        loading: true,
        getEventDetails: null,
        error: null
      };
    case type.GET_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getEventDetails: action.payload,
        error: null
      };
    case type.GET_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        getEventDetails: null,
        error: action.error
      };

    case type.DELETE_EVENT_START:
      return {
        ...state,
        loading: true,
        deleteEventDetail: null,
        error: null
      };
    case type.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteEventDetail: action.payload,
        error: null
      };
    case type.DELETE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        deleteEventDetail: null,
        error: action.error
      };

    case type.GET_EVENT_BY_ID_START:
      return {
        ...state,
        loading: true,
        getEventDetail: null,
        error: null
      };
    case type.GET_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getEventDetail: action.payload,
        error: null
      };
    case type.GET_EVENT_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        getEventDetail: null,
        error: action.error
      };

    case type.GET_EVENT_ACTIVITY_TYPE_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case type.GET_EVENT_ACTIVITY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        getActivityType: action.payload,
        error: null
      };
    case type.GET_EVENT_ACTIVITY_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        getActivityType: null
      };

    default:
      return state;
  }
};

export default eventReducer;
