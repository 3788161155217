/**
 * Import NPM Packages
 */
import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Spin, Tabs, Modal, Button } from 'antd';
import { PicLeftOutlined, TeamOutlined } from '@ant-design/icons';
import $ from 'jquery';
import qs from 'qs';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import moment from 'moment';
/**
 * Import Redux functions
 */

/**
 * Import Components
 */
import Spinner from '../../spinner/spinner.component';
import EventDetailHeader from '../event-detail/components/EventDetailHeader';
import EventDetailCard from '../event-detail/components/EventDetailCard';
import AttendingContainer from '../event-detail/containers/AttendingContainer';
import Logo from '../../logo/logo.component';
import avatar from '../event-detail/avatar.png';
import Pagination from '../event-detail/pagination';
import CustomButton from '../../custom-button/custom-button.component';
import api from '../../../axios';
/**
 * Import styles
 */
import './public-event-detail.styles.scss';
import { parseBlobUrl } from '../../../utils/helper';
import { acceptEventInvite } from '../../../redux/event/event.action';
import { signIn } from '../../../redux/user/user.action';

/**
 * Event Class
 */
const PublicEventDetail = (props) => {
  const { getEventById, location, history, acceptEventInvite } = props;
  const { eventId } = useParams();
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, seToken] = useState(qs.parse(location.search, { ignoreQueryPrefix: true }).token);
  const [decoded, setDecoded] = useState(null);


  /**
   * State of the Class
   */
  const [eventDetail, setEventDetail] = useState({});
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useState(() => {
    if (token) {
      setDecoded(jwtDecode(token));
      console.log('decoded:', decoded);
      console.log('token:', token);
    }
  }, [token]);

  useEffect(() => {
    if (decoded) {
      console.log('decoded:', decoded);
      const sessionTimExp = decoded.exp;
      if (sessionTimExp > new Date().getDate() / 1000) {
        console.log('expired invitation');
        // setExpired(true);
      }
    }
  }, [expired]);

  useEffect(() => {
    if (token && !localStorage.getItem('token')) {
      history.push(`/signIn?redirectUrl=${window.location.protocol}//${window.location.host}/event/${eventId}?token=${token}`);
    }
    if (eventId) {
      setIsLoading(true);
      api
        .get(
          `/events/uuid/${eventId}?population=["invitees","attendees", "attachments", "user"]`
        )
        .then((response) => {
          console.log('event-response:', response);
          setEventDetail(response.data.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          history.push('/events');
        });
    } else {
      history.push('/events');
    }
  }, [eventId, getEventById]);


  const copyClipboard = () => {
    $('.tooltiptext').show();
    setTimeout(() => {
      $('.tooltiptext').hide();
    }, 2000);
  };

  const getPaginatedItems = (items = []) => {
    return items.slice(currentPage, currentPage + itemPerPage);
  };

  const handlePaginationChange = (e, currentPage) => {
    e.preventDefault();
    setCurrentPage(currentPage);
  };


  const formSubmitHandler = e => {
    console.log('accept clicked');
    setLoading(true);
    api.post(`/events/accept-invite`, { event_id: eventId })
      .then((response) => {
        console.log('accept-invite-response:', response);
        if (response.data.data) {
          setLoading(false);
          notification.open({
            message: 'Success',
            description: 'Invite successfully accepted'
          });
          setEvent(response.data.data);
          // history.push(`/dashboard`);
        }

      })
      .catch(err => {
        setLoading(false);
        notification.open({
          message: 'Error',
          description: 'Unable to successfully accept invite'
        });
        console.log('accept-invite-err:', err);
      });
    acceptEventInvite(eventId);
  };

  const handleCalender = e => {
    setIsModalVisible(true);
  };

  const handleDone = e => {
    history.push('/dashboard');
  };

  const handleLink = (type = 'google') => {
    const eventOption = {
      start: moment(event.start_date),
      end: moment(event.end_date),
      title: event.title,
      location: event.location,
      details: event.url
    };
    let url;
    switch (type) {
      case 'google':
        url = google(eventOption);
        break;
      case 'outlook':
        url = outlook(eventOption);
        break;
      case 'office365':
        url = office365(eventOption);
        break;
      case 'yahoo':
        url = yahoo(eventOption);
        break;
    }
    window.open(url);
  };

  return isLoading ? (
    <Spinner/>
  ) : (
    <div className="body-content-wrap">
      <div className="container pe-details">
        <div className="logo-wrap">
          <Logo/>
        </div>
        <div className="row">
          {expired && (
            <div>
              <h3 className="page-heading">
                Invitation expired, please contact admin. Thank you.
              </h3>
            </div>
          )}

          {!token && eventId && (
            <div className="col-sm-12">
              <div className="pe-details__inner">
                <div className="white-box">
                  <EventDetailHeader
                    eventDetail={eventDetail}
                    copyClipboard={copyClipboard}
                  />
                  {/* TABS START */}
                  <Tabs
                    tabBarStyle={{
                      color: '#5B6FE0',
                      borderBottom: '1px solid #E7E8F2',
                      marginTop: 10
                    }}
                  >
                    <Tabs.TabPane
                      s
                      tab={
                        <span>
                <PicLeftOutlined/>
                Event Details
                </span>
                      }
                      key="1"
                    >
                      <EventDetailCard eventDetail={eventDetail}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                <TeamOutlined/>
                Attending
                </span>
                      }
                      key="3"
                    >
                      <AttendingContainer users={eventDetail?.attendees}/>
                    </Tabs.TabPane>
                  </Tabs>
                </div>

                <div className="invite-list-wrap mb-4">
                  <div className="white-box accept-in-list">
                    <h5>Invited User List</h5>
                    {eventDetail && eventDetail.invitees.length > 0 ? (
                      getPaginatedItems(eventDetail.invitees).map((invitee) => (
                        <div className="invited-user d-flex" key={invitee.id}>
                          <div className="invited-user__avatar">
                            <img
                              src={invitee.avatar ? parseBlobUrl(invitee.avatar.url) : avatar}
                              alt="avatar"
                            />
                          </div>
                          <div className="user-info">
                            <h4>{invitee.first_name}</h4>
                            <p>{invitee.email}</p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <strong className="text-center text-muted">
                        no invited user found
                      </strong>
                    )}
                    {/** Pagination **/}
                    {eventDetail && eventDetail.invitees && (
                      <Pagination
                        data={eventDetail.invitees}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        handleChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {token && !expired && eventId && (
            <div className="pe-details__inner">
              <h3 className="page-heading">
                Thanks for accepting this invite we look forward to your participation
              </h3>
              <div className="row">
                {!event ? (
                  <CustomButton
                    className="s-btn blue-btn w-80 mr-t30 mr-b20"
                    disabled={loading}
                    onClick={formSubmitHandler}
                    loading={loading}
                  >
                    {loading ? 'Processing...' : 'ACCEPT INVITE'}
                  </CustomButton>
                ) : (
                  <CustomButton
                    className="s-btn blue-btn w-80 mr-t30 mr-b20"
                    disabled={loading}
                    onClick={handleDone}
                    loading={loading}
                  >
                    Done
                  </CustomButton>
                )}

                <CustomButton
                  className="s-btn blue-btn w-80 mr-t30 mr-b20"
                  disabled={loading || !event}
                  style={{ marginLeft: 10 }}
                  onClick={handleCalender}
                >
                  ADD TO CALENDER
                </CustomButton>
              </div>
              <Modal title="Calendar List" visible={isModalVisible} onOk={() => setIsModalVisible(false)}
                     onCancel={() => setIsModalVisible(false)}>
                <p><Button type="link" onClick={(e) => handleLink('google')} block><a>Google</a></Button></p>
                <p><Button type="link" onClick={(e) => handleLink('outlook')} block><a>Outlook</a></Button></p>
                <p><Button type="link" onClick={(e) => handleLink('office365')} block><a>Office365</a></Button></p>
                <p><Button type="link" onClick={(e) => handleLink('yahoo')} block><a>Yahoo</a></Button></p>
              </Modal>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => ({
  acceptEventInvite: (eventId, getEvent) => dispatch(acceptEventInvite(eventId, getEvent))
});
/**
 * Export Event Class
 */
export default connect(null, mapDispatchToProps)(withRouter(PublicEventDetail));
