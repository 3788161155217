import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { jwtDecode } from '../../../jwt-decode';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const EventCard = ({ event, index, handleDeleteClick, type }) => {
  const userDetail = jwtDecode();
  const { _id, current_profile_type } = userDetail;

  const menu = (
    <Menu style={{ width: '100%' }}>
      <Menu.Item key="0">
        <Link
          to={{
            pathname: '/event-detail',
            state: {
              eventId: event._id
            }
          }}
          className="dropdown-item"
        >
          View
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link
          to={{
            pathname: '/edit-event',
            state: {
              eventId: event._id
            }
          }}
          className="dropdown-item"
        >
          Edit
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link
          to="/events"
          className="dropdown-item"
          onClick={() => handleDeleteClick(event)}
        >
          Delete
        </Link>
      </Menu.Item>
    </Menu>
  );

  const cardType =
    type === 'Awaiting'
      ? 'orange-border-card'
      : type === 'Upcoming'
      ? 'green-border-card'
      : 'red-border-card';


  return (
    <div className="col-sm-6 mr-b30" key={event.id}>
      {/* {console.log('event', event)}
      {console.log('_id :>> ', _id)} */}
      <Link
        to={{
          pathname: '/event-detail',
          state: {
            eventId: event._id
          }
        }}
      >
        <div className={'event-card ' + cardType}>
          <div className="inn-e-card">
            <div className="event-card__header">
              <h5>{event.title}</h5>
              {(event.user === _id || userDetail.current_profile_type == 'Admin') && (
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <button
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img src="/assets/images/v-dots-icon.svg" alt="" />
                  </button>
                </Dropdown>
              )}
            </div>
            <p>
              {moment(event.start_date).format('MMMM Do YYYY')},
              <span>
                {moment(event.start_date).format('hh:mm a')} -{' '}
                {moment(event.end_date).format('hh:mm a')}
              </span>
            </p>
            <div className="card-profile">
              {event.attendees
                ?.filter((x, i) => i < 5)
                .map((attendee, index) => (
                  <div className="card-p-img" key={index}>
                    <img src={attendee.avatar?.url} alt="" title="" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default EventCard;
