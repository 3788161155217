import React, { useState } from 'react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Dropdown, Menu, Modal, notification } from 'antd';
import Attending from '../../../attending/attending.component';
import EventTime from '../../../event-time/event-time.component';
import EventHours from './EventHours';
import { Link } from 'react-router-dom';
import { jwtDecode } from '../../../../jwt-decode';
import api from '../../../../axios';

const EventDetailHeader = ({
                             eventDetail,
                             attendingData,
                             userDetail,
                             copyClipboard,
                             location
                           }) => {
  const [deleteEventConfirmation, setDeleteEventConfirmation] = useState(false);
  const { _id: id, start_date, end_date } = eventDetail;
  console.log('eventDetail :', eventDetail);
  const [user, setUser] = useState(jwtDecode());
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleApproveEvent = (e) => {
    e.preventDefault();
    api.put(`/events/${eventDetail._id}`, { status: false })
      .then((response) => {
        console.log('accept-invite-response:', response);
        if (response.data.data) {
          setLoading(false);
          notification.open({
            message: 'Success',
            description: 'Event successfully approved'
          });
        }
        setShow(false);
      })
      .catch(err => {
        setLoading(false);
        notification.open({
          message: 'Error',
          description: 'Unable to approve event'
        });
        setShow(false);
        console.log('accept-invite-err:', err);
      });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link
          to={{
            pathname: '/edit-event',
            state: {
              eventId: id
            }
          }}
          className="dropdown-item"
        >
          Edit
        </Link>
      </Menu.Item>
    </Menu>
  );


  return (
    <div className="details-head">
      <div className="event-title">
        <h4>{eventDetail.title}</h4>

        <div className="row">
          <div className="col-sm-8">
            <span>
              Created by{' '}
              <em>
                {eventDetail.user && eventDetail.user.first_name}{' '}
                {eventDetail.user && eventDetail.user.last_name}
              </em>
              , on {moment(eventDetail.createdAt).format('ddd, DD MMM yyyy')}
            </span>
          </div>
          <div className="col-sm-2">
            <span className="tooltiptext">Share link copied!</span>
            <CopyToClipboard
              text={`${window.location.protocol}//${window.location.host}/event/${eventDetail?.uuid}`}
              onCopy={copyClipboard}
            >
              <Button>Copy Event URL</Button>
            </CopyToClipboard>
            <br/>
            <br/>
            {(user && user.current_profile_type === 'Admin' && eventDetail.status) && (
              <Button onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}>
                Approve Event
              </Button>
            )}
          </div>
          <div className={'col-sm-2'}>
            {/*{menu &&*/}
            {/*(userDetail?.current_profile_type == 'Admin' ||*/}
            {/*eventDetail.user == userDetail._id) && (*/}
            {/*<Dropdown.Button*/}
            {/*onClick={(e) => e.preventDefault()}*/}
            {/*overlay={menu}*/}
            {/*placement="bottomCenter"*/}
            {/*icon={*/}
            {/*<img*/}
            {/*style={{ margin: '5px 5px' }}*/}
            {/*src="/assets/images/v-dots-icon.svg"*/}
            {/*alt=""*/}
            {/*/>*/}
            {/*}*/}
            {/*></Dropdown.Button>*/}
            {/*)}*/}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <Attending attendingData={eventDetail.attendees}/>
        </div>
        <div className="col-sm-4">
          <EventHours eventDetail={eventDetail}/>
        </div>
        <div className="col-sm-4">
          <EventTime dateTime={{ start_date, end_date }}/>
        </div>
        <Modal
          title="Approval"
          visible={show}
          onOk={handleApproveEvent}
          onCancel={() => setShow(false)}
        >
          <p>Are you sure you want to approve this event?</p>
        </Modal>
      </div>
    </div>
  );
};

export default EventDetailHeader;
