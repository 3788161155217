import React, { useState, useEffect } from 'react';

const Pagination = ({
  data = [],
  currentPage = 0,
  itemPerPage = 2,
  handleChange,
  ...props
}) => {
  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (data.length > itemPerPage) {
      const total = data.length - 1;
      const totalPage = Math.round(total / itemPerPage);
      setPages(Array(totalPage).fill(0));
    }
  }, [currentPage, data, handleChange, itemPerPage]);

  return (
    <>
      {pages.length ? (
        <nav aria-label="Page navigation" className="mt-5">
          <ul className="pagination justify-content-center pagination-sm">
            <li className={`page-item ${currentPage > 0 ? '' : 'disabled'}`}>
              <button
                className="page-link"
                onClick={(e) => handleChange(e, currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {pages.map((item, i) => (
              <li
                className={`page-item ${currentPage === i ? 'active' : ''}`}
                key={Math.random().toString()}
              >
                <button
                  className={`page-link`}
                  onClick={(e) => handleChange(e, i)}
                >
                  {i + 1}
                </button>
              </li>
            ))}

            <li
              className={`page-item ${
                currentPage === pages.length ? 'disabled' : ''
              }`}
            >
              <button
                className="page-link"
                onClick={(e) => handleChange(e, currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      ) : null}
    </>
  );
};

export default Pagination;
