/**
 * Import NPM Packages
 */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { jwtDecode } from '../jwt-decode';

class Authentication extends React.Component {
  render() {
    const MainComponent = this.props.component;
    const token = localStorage.getItem('token');
    if (token) {
      const data = jwtDecode();
      return <MainComponent {...this.props} />;

      // return data.verification_status === 'verified' ? (
      //   <MainComponent {...this.props} />
      // ) : (
      //   <Redirect to="/pending-approval" />
      // );
    } else {
      return <Redirect to="/signin" />;
    }
  }
}

export default Authentication;
