/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getUsers,
  unApproveUser
} from '../../redux/employees/employees.actions';
import { jwtDecode } from '../../jwt-decode';
import Spinner from '../spinner/spinner.component';
import ListContainer from './containers/ListContainer';
import SearchBar from './components/SearchBar';
import './employees.styles.scss';
import 'font-awesome/css/font-awesome.min.css';
import { Tabs } from 'antd';
import {
  SafetyCertificateOutlined,
  SecurityScanOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import InviteUser from './components/InviteUser';

/**
 * Import Redux functions
 */
import searchFor from './searchingUtil';


const Employees = ({
                     employees: { users, isLoading, pagination },
                     getUsers,
                     unApproveUser
                   }) => {

  const [user, setUser] = useState(jwtDecode());
  const [filteredList, setFilteredList] = useState([]);
  const [userFilterType, setUserFilterType] = useState({
    value: 'ALL',
    label: 'ALL'
  });
  const [currentPagination, setCurrentPagination] = useState([]);
  const [tabIndex, setTabIndex] = useState('0');
  const [tabLabels, setTabLabels] = useState([
    { label: 'Approved', Icon: SafetyCertificateOutlined },
    { label: 'Pending Verification', Icon: SecurityScanOutlined }
  ]);

  // For account type checking
  const userAuth = jwtDecode();
  const { current_profile_type } = userAuth;

  const addLabel = [{ label: 'Unverified', Icon: MinusCircleOutlined }];

  useEffect(() => {
    if (current_profile_type === 'Admin') {
      setTabLabels(tabLabels.concat([...addLabel]));
    }
  }, [current_profile_type]);

  useEffect(() => {
    getUsersRequest();
  }, []);

  // Setting initial load of users
  useEffect(() => {
    if (users && users !== null) {
      setFilteredList(users);
      setCurrentPagination(pagination);
    }
    // unApproveUser('5f593606e14fe80023a440da');
  }, [users]);

  // For user type selection
  useEffect(() => {
    getUsersRequest();
  }, [userFilterType]);

  const getUsersRequest = (currentTab, page = 1, per_page = 10) => {
    const params = { page, per_page };
    const index = currentTab || tabIndex;
    params.population = JSON.stringify(['company']);
    if (index === '0') params.verification_status = 'verified';
    if (index === '1') params.verification_status = 'pending';
    if (index === '2') params.verification_status = 'unverified';

    if (userFilterType.value === 'Volunteer')
      params.current_profile_type = 'Volunteer';
    if (userFilterType.value === 'IndustryPartner')
      params.current_profile_type = 'IndustryPartner';

    if (current_profile_type === 'Admin') {

    } else {
      params.company = userAuth.company;
      // params.current_profile_type = 'Volunteer';
    }

    getUsers(params);
  };

  const handleTabsOnChange = (tabIndex) => {
    setTabIndex(tabIndex);
    getUsersRequest(tabIndex);
  };

  const handleUserSearch = (e) => {
    const { value } = e.target;
    const filteredUsers = searchFor(value, users);

    setFilteredList(filteredUsers);
  };

  return isLoading ? (
    <Spinner/>
  ) : (
    <div className="row">
      <div className="col-sm-3">
        <h3 className="page-heading mr-b30">
          {current_profile_type === 'Admin' ? 'Users' : 'Employees'}
        </h3>
      </div>
      {/* Invite Users */}
      <div className="col-sm-2 ml-auto">
        <InviteUser userType={current_profile_type}/>
      </div>
      {/* Search */}
      {current_profile_type === 'Admin' && (
        <SearchBar
          handleUserSearch={handleUserSearch}
          userType={current_profile_type}
          userFilterType={userFilterType}
          setUserFilterType={setUserFilterType}
        />
      )}
      <div className="col-sm-12">
        <div className="white-box employees-wrap">
          <div className="table-wrap"></div>
          {/* EMPLOYEES */}
          <Tabs
            tabBarStyle={{
              color: '#5B6FE0',
              borderBottom: '1px solid #E7E8F2',
              marginTop: 10
            }}
            onChange={handleTabsOnChange}
          >
            {tabLabels.map(({ label, Icon }, i) => (
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon/> {label}
                  </span>
                }
                key={i}
                // activeKey={tabIndex}
              >
                <ListContainer
                  filteredList={filteredList}
                  getUsersRequest={getUsersRequest}
                  pagination={currentPagination}
                  currentTab={tabIndex}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  employees: state.employees
});
export default connect(mapStateToProps, { getUsers, unApproveUser })(Employees);
