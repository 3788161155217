/**
 * Import NPM Packages
 */
import React from 'react';
import { Spin } from 'antd';

/**
 * Import styles
 */
import './spinner.styles.scss';

/**
 * SignIn Component
 */
const Spinner = () => {
  return (
    <div className="spinner">
      <Spin style={{ top: '50%', position: 'absolute' }} />
    </div>
  );
};

/**
 * Export Spinner Component
 */
export default Spinner;
