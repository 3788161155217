import React from 'react';

const EmptyEvent = ({ title, subText }) => (
  <div>
    <div className="ety-event-wrap text-center mr-t30">
      <img
        src="/assets/images/event-vector.svg"
        className="img-fluid"
        alt=""
        title=""
      />
      <h4 className="mr-t40">{title}</h4>
      <p className="mr-t20 mr-b30">{subText}</p>
    </div>
  </div>
);

export default EmptyEvent;
