import React from 'react';
import emptyState from '../../../img/empty-users.svg';

const EmptyState = ({ text }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <img
        src={emptyState}
        alt="..."
        style={{ width: '80%', height: '50%', margin: '40px 0 20px' }}
      />
      <h4 style={{ color: '#A7A7A7', fontWeight: 'normal' }}>{text}</h4>
    </div>
  );
};

export default EmptyState;
