import React from 'react';
import InviteCard from '../components/InvitedCard';
import EmptyState from '../components/EmptyState';

const InvitedContainer = ({ users }) => {
  return (
    <div>
      <div className="row invite-wrapper">
        {users && users.length > 0 ? (
          users.map((user, i) => <InviteCard user={user} key={i} />)
        ) : (
          <EmptyState text="No attendees yet" />
        )}
      </div>
    </div>
  );
};

export default InvitedContainer;
