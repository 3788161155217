import { type } from './setting.types';
import api from '../../axios';

// Get settings
export const getSettings = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/settings/${userId}/users`);
    dispatch({
      type: type.GET_SETTINGS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: type.SETTINGS_ERROR,
      payload: err
    });
  }
};


// create settings
export const createSettings = (settingsObj) => async (dispatch) => {
  try {
    const res = await api.post(`/settings`, settingsObj);
    dispatch({
      type: type.UPDATE_SETTINGS,
      payload: res.data.data
    });
    return res;
  } catch (err) {
    dispatch({
      type: type.SETTINGS_ERROR,
      payload: err
    });
  }
};

// Update settings
export const updateSettings = (userId, settingsObj) => async (dispatch) => {
  try {
    const res = await api.put(`/settings/${userId}/users`, settingsObj);
    dispatch({
      type: type.UPDATE_SETTINGS,
      payload: res.data.data
    });
    return res;
  } catch (err) {
    dispatch({
      type: type.SETTINGS_ERROR,
      payload: err
    });
  }
};
