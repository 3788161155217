import React, { Fragment } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const DateSelector = ({ value, onChange }) => {
  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().endOf('day');
  };

  return (
    <Fragment>
      <DatePicker
        bordered={false}
        defaultValue={moment().add(1, 'days')}
        format="MM/DD/YYYY"
        disabledDate={disabledDate}
        // value={value}
        onChange={(date) => onChange(date)}
        suffixIcon={
          <img src="/assets/images/calendar_icon.svg" alt="" title="" />
        }
      />
    </Fragment>
  );
};

export default DateSelector;
