import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getSettings,
  createSettings,
  updateSettings
} from '../../../redux/settings/settings.actions';
import { notification, Checkbox } from 'antd';
import { withRouter, Route } from 'react-router-dom';
import FormInput from '../../form-input/form-input.component';
import CustomButton from '../../custom-button/custom-button.component';
import Spinner from '../../spinner/spinner.component';
import { jwtDecode } from '../../../jwt-decode';
import '../settings.styles.scss';
import api from '../../../axios';
import { updateAccount, updateUser } from '../../../redux/user/user.action';
import Dashboard from '../../dashboard/dashboard.component';

const initState = {
  approve_events_on_create: false,
  require_background_check: false,
  background_check_link: '',
  password: ''
};

const ChangePasswordComponent = ({
  settings: { isLoading, userSettings },
  getSettings,
  createSettings,
  updateSettings,
  updateUser,
  updateAccount
}) => {
  const [state, setState] = useState(initState);
  const [pass2, setPass2] = useState('');
  const [settings, setSettings] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // For account type checking
  const userAuth = jwtDecode();
  const { _id, current_profile_type } = userAuth;

  useEffect(() => {
    api
      .get(`/users/${_id}?population=["account", "company"]`, {
        headers: { auth: localStorage.getItem('token') }
      })
      .then(
        (response) => {
          setLoading(false);
          if (response.data) {
            setSettings(response.data?.data?.account);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
  }, [_id]);

  useEffect(() => {
    if (!loading && settings) {
      const settingsData = { ...settings };
      for (const key in userSettings) {
        if (key in settingsData) settingsData[key] = userSettings[key];
      }
      console.log(settingsData);
      setState(settingsData);
    }
  }, [loading, settings, userSettings]);

  const onChange = (e) => {
    const { value, name } = e.target;
    if (
      name === 'approve_events_on_create' ||
      name === 'require_background_check' ||
      name === 'background_check_url'
    ) {
      return setState({
        ...state,
        [name]: e.target.checked
      });
    }
    setState({ ...state, [name]: value });
  };

  const notify = () => {
    notification['success']({
      message: 'Success',
      description: 'Settings have been update'
    });
  };
  const notifyError = (message) => {
    notification['error']({
      message: 'Error',
      description: message
    });
  };

  const handlePasswordSubmit = () => {
    const settingsObj = { ...state, user: _id };
    // If user has no settings create them
    if (!settingsObj?.password || settingsObj?.password.trim() == '') {
      return;
    }
    if (settingsObj.password != pass2) {
      notifyError("Password don't match");
      return;
    }
    updateUser(_id, { password: settingsObj.password }).then((res) => {
      setSubmitting(false);
      if (res.status === 200) notify();
    });
  };

  const { password } = state;

  return loading ? (
    <Spinner />
  ) : (
    <div className="col-sm-12">
      <div className="white-box event-form-wrap">
        <div className="event-form-block">
          <div className="form-group w-100 mb-0">
            <div className="password-wrapper">
              <Route
                path="/change-password"
                component={<div>Change Password</div>}
              />
              <FormInput
                label="Change Password"
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                className="form-control"
                handleChange={onChange}
              />
              <FormInput
                type="password"
                name="pass2"
                value={pass2}
                placeholder="Confirm Password"
                className="form-control"
                handleChange={(e) => setPass2(e.target.value)}
              />
            </div>
            <div>
              <CustomButton
                disabled={submitting}
                className="btn submit-btn w-50"
                onClick={handlePasswordSubmit}
              >
                Update Password
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings
});

export default withRouter(
  connect(mapStateToProps, {
    updateAccount,
    updateUser,
    getSettings,
    createSettings,
    updateSettings
  })(ChangePasswordComponent)
);
