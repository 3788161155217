import React, { useEffect, useState } from 'react';
import moment from 'moment';

const EventHours = ({ eventDetail }) => {
  const [eventHours, setEventHours] = useState(null);

  useEffect(() => {
    if (eventDetail !== '') {
      const start = moment(eventDetail.start_date);
      const end = moment(eventDetail.end_date);
      const duration = moment.duration(end.diff(start));
      const durationString = `${duration._data.hours} : ${duration._data.minutes}`;
      const totalHours =
        parseFloat(durationString) * eventDetail.attendees.length;
      setEventHours(totalHours);
    }
  }, [eventDetail]);

  return (
    <div>
      <div className="e-date">
        <p className="color-gary font12 muli-semiBold text-uppercase">
          Event Hours
        </p>
        <ul className="pl-4 mr0 pd0">
          <div className="s-pro-outer">
            <div className="s-pro-inn" style={{ background: '#77c285' }}>
              <h4>{eventHours !== null && eventHours}</h4>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default EventHours;
