/**
 * Import NPM Packages
 */
import React from 'react';
import moment from 'moment';
import { FieldTimeOutlined } from '@ant-design/icons';

/**
 * Import styles
 */
import './event-time.styles.scss';

/**
 * EventTime Component
 */
const EventTime = ({ dateTime: { start_date, end_date } }) => {
  return (
    <div className="e-date">
      <p className="color-gary font12 muli-semiBold text-uppercase">
        Event Date
      </p>
      <span>{moment(start_date).format('ddd, DD MMM yyyy')}</span>
      <p className="duration">
        <FieldTimeOutlined />
        {moment(start_date).format('hh:mm a')} -{' '}
        {moment(end_date).format('hh:mm a')}
      </p>
    </div>
  );
};

/**
 * Export EventTime Component
 */
export default EventTime;
