import { type } from './survey.types';
import { loginError } from './survey.selector';
const INITIAL_STATE = {
  loading: null,
  error: null,
  data: null
};

const surveyReducer = ( state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_SURVEY:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case type.SUBMIT_SURVEY:
      return {
        ...state,
        loading: false,
        error: null,
        submitted: action.payload
      };
    case type.SURVEY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default surveyReducer;
