import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Dropdown, Menu, notification, Tabs } from 'antd';
import { PicLeftOutlined, TeamOutlined } from '@ant-design/icons';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import $ from 'jquery';
import { getEventById } from '../../../redux/event/event.action';
import { getEventDetail } from '../../../redux/event/event.selector';
import { userDetails } from '../../../redux/user/user.selector';
import { jwtDecode } from '../../../jwt-decode';
import Spinner from '../../spinner/spinner.component';
import './event-detail.styles.scss';
import EventDetailHeader from './components/EventDetailHeader';
import EventDetailCard from './components/EventDetailCard';
import AttendingContainer from './containers/AttendingContainer';
import AcceptInvite from './components/AcceptInvite';
import { inviteUser } from '../../../redux/employees/employees.actions';
import { selectFormStyles as selectStyle } from '../../tagsInput/selectFormStyles';
import Pagination from './pagination';
import avatar from './avatar.png';
import api from '../../../axios';
import { Link } from 'react-router-dom';
import { parseBlobUrl } from '../../../utils/helper';

// AIzaSyAfMDhzGh3tzMCWJzTJWjqauCxWuoq5hrY

const components = {
  DropdownIndicator: null
};

const createOption = (label) => ({
  label,
  value: label
});

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const submitCb = (message) => {
  notification['success']({
    message: 'Success!',
    description: message,
    style: {
      width: 600,
      marginLeft: 335 - 600
    }
  });
};

class EventDetail extends React.Component {
  state = {
    eventDetail: '',

    invitees: [],
    eventId: null,
    isLoading: false,
    inputValue: '',
    inviteeEmails: [],
    activeTabKey: 1,
    users: [],
    selectedUser: [],
    currentPage: 0, // current page 0 using for array indexing purpose
    itemPerPage: 5
  };


  componentDidMount() {
    this.getEvent();
    api
      .get('/users')
      .then((resp) => {
        const users = resp.data.data.map((user) => ({
          label: `${user.first_name ?? ''} ${user.last_name ?? ''}`,
          value: user.id
        }));
        this.setState({ users });
      })
      .catch((err) => {
        console.log(err);
      });
    const { location } = this.props;
    const eventId = location.state?.eventId;
  }

  getEvent = () => {
    const { location } = this.props;
    if (location.state?.eventId) {
      this.setState({ isLoading: true });
      this.props
        .getEventById({
          eventId: location.state?.eventId
        })
        .then((response) => {
          this.setState({
            isLoading: false,
            eventDetail: response
          });
        });
    } else {
      this.props.history.push('/events');
    }
  };

  componentWillUnmount() {
    this.setState({
      eventDetail: ''
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = prevState;
    state.isLoading = false;
    return prevState === state ? null : state;
  }

  copyClipboard() {
    $('.tooltiptext').show();
    setTimeout(() => {
      $('.tooltiptext').hide();
    }, 2000);
  }

  handleTabChange = (key) => {
    this.setState({ activeTabKey: key });
  };

  handleChange = (value, type) => {
    if (!value) value = [];
    if (type === 'email') {
      this.setState({ inviteeEmails: [...value] });
    } else if (type === 'user') {
      this.setState({ selectedUser: [...value] });
    }
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    const { inputValue, inviteeEmails } = this.state;

    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (!validateEmail(inputValue))
          return alert('invalid email address, please provide valid one');

        this.setState({
          inputValue: '',
          inviteeEmails: [...inviteeEmails, createOption(inputValue)]
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  handleInviteSubmit = (e) => {
    e.preventDefault();

    const { inviteeEmails } = this.state;

    const emails = inviteeEmails.map((email) => email.value);
    let message =
      inviteeEmails.length > 1
        ? 'Invites have been successfully sent'
        : 'Invite has been successfully sent';
    const data = {
      email: emails,
      invitation_to: 'IndustryPartner',
      redirect_url: `${window?.location?.href}/signup`
    };

    this.setState({ inviteeEmails: [] });
    inviteUser(data, submitCb(message));
  };

  sendInviteById = (e) => {
    e.preventDefault();
    const userIds = this.state.selectedUser.map((user) => user.value);
    let message =
      userIds.length > 1
        ? 'Invites have been successfully sent'
        : 'Invite has been successfully sent';

    const data = {
      invitees: userIds
    };
    api
      .put(`/events/${this.state.eventDetail._id}/invite`, data)
      .then((resp) => {
        submitCb(message);
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ selectedUser: [] });
  };

  getPaginatedItems = (items = []) => {
    const { currentPage, itemPerPage } = this.state;
    return items.slice(currentPage, currentPage + itemPerPage);
  };

  handlePaginationChange = (e, currentPage) => {
    e.preventDefault();
    console.log(currentPage);
    this.setState({ currentPage });
  };

  render() {
    const userDetail = jwtDecode();
    const { attachments, attendingData } = this.state;
    const {
      eventDetail,
      isLoading,
      inviteeEmails,
      inputValue,
      users,
      selectedUser,
      currentPage,
      itemPerPage,
      user
    } = this.state;
    console.log('attendees', eventDetail?.attendees);

    return isLoading ? (
      <Spinner/>
    ) : (
      <div className="row">
        <div className="col-sm-6">
          <h3 className="page-heading mr-b30">Event Details</h3>
        </div>
        {/* ACCEPT / DECLINE */}
        {userDetail.current_profile_type !== 'Admin' && eventDetail?.attendees && user && eventDetail?.attendees.find(a => a._id == user?._id)(
          <AcceptInvite
            userDetail={userDetail}
            eventDetail={eventDetail}
            getEvent={this.getEvent}
          />
        )}
        <div className="col-sm-8">
          <div className="white-box event-details-wrap">
            {/* EVENT HEADER */}
            <EventDetailHeader
              eventDetail={eventDetail}
              userDetail={userDetail}
              copyClipboard={this.copyClipboard}
            />
            {/* TABS START */}
            <Tabs
              tabBarStyle={{
                color: '#5B6FE0',
                borderBottom: '1px solid #E7E8F2',
                marginTop: 10
              }}
              onChange={this.handleTabChange}
            >
              <Tabs.TabPane
                tab={
                  <span>
                    <PicLeftOutlined/>
                    Event Details
                  </span>
                }
                key="1"
              >
                <EventDetailCard eventDetail={eventDetail}/>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <TeamOutlined/>
                    Attending
                  </span>
                }
                key="3"
              >
                <AttendingContainer users={(eventDetail && eventDetail.attendees) ?  eventDetail.attendees: []}/>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
        {/* Side column */}
        <div className="col-sm-4">
          <div className="invition-form white-box mb-4 p-4">
            <Tabs defaultActiveKey="1" centered style={{ overflow: 'visible' }}>
              <Tabs.TabPane key="1" tab="Invite IP/Volunteer">
                <div className="form-group">
                  <Select
                    isMulti
                    name="users"
                    value={selectedUser}
                    options={users}
                    placeholder="Select or type name of IP/Volunteer"
                    styles={selectStyle}
                    onChange={(val) => this.handleChange(val, 'user')}
                  />
                </div>
                <div className="form-group text-center">
                  <Button
                    className="btn-purple"
                    type="button"
                    onClick={this.sendInviteById}
                    disabled={selectedUser.length === 0}
                  >
                    Send Invite
                  </Button>
                </div>
              </Tabs.TabPane>
              {/*<Tabs.TabPane key="2" tab="Invite by Email">*/}
              {/*  <div className="form-group">*/}
              {/*    <CreatableSelect*/}
              {/*      styles={selectStyle}*/}
              {/*      components={components}*/}
              {/*      inputValue={inputValue}*/}
              {/*      // isClearable*/}
              {/*      isMulti*/}
              {/*      menuIsOpen={false}*/}
              {/*      onChange={(val) => this.handleChange(val, 'email')}*/}
              {/*      onInputChange={this.handleInputChange}*/}
              {/*      onKeyDown={this.handleKeyDown}*/}
              {/*      placeholder="Type email then hit ENTER or press TAB to add email"*/}
              {/*      value={inviteeEmails}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="form-group text-right">*/}
              {/*    <Button*/}
              {/*      className="btn btn-violate"*/}
              {/*      onClick={this.handleInviteSubmit}*/}
              {/*      disabled={inviteeEmails.length === 0}*/}
              {/*    >*/}
              {/*      Send Invite*/}
              {/*    </Button>*/}
              {/*  </div>*/}
              {/*</Tabs.TabPane>*/}
            </Tabs>
          </div>

          <div className="invite-list-wrap mb-4">
            <div className="white-box accept-in-list">
              <h5>Invited User List</h5>
              {eventDetail && eventDetail.invitees.length > 0 ? (
                this.getPaginatedItems(eventDetail.invitees).map((invitee) => (
                  <div className="invited-user d-flex" key={invitee.id}>
                    <img
                      src={invitee.avatar ? parseBlobUrl(invitee.avatar.url) : avatar}
                      alt="avatar"
                    />
                    <div className="user-info">
                      <h4>{invitee.first_name}</h4>
                      <p>{invitee.email}</p>
                    </div>
                  </div>
                ))
              ) : (
                <strong className="text-center text-muted">
                  no invited user found
                </strong>
              )}
              {/** Pagination **/}
              {eventDetail && eventDetail.invitees && (
                <Pagination
                  data={eventDetail.invitees}
                  currentPage={currentPage}
                  itemPerPage={itemPerPage}
                  handleChange={this.handlePaginationChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  eventInfoById: getEventDetail,
  user: userDetails
});

const mapDispatchToProps = (dispatch) => ({
  getEventById: (eventInfo) => dispatch(getEventById(eventInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDetail);
