/**
 * Import NPM Packages
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import qs from 'query-string';
/**
 * Import Redux functions
 */
import { signIn } from '../../redux/user/user.action';
import { loginDetails, loginError, userDetails } from '../../redux/user/user.selector';

/**
 * Import styles
 */
import './sign-in.styles.scss';

/**
 * Import Components
 */
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import UpcomingEvent from '../upcoming-event/upcoming-event.component';
import Logo from '../logo/logo.component';
import Spinner from '../spinner/spinner.component';
import { Spin } from 'antd';
/**
 * SignIn Component
 */
const SignIn = ({ signIn, loginInfo, loginError, history, location }) => {
  /**
   * React Hooks
   */
  const [userDetails, setUserDetails] = useState({
    emailAddress: '',
    password: '',
    isSocial: '0'
  });
  const [validateEmail, setValidateEmail] = useState(true);
  const [showFormError, setShowFormError] = useState(false);
  // const [loginError, setLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const redirectUrl = qs.parse(location.search, { ignoreQueryPrefix: true }).redirectUrl;

  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/dashboard');

    }
  }, []);

  useEffect(() => {
    if (loginError) setIsLoading(false);
    if (loginInfo?._id) {
      setIsLoading(false);
      setUserDetails({
        emailAddress: loginInfo.email,
        password: '',
        isSocial: '0'
      });
      if (localStorage.getItem('token')) {
        if (location.state?.eventId) {
          history.push({
            pathname: '/event-detail',
            state: { eventId: location.state?.eventId }
          });
        } else {
          history.push('/dashboard');
        }
      }
    }
  }, [loginInfo, loginError]);

  /**
   * Handler Functions
   */
  const signUpRedirect = () => {
    const obj = qs.parse(location.search);

    let string = 'signup';

    if (obj.eventId) {
      string = string.concat(`?eventId=${obj.eventId}`);
    } else if (obj.type) {
      if (obj.eventId) {
        string = string.concat(`&type=${obj.type}`);
      } else {
        string = string.concat(`?type=${obj.type}`);
      }
    }
    if (obj.token) {
      string = string.concat(`&token=${obj.token}`);
    }

    return string;
  };

  // const signUpRedirect = location.state?.eventId
  //   ? 'signup?eventId=' + location.state.eventId
  //   : 'signup';

  const validateEmailAddress = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Input changes state get updated
   * @param {*} event
   */
  const formHandleChange = (event) => {
    const { name, value } = event.target;
    setShowFormError(false);
    setValidateEmail(true);
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  /**
   * When form submits if all validation are perfect then login API is called
   */
  const formSubmitHandler = () => {

    if (userDetails.emailAddress && userDetails.password) {
      if (validateEmailAddress(userDetails.emailAddress)) {
        setIsLoading(true);
        signIn({
          email: userDetails.emailAddress,
          password: userDetails.password,
          isSocial: userDetails.isSocial
        }, redirectUrl);
      } else {
        setValidateEmail(false);
        setShowFormError(true);
      }
    } else {
      if (!validateEmailAddress(userDetails.emailAddress)) {
        setValidateEmail(false);
      }
      setShowFormError(true);
    }
  };

  return false == true ? (
    <Spinner />
  ) : (
    <section className="form-body">
      <div className="form-box">
        <div className="container">
          <div className="f-inn-content">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <Logo />
              </div>
              <div className="col-sm-6">
                {location.state?.eventId ? (
                  <UpcomingEvent eventId={location.state.eventId} />
                ) : null}
              </div>
              <div className="offset-sm-1 col-sm-5">
                <h4>Log In</h4>
                <div className="sign-form">
                  {/*<CustomButton className="g-btn">*/}
                  {/*  <img src="assets/images/g-icon.png" alt="" title="" />*/}
                  {/*  Sign in with Google*/}
                  {/*</CustomButton>*/}
                  <div className="divider-title mr-t30 mr-b30">
                    <h5 className="text-center">
                      <span>Sign in with your email</span>
                    </h5>
                  </div>

                  <div className="form-inputs">
                    <FormInput
                      label="Email address"
                      type="email"
                      name="emailAddress"
                     // value={userDetails.emailAddress}
                      placeholder="Enter email address"
                      handleChange={formHandleChange}
                      showError={
                        showFormError &&
                        (userDetails.emailAddress === '' || !validateEmail)
                          ? true
                          : false
                      }
                      errorText={
                        userDetails.emailAddress === ''
                          ? 'Please insert Email address'
                          : !validateEmail
                          ? 'Please insert validate email address'
                          : null
                      }
                    />
                    <FormInput
                      label="Password"
                      type="password"
                      name="password"
                      // value={userDetails.password}
                      placeholder="Enter password"
                      handleChange={formHandleChange}
                      showError={
                        showFormError && userDetails.password === ''
                          ? true
                          : false
                      }
                      errorText="Please insert password"
                    />
                    {loginError ? (
                      <div
                        className="signup-error"
                        style={{ textAlign: 'center' }}
                      >
                        {loginError}
                      </div>
                    ) : null}
                    <CustomButton
                      disabled = {isLoading}
                      className="s-btn blue-btn w-80 mr-t30 mr-b20"
                      onClick={formSubmitHandler}
                    >
                      {isLoading ? (
                        <Spin />
                      ) : (
                        'Login'
                      )}
                    </CustomButton>
                    <div className="f-foot-link">
                      <span className="font14 muli-bold blue-gary mr-r30">
                        Don't have an account?
                      </span>
                      Contact <a href="mailto:admin@stemuli.net">admin@stemuli.net</a>
                      {/*<Link*/}
                      {/*  to={() => signUpRedirect()}*/}
                      {/*  className="font14 muli-bold blue-blue"*/}
                      {/*>*/}
                      {/*  Sign up now*/}
                      {/*</Link>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({
  loginInfo: userDetails,
  loginError: loginError
});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({
  signIn: (userInfo, redirectUrl) => dispatch(signIn(userInfo, redirectUrl))
});

/**
 * Export SignIn Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
