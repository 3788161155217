import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { inviteUser } from '../../../redux/employees/employees.actions';
import Select from 'react-select';
import { selectFormStyles } from '../selectFormStyles';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import TagsInput from '../../tagsInput/tagsInput';

const options = [
  { value: 'IndustryPartner', label: 'INDUSTRY PARTNER' },
  { value: 'Volunteer', label: 'VOLUNTEER' },
  { value: 'Admin', label: 'ADMIN' },
];
const ipOptions = [
  { value: 'Volunteer', label: 'VOLUNTEER' }
];

const InviteUser = ({ userType, inviteUser }) => {
  const [userEmails, setUserEmails] = useState([]);
  const [userAccountType, setUserAccountType] = useState(options[1]);
  const closeRef = useRef(null);
  const submitCb = (message) => {
    notification['success']({
      message: 'Success!',
      description: message,
      style: {
        width: 600,
        marginLeft: 335 - 600
      }
    });
    closeRef.current.click();
  };

  function userEmailsHandler(d) {
    setUserEmails(d || []);
  }

  const handleInviteSubmit = () => {
    if (userEmails?.length === 0 || !userAccountType) return;
    const emails = userEmails.map((email) => email.value);
    let message =
      userEmails.length > 1
        ? 'Invites have been successfully sent'
        : 'Invite has been successfully sent';
    setUserEmails([]);
    setUserAccountType('');
    const data = {
      email: emails,
      invitation_to: userAccountType.value,
      redirect_url: `${
        window?.location?.protocol + '//' + window?.location?.host
      }/signup`
    };
    inviteUser(data, submitCb(message));
  };

  return (
    <div>
      <button
        type="button"
        data-toggle="modal"
        data-target="#exampleModalCenter"
        className="invite-btn"
      >
        Invite Users
      </button>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {userType === 'Admin' ? 'Invite Users' : 'Invite Volunteers'}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {userType === 'Admin' && (
                <div className="pb-2">
                  <Select
                    name="options"
                    value={userAccountType}
                    options={options}
                    onChange={(e) => setUserAccountType(e)}
                    styles={selectFormStyles}
                    menuPlacement="bottom"
                    style={{ width: '100%', backgroundColor: '#fff' }}
                    placeholder="Select User Type"
                  />
                </div>
              )}
              {userType === 'IndustryPartner' && (
                <div className="pb-2">
                  <Select
                    name="options"
                    value={userAccountType}
                    options={ipOptions}
                    onChange={(e) => setUserAccountType(e)}
                    styles={selectFormStyles}
                    menuPlacement="bottom"
                    style={{ width: '100%', backgroundColor: '#fff' }}
                    placeholder="Select User Type"
                  />
                </div>
              )}
              <TagsInput
                placeholder="Type email then hit ENTER or Tab to add email"
                value={userEmails}
                cB={userEmailsHandler}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn"
                onClick={handleInviteSubmit}
                style={{ backgroundColor: '#3F51B5', color: '#fff' }}
              >
                Send Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
InviteUser.propTypes = {
  userType: PropTypes.string.isRequired,
  inviteUser: PropTypes.func.isRequired
};
export default connect(null, { inviteUser })(InviteUser);
