/**
 * Import NPM Packages
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

/**
 * Import Components
 */

import SignUp from './components/sign-up/sign-up.component';
import signIn from './components/sign-in/sign-in.component';
import Authentication from './layout/authentication.component';
import Main from './layout/main.component';
import Survey from './containers/survey/survey.component';
import PublicEventDetail from './components/events/public-event-detail/public-event-detail.component';

import 'antd/dist/antd.css';
const App = () => (
  <Switch>
    <Route exact path="/event/:eventId" component={PublicEventDetail} />
    <Route path="/survey/:id" component={Survey} />
    <Route path="/signup" component={SignUp} />
    <Route path="/signin" component={signIn} />
    <Authentication path="/" component={Main} />
  </Switch>
);

export default App;
