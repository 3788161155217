import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getSettings,
  createSettings,
  updateSettings
} from '../../../redux/settings/settings.actions';
import { notification, Checkbox } from 'antd';
import { withRouter, Route } from 'react-router-dom';
import FormInput from '../../form-input/form-input.component';
import CustomButton from '../../custom-button/custom-button.component';
import Spinner from '../../spinner/spinner.component';
import { jwtDecode } from '../../../jwt-decode';
import '../settings.styles.scss';
import api from '../../../axios';
import {updateAccount} from '../../../redux/user/user.action';
import Dashboard from '../../dashboard/dashboard.component';

const initState = {
  approve_events_on_create: false,
  require_background_check: false,
  background_check_link: '',
  password: ''
};

const SafetyComponent = ({
  settings: { isLoading, userSettings },
  getSettings,
  createSettings,
  updateSettings,
   updateAccount
}) => {
  const [state, setState] = useState(initState);
  const [settings, setSettings] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // For account type checking
  const userAuth = jwtDecode();
  const { _id, current_profile_type } = userAuth;

  useEffect(() => {
    api.get(`/users/current?population=["account", "company"]`, {
      headers: { auth: localStorage.getItem('token') }
    }).then(response => {
      console.log('resonp', response);
      setLoading(false);
      if (response.data) {
        setSettings(response.data?.data?.account);
      }
    }, (error)=> {
     setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!loading && settings) {
      const settingsData = { ...settings };
      for (const key in userSettings) {
        if (key in settingsData) settingsData[key] = userSettings[key];
      }
      setState(settingsData);
    }
  }, [settings]);

  const onChange = (e) => {
    const { value, name } = e.target;
    if (
      name === 'approve_events_on_create' ||
      name === 'require_background_check' ||
      name === 'background_check_url'
    ) {
      return setState({
        ...state,
        [name]: e.target.checked
      });
    }
    setState({ ...state, [name]: value });
  };

  const notify = () => {
    notification['success']({
      message: 'Success',
      description: 'Settings have been update'
    });
  };
  const notifyError = (message) => {
    notification['error']({
      message: 'Error',
      description: message
    });
  };


  const handleAccountSubmit = () => {
    const settingsObj = { ...state, user: _id };
    // If user has no settings create them
    setSubmitting(true);
    updateAccount(settings._id, state).then((res) => {
      setSubmitting(false);
      if (res.status === 200) notify();
    });
  };

  const {
    approve_events_on_create,
    require_background_check,
    background_check_link,
    password
  } = state;

  return loading ? (
    <Spinner />
  ) : (
      <div className="col-sm-12">
        <div className="white-box event-form-wrap">
          <div className="event-form-block">
            {current_profile_type === 'Admin' && (
              <Fragment>
                {/* Background check Link*/}
                <div className="mt-5 form-group">
                  <FormInput
                    label="Link for background check"
                    type= "text"
                    name="background_check_link"
                    value={background_check_link}
                    placeholder="https://background-check.com"
                    className="form-control"
                    handleChange={onChange}
                  />
                </div>
                {/* Approve IP events automatically */}
                <div className="form-group">
                  <Checkbox
                    checked={approve_events_on_create}
                    name="approve_events_on_create"
                    onChange={onChange}
                  >
                    Approve events created by Industry Partner automatically
                  </Checkbox>
                </div>
                {/* Require background check */}
                <div className="form-group">
                  <Checkbox
                    checked={require_background_check}
                    name="require_background_check"
                    onChange={onChange}
                  >
                    Require background check for all Employees / Volunteers
                  </Checkbox>
                </div>
              </Fragment>
            )}
          </div>
          <CustomButton disabled={submitting} className="btn submit-btn w-100" onClick={handleAccountSubmit}>
            Update Settings
          </CustomButton>
        </div>
      </div>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings
});

export default withRouter(
  connect(mapStateToProps, {updateAccount, getSettings, createSettings, updateSettings })(
    SafetyComponent
  )
);
