import { createSelector } from 'reselect';

const selectEvent = (state) => state.event;

export const createEventDetails = createSelector(
  [selectEvent],
  (event) => event.createEvent
);

export const editEventDetails = createSelector(
  [selectEvent],
  (event) => event.editEvent
);

export const deleteEventDetail = createSelector(
  [selectEvent],
  (event) => event.deleteEventDetail
);

export const getEventDetails = createSelector(
  [selectEvent],
  (event) => event.getEventDetails
);

export const getEventDetail = createSelector(
  [selectEvent],
  (event) => event.getEventDetail
);

export const getActivityType = createSelector(
  [selectEvent],
  (event) => event.getActivityType
);

export const upcomingEventDetail = createSelector(
  [selectEvent],
  (event) => event.upcomingEventDetail
);
