import { type } from './user.types';
import { loginError } from './user.selector';
const INITIAL_STATE = {
  loading: null,
  error: null,
  fistrationDetails: null,
  // loginDetails: null,
  userDetails: null,
  allUserDetails: null,
  searchUserDetails: null,
  dashboardDetails: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.SIGNUP_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case type.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
        error: null
      };
    case type.SIGNUP_ERROR: {
      return {
        ...state,
        loading: false,
        userDetails: null,
        error: action.error.message
      };
    }
    case type.SIGNIN_START:
      return {
        ...state,
        loading: true,
        loginError: null
      };
    case type.SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
        loginError: null
      };
    case type.SIGNIN_ERROR:
      return {
        ...state,
        loading: false,
        userDetails: null,
        loginError: action.error.message
      };

    case type.DASHBOARD_START:
      return {
        ...state,
        loading: true,
        dashboardDetails: null,
        error: null
      };
    case type.DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardDetails: action.payload,
        error: null
      };
    case type.DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        dashboardDetails: null,
        error: action.error.message
      };

    case type.GET_USER_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case type.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
        error: null
      };
    case type.GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        userDetails: null,
        error: action.error
      };

    case type.GET_ALL_USER_START:
      return {
        ...state,
        loading: true,
        error: null,
        allUserDetails: null
      };
    case type.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        allUserDetails: action.payload,
        error: null
      };
    case type.GET_ALL_USER_ERROR:
      return {
        ...state,
        loading: false,
        allUserDetails: null,
        error: action.error
      };

    case type.SEARCH_USER_START:
      return {
        ...state,
        loading: true,
        searchUserDetails: null,
        error: null
      };
    case type.SEARCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        searchUserDetails: action.payload,
        error: null
      };
    case type.SEARCH_USER_ERROR:
      return {
        ...state,
        loading: false,
        searchUserDetails: null,
        error: action.error
      };

    case type.UPLOAD_AVATAR:
      return {
        ...state,
        loading: false,
        searchUserDetails: null,
        error: action.error
      };
    case type.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: {...state.userDetails, ...action.payload},
        error: null
      };
    case type.UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.error?.message
      };
    case type.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: {...state.userDetails, company: {...action.payload}},
        error: null
      };

    default:
      return state;
  }
};

export default userReducer;
