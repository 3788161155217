import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getSettings,
  createSettings,
  updateSettings
} from '../../redux/settings/settings.actions';
import { notification, Checkbox, Tabs } from 'antd';
import { withRouter, Route, Link } from 'react-router-dom';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import Spinner from '../spinner/spinner.component';
import { jwtDecode } from '../../jwt-decode';
import './settings.styles.scss';
import api from '../../axios';
import { updateAccount } from '../../redux/user/user.action';
import Dashboard from '../dashboard/dashboard.component';
import ChangePassword from './setting/change-password.component';
import Safety from './setting/safety.component';
import Company from './setting/company.component';
import Profile from './setting/profile.component';
import {
  SafetyCertificateOutlined,
  SecurityScanOutlined,
  MinusCircleOutlined,
  UserOutlined,
  KeyOutlined,
  BankOutlined
} from '@ant-design/icons';
const initState = {
  approve_events_on_create: false,
  require_background_check: false,
  background_check_link: '',
  password: ''
};

const SettingsPage = ({
  settings: { isLoading, userSettings },
  getSettings,
  createSettings,
  updateSettings,
  updateAccount,
  user
}) => {
  const [state, setState] = useState(initState);
  const [tabIndex, setTabIndex] = useState(1);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (!user) return;
    const tabItems = [
      {
        icon: <UserOutlined />,
        title: 'Profile',
        component: <Profile />
      },
      {
        icon: <KeyOutlined />,
        title: 'Change Password',
        component: <ChangePassword />
      }
    ];
    switch (user?.current_profile_type) {
      case 'Admin':
        tabItems.push({
          icon: <SecurityScanOutlined />,
          title: 'Safety',
          component: <Safety />
        });
        break;
      case 'IndustryPartner':
      case 'Volunteer':
        tabItems.push({
          icon: <BankOutlined />,
          title: 'Company',
          component: <Company />
        });
        break;
      default:
        break;
    }
    setTabs([...tabItems]);
    if (user?.current_profile_type == 'IndustryPartner') {
    }
  }, [user]);

  return (
    <div className="row">
      {/* Header */}
      <div className="col-sm-12">
        <h3 className="page-heading mr-b30">Settings</h3>
      </div>
      <div className="col-sm-12">
        <div className={'row'}>
          <div className="col-sm-12">
            <Tabs
              tabBarStyle={{
                color: '#5B6FE0',
                borderBottom: '1px solid #E7E8F2',
                marginTop: 10
              }}
              onChange={setTabIndex}
            >
              {tabs.map((tab, i) => (
                <Tabs.TabPane
                  tab={
                    <span>
                      {tab.icon} {tab.title}
                    </span>
                  }
                  key={'' + (i + 1)}
                >
                  {tab.component}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  user: state.user.userDetails
});

export default withRouter(
  connect(mapStateToProps, {
    updateAccount,
    getSettings,
    createSettings,
    updateSettings
  })(SettingsPage)
);
