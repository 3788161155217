/**
 * Import NPM Packages
 */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {signOut} from '../../redux/user/user.action';
import {
  LogoutOutlined
} from '@ant-design/icons';
/**
 * Import Redux functions
 */
// import { signOut } from '../../redux/user/user.action';

import './left-sidebar.styles.scss';

/**
 * Import Components
 */
import Logo from '../logo/logo.component';
import { jwtDecode } from '../../jwt-decode';
import { createStructuredSelector } from 'reselect';
import { dashboardDetails } from '../../redux/user/user.selector';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../redux/user/user.action';

/**
 * LeftSidebar Component
 */
const LeftSidebar = (props) => {
  const userDetail = jwtDecode();
  let router = [],
    sub_router = [];

  if (userDetail.current_profile_type === 'Volunteer') {
    router = [
      { imageUrl: '/assets/images/dashboard-icon.svg', path: '/dashboard' },
      { imageUrl: '/assets/images/chart-icon.svg', path: '/events' }
      // { imageUrl: '/assets/images/box-icon.svg', path: '/messages' },
      // { imageUrl: '/assets/images/cal-icon.svg', path: '/calendar' }
    ];

    sub_router = [
      { name: 'Dashboard', path: '/dashboard' },
      { name: 'Events', path: '/events' },
      { name: 'Settings', path: '/settings' }
      // { name: 'Messages', path: '/messages' },
      // { name: 'Calendar', path: '/calendar' }
    ];
  }
  if (userDetail.current_profile_type === 'Admin') {
    router = [
      { imageUrl: '/assets/images/dashboard-icon.svg', path: '/dashboard' },
      { imageUrl: '/assets/images/chart-icon.svg', path: '/events' },
      { imageUrl: '/assets/images/card-icon.svg', path: '/employees' }

      // { imageUrl: '/assets/images/box-icon.svg', path: '/messages' },
      // { imageUrl: '/assets/images/cal-icon.svg', path: '/calendar' }
    ];

    sub_router = [
      { name: 'Dashboard', path: '/dashboard' },
      { name: 'Events', path: '/events' },
      { name: 'Users', path: '/employees' },
      { name: 'Surveys', path: '/survey-result' },
      { name: 'Settings', path: '/settings' }
      // { name: 'Messages', path: '/messages' },
      // { name: 'Calendar', path: '/calendar' }
    ];
  }
  if (userDetail.current_profile_type === 'IndustryPartner') {
    router = [
      { imageUrl: '/assets/images/dashboard-icon.svg', path: '/dashboard' },
      { imageUrl: '/assets/images/chart-icon.svg', path: '/events' },
      { imageUrl: '/assets/images/card-icon.svg', path: '/employees' }
    ];

    sub_router = [
      { name: 'Dashboard', path: '/dashboard' },
      { name: 'Events', path: '/events' },
      { name: 'Users', path: '/employees' },
      { name: 'Settings', path: '/settings' }
    ];
  }

  const signOutHandler = () => {
    props.signOut();
    props.history.push('/signin');
  };

  return (
    <aside className="sidebar-wrap">
      <div className="nav-icon-bar">
        <ul>
          {router.map((route, index) => (
            <li key={index}>
              <Link
                to={route.path}
                className={
                  props.history.location.pathname === route.path
                    ? 'active-block'
                    : null
                }
              >
                <img src={route.imageUrl} alt="" title="" />
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="navbar-wrap">
        <Logo className="head-logo" />
        <nav className="navbar-contain">
          <div className="nav-block w-border">
            <h5>Menu</h5>
            <ul>
              {sub_router.map((route, index) => (
                <li key={index}>
                  <Link
                    to={route.path}
                    className={
                      props.history.location.pathname === route.path
                        ? 'active-link'
                        : null
                    }
                  >
                    {route.name}
                  </Link>
                </li>
              ))}
              <li className={"signout"} key={sub_router.length + 1}>
                <Link to="" onClick={signOutHandler}>
                   <LogoutOutlined/> Sign Out
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
};

const mapStateToProps = createStructuredSelector({
});
/**
 * Export Layout Component
 */
export default withRouter(
  connect(mapStateToProps, {signOut})(
    LeftSidebar
  )
);
