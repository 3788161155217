/**
 * Import NPM Packages
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import qs from 'query-string';
/**
 * Import Redux functions
 */
import { signIn } from '../../redux/user/user.action';
import { loginDetails, loginError } from '../../redux/user/user.selector';

/**
 * Import styles
 */
import './survey.styles.scss';

/**
 * Import Components
 */
import FormInput from '../../components/form-input/form-input.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import UpcomingEvent from '../../components/upcoming-event/upcoming-event.component';
import Logo from '../../components/logo/logo.component';
import Spinner from '../../components/spinner/spinner.component';
import { getSurvey, submitSurvey } from '../../redux/survey/survey.action';
import { currentSurvey, surveyError } from '../../redux/survey/survey.selector';
import { Button } from 'antd';
import { genders, races, schools } from '../../data/survey-biodata';

const bioPage = {
  name: "page1",
  elements: [
    {
      type: 'panel',
      elements: [
        {
          type: 'text',
          name: 'studentId',
          title: 'Student ID Number',
          isRequired: true,
          startWithNewLine: false,
          hideNumber: true
        },
        {
          type: 'text',
          name: 'grade',
          startWithNewLine: false,
          title: 'Grade',
          isRequired: true,
          hideNumber: true
        },
        {
          type: 'dropdown',
          name: 'campus',
          title: 'Campus',
          isRequired: true,
          hideNumber: true,
          choices: [...schools],
        },
        {
          type: 'text',
          name: 'teacherName',
          startWithNewLine: false,
          title: 'Teacher Name',
          isRequired: true,
          hideNumber: true
        }
      ],
      name: 'panel1'
    },
    {
      type: "radiogroup",
      name: "gender",
      isRequired: true,
      hideNumber: true,
      title:
        "Your gender",
      choices: [...genders]
    },
    {
      type: "radiogroup",
      name: "race",
      isRequired: true,
      hideNumber: true,
      title:
        "Race",
      choices: [...races]
    }

  ]
};

/**
 * SignIn Component
 */
const SurveyForm = ({ loadSurvey, loadedSurvey, submitSurveyResult, match, surveyError, history, location }) => {
  Survey.StylesManager.applyTheme("default");

  /**
   * React Hooks
   */

  // const [loginError, setLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState();
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(()=> {
    if (match.params.id) {
      setLoading(true);
      loadSurvey(match.params.id);
    }

  }, [match.params]);

  useEffect(()=> {
    if (loadedSurvey) {
      setLoading(false);
      console.log('loaded-survey', loadedSurvey);
       if (loadedSurvey?.showBio) {
         bioPage['description'] = loadedSurvey?.description || "";
         loadedSurvey.pages = [bioPage].concat(loadedSurvey.pages);
       }
      setModel(new Survey.Model(loadedSurvey));
    }
  }, [loadedSurvey]);

  /**
   * Handler Functions
   */

  const obj = qs.parse(location.search);

  /**
   * Input changes state get updated
   * @param {*} event
   */
  const handleSubmit = async (surveyResponse) => {
    setLoading(true);
    setResponse(surveyResponse);
     submitSurveyResult(loadedSurvey._id, {uniqueRef: obj.responder, ...surveyResponse.data}).then(res=> {
       setLoading(false);
       if (res.error) {
         setError(res.error.message);
       } else {
         setError(null);
       }
     });

  };

  const reSubmit = function(  ) {
    setLoading(true);
  submitSurveyResult(loadedSurvey._id, {uniqueRef: obj.responder, ...response.data})
      .then(res=> {
        setLoading(false);
        if (res.error) {
          setError(res.error.message);
        } else {
          setError(null);
        }
      });
  };





  return loading ? (
    <Spinner />
  ) : (
    <section>
      <div >
        <div className="container">
          <div className="f-inn-content">
            <div className="row align-items-center">
              <div className="col-sm-2 header">
                <Logo />
              </div>

            </div>
            <div className="row align-items-center">
              {!error && new Date() <= loadedSurvey?.endDate && <div className="col-sm-12">
                {loadedSurvey && !!model && <Survey.Survey model={model}  onComplete={handleSubmit} />}
              </div>}
              {loadedSurvey?.endDate && !error && new Date() > new Date(loadedSurvey?.endDate) && <div className="col-sm-12">
                <h3 style={{color: '#ddd'}}>Sorry, this survey is no longer available.</h3>
              </div>}
              {error && <div className="col-sm-12 text-center">
                <h3>{`Error! ${error}`}</h3>
                <h5>Try again.</h5>
                <Button onClick={reSubmit} className={'btn'}>Submit</Button>
              </div>}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({
  loadedSurvey: currentSurvey,
  surveyError: surveyError,
});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({
  loadSurvey: (id) => dispatch(getSurvey(id)),
  submitSurveyResult: (id, data) => dispatch(submitSurvey(id, data)),
});

/**
 * Export SignIn Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(SurveyForm);
