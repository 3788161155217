import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { selectFormStyles } from './selectFormStyles';

const TagsInput = ({ placeholder, cB, value }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Guard against storing previous state
  useEffect(() => {
    if (value && value?.length === 0) setOptions([]);
  }, [value]);

  const components = {
    DropdownIndicator: null
  };

  const createOption = (label) => ({
    label,
    value: label
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        setOptions([...options, createOption(inputValue)]);
        //  Passed in CB to set state in your component
        cB([...options, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };
  const handleBlur = (event) => {
    if (!inputValue) return;
        setOptions([...options, createOption(inputValue)]);
        //  Passed in CB to set state in your component
        cB([...options, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
  };

  const handleOnChange = (e) => {
    setOptions(e);
    cB(e);
  };

  return (
    <div>
      <CreatableSelect
        isMulti
        isClearable
        value={value}
        onChange={(e) => handleOnChange(e)}
        components={components}
        inputValue={inputValue}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onInputChange={(e) => setInputValue(e)}
        menuIsOpen={false}
        placeholder={placeholder}
        styles={selectFormStyles}
      />
    </div>
  );
};

export default TagsInput;
