import { type } from './company.types';
import api from '../../axios';

export const companyList = () => {
  return async (dispatch) => {
    dispatch({
      type: type.COMPANY_LIST_START
    });

    try {
      const response = await api.get('/industryPartner/get-industry-partner');
      dispatch({
        type: type.COMPANY_LIST_SUCCESS,
        payload: response.data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.COMPANY_LIST_ERROR,
        error: error.response
      });
    }
  };
};
