import { type } from './employees.types';
import api from '../../axios';

// Get users
export const getUsers = (params) => async (dispatch) => {
  try {
    const res = await api.get(`/users`, {
      params: {
        per_page: 10,
        ...params
      }
    });

    dispatch({
      type: type.GET_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: type.EMPLOYEE_ERROR,
      payload: err
    });
  }
};

// Get Employees
export const getEmployees = (partnerId, params) => async (dispatch) => {
  try {
    const res = await api.get(`/users`, {
      params: {
        current_profile_type: 'Volunteer',
        per_page: 10,
        ...params
      }
    });
    dispatch({
      type: type.GET_USERS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: type.EMPLOYEE_ERROR,
      payload: err
    });
  }
};

// Invite users / Employees
export const inviteUser = (inviteData, cB) => async (dispatch) => {
  try {
    await api.post('/users/send-invite', inviteData);
    // CB will close modal and reset form
    if (cB) cB();
  } catch (err) {
    dispatch({
      type: type.EMPLOYEE_ERROR,
      payload: err
    });
  }
};

// Approve volunteers
export const approveUser = (userId, cB) => async (dispatch) => {
  try {
    const res = await api.put(`/users/${userId}/mark-as-verified`);
    // CB will reset users
    if (cB) cB();
    return res;
  } catch (err) {
    dispatch({
      type: type.EMPLOYEE_ERROR,
      payload: err
    });
  }
};

// UN Approve volunteers
export const unApproveUser = (userId, cB, setUsers) => async (dispatch) => {
  try {
    const res = await api.put(`/users/${userId}/mark-as-unverified`);
    // CB will reset users
    if (cB) cB();
    if (setUsers) setTimeout(() => setUsers(), 500);
  } catch (err) {
    dispatch({
      type: type.EMPLOYEE_ERROR,
      payload: err
    });
  }
};
