/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Spinner from '../spinner/spinner.component';
import api from '../../axios';
import './survey.styles.scss';
import { createStructuredSelector } from 'reselect';
import {
  registrationDetails,
  userErrors
} from '../../redux/user/user.selector';
import { companyDetails } from '../../redux/company/company.selector';
import { signUp } from '../../redux/user/user.action';
import { companyList } from '../../redux/company/company.action';
import { connect } from 'react-redux';
import { isArray } from 'jquery';
import { genders, races, schools } from '../../data/survey-biodata';
import { Button, Pagination } from 'antd';

const SurveyDetail = (props) => {
  //survey id
  const { id } = useParams();
  const { state } = useLocation();
  const [survey, setSurvey] = useState({});
  const [page, setPage] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [questionId, setQuestionId] = useState(null);
  const [renderChart, setRenderChart] = useState(undefined);
  const [responders, setResponders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseOfResponder, setResponseOfResponder] = useState({});
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedRace, setSelectedRace] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedCampus, setSelectedCampus] = useState('');
  const [selectedStudentNumber, setSelectedStudentNumber] = useState('');
  const [pagination, setPagination] = useState({});
  const [chartValue, setChartValue] = useState('percent');
  const [chartFormData, setChartFormData] = useState({ } );

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    plotOptions: {
      column: {
        colors: [
          '#3d5afe',
          '#004d40',
          '#8c9eff',
          '#c51162',
          '#1a237e',
          '#7986cb',
          '#c5cae9',
          '#448aff',
          '#e91e63',
          '#d500f9'
        ]
      }
    },
    legend: {
      enabled: false
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: 'Final Test'
    },
    tooltip: {
      //   headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}'+ (chartValue==="percent"?"%":"")+'</b><br/>'
    },
    series: [
      {
        name: '',
        colorByPoint: true,
        data: []
      }
    ]
  });

  const handleChangeQuestion = (e) => {
    setQuestionId(e.target.value);
  };

  useEffect(() => {
    setSurvey(state.survey);
    if (id) {
      // api
      //   .get(`/survey/surveys/${id}`)
      //   .then((resp) => {
      //
      //     if (resp.data.data) {
      //       setSurvey(resp.data.data);
      //     }
      //     console.log(resp.data.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      api
        .get(`/survey/surveys/${id}/questions`)
        .then((resp) => {
          const filteredQuestions = resp.data.data.filter(
            (q) => q?.data?.choices !== undefined
          );
          setQuestions(filteredQuestions);

          if (filteredQuestions[0]) {
            setQuestionId(filteredQuestions[0]['_id']);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      //fetch responder
      filteredSurveyRequest();
    }
  }, [id, state.survey]);

  useEffect(() => {
    //fetch survey data and change data structure for visualization
    if (questionId && survey) {
      setRenderChart(false);
      const url = selectedCampus ? `/survey/responses?question=${questionId}&survey=${survey._id}&data.campus=${selectedCampus}&per_page=100000`
        : `/survey/responses?question=${questionId}&survey=${survey._id}&per_page=100000`;
      api
        .get(url)
        .then((resp) => {
          const rawData = resp.data.data;
          const formatedData = {};
          let count = 0;
          rawData.forEach((item) => {
            count++;
            if (!Array.isArray(item.answer)) item.answer = [item.answer];
            item.answer.forEach((answer) => {
              formatedData[answer] = (formatedData[answer] || 0) + 1;
            });
          });
          console.log('formated', formatedData);
          setChartFormData({...formatedData});
          if (chartValue=='percent') {
            Object.keys(formatedData).forEach(k => {
              formatedData[ k ] = ((parseFloat(''+formatedData[ k ]) / count) * 100);
            });
          }
          const question = questions.find((q) => q._id === questionId);
          prepareAndSetData(formatedData, question);
        });
    }
  }, [questionId, questions, survey, selectedCampus]);

  useEffect(() => {
    //fetch survey data and change data structure for visualization
    if (questionId && survey) {
        setChartFormData({...chartFormData});
          let formattedData = chartFormData;
          let count = 0;

          if (chartValue == 'percent') {
            Object.keys(formattedData).forEach(k => {
              count = count + formattedData[ k ];
            });
            Object.keys(formattedData).forEach(k => {
              formattedData[ k ] = (formattedData[ k ] / count) * 100;
            });
          }
          const question = questions.find((q) => q._id === questionId);
          prepareAndSetData(formattedData, question);
    }
  }, [chartValue]);


  function prepareAndSetData(data, question) {
    let values = [];
    for (const choice of question?.data?.choices ?? []) {
      values = [
        ...values,
        {
          y: data[choice?.value || choice],
          name: choice?.text || choice,
          drilldown: null
        }
      ];
    }
    console.log('question', question?.data);
    setChartOptions({
      ...chartOptions,
      title: {
        text: question.data.title
      },
      series: [
        {
          colorByPoint: true,
          name: question.data.title,
          data: [...values]
        }
      ],
      tooltip: {
        //   headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        headerFormat: '',
        pointFormat:
          chartValue==="percent"?
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}'+ (chartValue==="percent"?"%":"")+'</b><br/>' :
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b><br/>'
      },
    });

    setRenderChart(true);
  }

  useEffect(() => {
    filteredSurveyRequest();
  }, [selectedRace, selectedGender, selectedSchool, page]);
  useEffect(() => {
    if (selectedStudentNumber.length > 0 && selectedStudentNumber.length < 3) return;
    filteredSurveyRequest();
  }, [selectedStudentNumber]);

  const filteredSurveyRequest = () => {
    const params = {
      survey: id,
      page
    };

    if (selectedRace) {
      params['data.race'] = selectedRace;
    }
    if (selectedGender) params['data.gender'] = selectedGender;
    if (selectedSchool) params['data.campus'] = selectedSchool;
    if (selectedStudentNumber) params['data.studentId'] = selectedStudentNumber;
    const url = `/survey/responders`;

    api
      .get(url, { params })
      .then((resp) => {
        setResponders(resp.data.data || []);
        // setTotalResponder(resp.data?.meta?.pagination?.total_count || 0);
        setPagination(resp.data?.meta?.pagination);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelect = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    console.log('selected-value:', value);
    switch (key) {
      case 'studentId':
        setSelectedStudentNumber(value);
        break;
      case 'race':
        setSelectedRace(value);
        break;
      case 'gender':
        setSelectedGender(value);
        break;
      case 'school':
        setSelectedSchool(value);
        break;
      case 'campus':
        setSelectedCampus(value);
        break;
      default:
        break;
    }
  };

  const setCurrentPage = (p) => {
    setPage(p);
  };
  const resetForm = () => {
    setSelectedRace('');
    setSelectedGender('');
    setSelectedSchool('');
    setSelectedCampus('');
    setSelectedStudentNumber('');
  };

  const handleLoadingResponderData = (surveyId, responderId) => {
    setIsLoading(true);
    const url = `/survey/responses?survey=${surveyId}&per_page=100&responder=${responderId}&population=[{"path":"responder"},{"path":"question"}]`;

    api
      .get(url)
      .then((resp) => {
        const data = resp.data.data;
        let formatedResponse = [];
        let responder = null;

        for (const q of data) {
          if (!responder) responder = { ...q.responder, ...q.responder?.data };
          if (!Array.isArray(q.answer)) q.answer = [q.answer];
          const answer = q.answer.map((a) =>
            q.question.data.choices.find((c) => c == a || c.value === a)
          );
          console.log('answer', answer, q.answer, q.question.data.choices);
          formatedResponse.push({
            title: q.question.data.title,
            answer: answer.map((a) => a?.text || a).join(', ')
          });
        }

        setResponseOfResponder({
          responder,
          response: formatedResponse
        });
        console.log(responseOfResponder);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <h2>Surveys</h2>
        <div className="row ml-lg-4">
          <div className="col-md-12">
            <h5>{survey.title ?? ''}</h5>
          </div>
        </div>
        <div className="row col-md-12">
          <div className="row ml-lg-4">
            <div className="col-lgs-12 col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <select
                  className="form-control sm mt-4"
                  style={{ fontSize: '11px' }}
                  onChange={handleChangeQuestion}
                  value={questionId || ''}
                >
                  <option value="" hidden>
                    Select Question
                  </option>
                  {questions
                    .filter((q) => !!q._id)
                    .map((question) => (
                      <option value={question._id} key={question._id+Math.random()}>
                        {question.data.title}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row ml-lg-4">
            <div className="col-lgs-12 col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <select
                  onChange={handleSelect}
                  name="campus"
                  value={selectedCampus}
                  className="form-control sm mr-3"
                  style={{marginTop: '20px'}}
                >
                  <option value={''}>All Campuses</option>
                  {schools.map((g) => {
                    return (
                      <option key={g.value} value={g.value}>
                        {g.text}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row ml-lg-4">
            <div className="col-lgs-12 col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <select
                  onChange={(v)=>{
                    setChartValue(v?.target?.value)
                  }}
                  name="percentage"
                  value={chartValue}
                  className="form-control sm mr-3"
                  style={{marginTop: '20px'}}
                >
                  <option value={'percent'}>Percentage Values</option>
                  <option value={'absolute'}>Absolute Values</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {renderChart === undefined ? (
          <div className="col-12 text-center">
            <h4>Please select a question to see graph</h4>
          </div>
        ) : renderChart === false ? (
          <Spinner/>
        ) : (
          <div className="row ml-lg-4">
            <div className="col-lg-12 mb-4">
              <HighchartsReact
                containerProps={{ style: { height: '350px', width: '100%' } }}
                highcharts={Highcharts}
                options={chartOptions}
              />
            </div>
            <div className="col-lg-12">
              <div className="white-box employees-wrap">
                <div className="table-wrap">
                  <div className="row">
                    <div className="col-lg mb-3 mb-lg-0">
                      <input
                        onChange={handleSelect}
                        name="studentId"
                        value={selectedStudentNumber}
                        className="form-control sm mr-3"
                      />
                    </div>

                    <div className="col-lg mb-3 mb-lg-0">
                      <select
                        onChange={handleSelect}
                        name="race"
                        value={selectedRace}
                        className="form-control sm mr-3"
                      >
                        <option value={''}>All Races</option>
                        {races.map((g) => {
                          return (
                            <option key={g.value} value={g.value}>
                              {g.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-lg mb-3 mb-lg-0">
                      <select
                        onChange={handleSelect}
                        name="school"
                        value={selectedSchool}
                        className="form-control sm mr-3"
                      >
                        <option value={''}>All Campuses</option>
                        {schools.map((g) => {
                          return (
                            <option key={g.value} value={g.value}>
                              {g.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-lg mb-2 mb-lg-0">
                      <select
                        onChange={handleSelect}
                        value={selectedGender}
                        name="gender"
                        className="form-control sm mr-3"
                      >
                        <option value={''}>All Genders</option>
                        {genders.map((g) => (
                          <option key={g.value} value={g.value}>
                            {g.text}
                          </option>
                        ))}
                      </select>

                    </div>
                    <div className="col-lg mb-2 mb-lg-0">
                      <Button onClick={resetForm}>Reset</Button>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className="col mb-3 mt-4 mb-lg-0">
                      <h5 className="mr-4">
                        Total Responses: {pagination?.total_count || 0}
                      </h5>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-lg-12 col-md-12 col-12">
                      <table className="table table-striped table-dark">
                        <thead>
                        <tr>
                          <th>ID</th>
                          <th>Gender</th>
                          <th>Race</th>
                          {/*<th>District</th>*/}
                          <th>Campus</th>
                          <th>Response</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!!responders &&
                        responders.map((respond) => {
                          const campusId =
                            respond?.campus || respond?.data?.campus;
                          return (
                            <tr key={respond._id}>
                              <td>
                                {respond.studentId || respond?.data?.studentId}
                              </td>
                              <td>
                                {respond.gender || respond?.data?.gender}
                              </td>
                              <td>
                                {respond?.race || respond?.data?.race}
                              </td>
                              {/*<td>{respond?.district || respond?.data?.district}</td>*/}
                              <td>
                                {schools.find((s) => s.value === campusId)
                                  ?.text || campusId}
                              </td>
                              <td>
                                <button
                                  className="btn btn-link text-info"
                                  data-toggle="modal"
                                  data-target="#staticBackdrop"
                                  onClick={(e) =>
                                    handleLoadingResponderData(
                                      id,
                                      respond._id
                                    )
                                  }
                                >
                                  Responses
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                      <div className={'text-center'}><Pagination
                        responsive
                        current={pagination?.current || 0}
                        pageSize={pagination?.per_page || 0}
                        total={pagination?.total_count || 0}
                        onChange={setCurrentPage}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">

          {isLoading ? (
            <div className="modal-content responder-modal align-items-center justify-content-center is-loading">
              <strong>Preparing data, please wait</strong>
              <Spinner/>
            </div>
          ) : (
            <div className="modal-content responder-modal">
              <div className="modal-header">

                <div className="responder-info">
                  {responseOfResponder?.responder?.studentId && <div className="info">
                    <div className="key">Student Id:</div>
                    <div className="value">
                      {responseOfResponder?.responder?.studentId ?? ''}
                    </div>
                  </div>}
                  {responseOfResponder?.responder?.name && <div className="info">
                    <div className="key">Name:</div>
                    <div className="value">
                      {responseOfResponder?.responder?.name ?? ''}
                    </div>
                  </div>}
                  <div className="info">
                    <div className="key">Gender:</div>
                    <div className="value">
                      {responseOfResponder?.responder?.gender ?? ''}
                    </div>
                  </div>

                  {responseOfResponder?.responder?.teacherName && <div className="info">
                    <div className="key">Teacher's Name:</div>
                    <div className="value">
                      {responseOfResponder?.responder?.teacherName ?? ''}
                    </div>
                  </div>}
                  {responseOfResponder?.responder?.grade && <div className="info">
                    <div className="key">Grade:</div>
                    <div className="value">
                      {responseOfResponder?.responder?.grade ?? ''}
                    </div>
                  </div>}
                </div>
                <div className="responder-info">
                  {/*<div className="info">*/}
                  {/*  <div className="key">district:</div>*/}
                  {/*  <div className="value">*/}
                  {/*    {responseOfResponder?.responder?.district ?? ''}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="info">
                    <div className="key">Campus:</div>
                    <div className="value">
                      {schools.find(
                        (s) =>
                          s.value ===
                          (responseOfResponder?.responder?.campus ||
                            responseOfResponder?.responder?.data?.campus)
                      )?.text ||
                      responseOfResponder?.responder?.campus ||
                      responseOfResponder?.responder?.data?.campus}
                    </div>
                  </div>
                  <div className="info">
                    <div className="key">Race:</div>
                    <div className="value">
                      {responseOfResponder?.responder?.race ?? ''}
                    </div>
                  </div>
                </div>
                <div className={'text-right'}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="question-answer">
                  {responseOfResponder?.response &&
                  responseOfResponder?.response.map((q, i) => (
                    <div className="question-item">
                      <h6>
                        {i + 1}.<span>{q?.title}</span>
                      </h6>
                      <div className="answer">
                        <strong>Answer:</strong>{' '}
                        <span>{q?.answer ?? 'No Answer Provided'}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({});

/**
 * Export Employees Class
 */
export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetail);
