export const type = {
  SIGNUP_START: 'SIGNUP_START',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',

  SIGNIN_START: 'SIGNIN_START',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SIGNIN_ERROR: 'SIGNIN_ERROR',

  DASHBOARD_START: 'DASHBOARD_START',
  DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',
  DASHBOARD_ERROR: 'DASHBOARD_ERROR',

  GET_USER_START: 'GET_USER_START',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_ERROR: 'GET_USER_ERROR',

  UPDATE_USER_START: 'UPDATE_USER_START',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

  GET_ALL_USER_START: 'GET_ALL_USER_START',
  GET_ALL_USER_SUCCESS: 'GET_ALL_USER_SUCCESS',
  GET_ALL_USER_ERROR: 'GET_ALL_USER_ERROR',

  SEARCH_USER_START: 'SEARCH_USER_START',
  SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
  SEARCH_USER_ERROR: 'SEARCH_USER_ERROR',

  UPLOAD_AVATAR: 'UPLOAD_AVATAR',

  GET_ACCOUNT: 'GET_ACCOUNT',
  GET_ACCOUNT_ERROR: 'GET_ACCOUNT_ERROR',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_ERROR: 'UPDATE_ACCOUNT_ERROR',

  UPDATE_COMPANY_START: 'UPDATE_COMPANY_START',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
};
