export const type = {
  UPCOMING_EVENT_DETAIL_START: 'UPCOMING_EVENT_DETAIL_START',
  UPCOMING_EVENT_DETAIL_SUCCESS: 'UPCOMING_EVENT_DETAIL_SUCCESS',
  UPCOMING_EVENT_DETAIL_ERROR: 'UPCOMING_EVENT_DETAIL_ERROR',

  CREATE_EVENT_START: 'CREATE_EVENT_START',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_ERROR: 'CREATE_EVENT_ERROR',

  SEND_EVENT_INVITATION_START: 'SEND_EVENT_INVITATION_START',
  SEND_EVENT_INVITATION_SUCCESS: 'SEND_EVENT_INVITATION_SUCCESS',
  SEND_EVENT_INVITATION_ERROR: 'SEND_EVENT_INVITATION_ERROR',

  EDIT_EVENT_START: 'EDIT_EVENT_START',
  EDIT_EVENT_SUCCESS: 'EDIT_EVENT_SUCCESS',
  EDIT_EVENT_ERROR: 'EDIT_EVENT_ERROR',

  GET_EVENT_START: 'GET_EVENT_START',
  GET_EVENT_SUCCESS: 'GET_EVENT_SUCCESS',
  GET_EVENT_ERROR: 'GET_EVENT_ERROR',

  DELETE_EVENT_START: 'DELETE_EVENT_START',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_ERROR: 'DELETE_EVENT_ERROR',

  GET_EVENT_BY_ID_START: 'GET_EVENT_BY_ID_START',
  GET_EVENT_BY_ID_SUCCESS: 'GET_EVENT_BY_ID_SUCCESS',
  GET_EVENT_BY_ID_ERROR: 'GET_EVENT_BY_ID_ERROR',

  GET_EVENT_ACTIVITY_TYPE_START: 'GET_EVENT_ACTIVITY_TYPE_START',
  GET_EVENT_ACTIVITY_TYPE_SUCCESS: 'GET_EVENT_ACTIVITY_TYPE_SUCCESS',
  GET_EVENT_ACTIVITY_TYPE_ERROR: 'GET_EVENT_ACTIVITY_TYPE_ERROR'
};
