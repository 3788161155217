/**
 * Import NPM Packages
 */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import qs from 'query-string';

/**
 * Import Redux functions
 */
import { signUp, updateUser } from '../../redux/user/user.action';
import { companyList } from '../../redux/company/company.action';
import {
  registrationDetails,
  userErrors,
  userDetails
} from '../../redux/user/user.selector';
import { companyDetails } from '../../redux/company/company.selector';

/**
 * Import styles
 */
import './sign-up.styles.scss';

/**
 * Import Components
 */
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import UpcomingEvent from '../upcoming-event/upcoming-event.component';
import Logo from '../logo/logo.component';
import Spinner from '../spinner/spinner.component';
import { Spin } from 'antd';

/**
 * Go back component
 */
const GoBack = ({ handleChange }) => (
  <div className="f-foot-link text-center">
    <a
      style={{ cursor: 'pointer' }}
      className="font14 muli-semiBold blue-gary"
      onClick={() => localStorage.clear() && handleChange()}
    >

    </a>
  </div>
);

/**
 * SignUp Component
 */
const SignUp = ({
                  signUp,
                  registrationInfo,
                  history,
                  location,
                  companyList,
                  companyInfo,
                  errors,
                  user,
                  updateUser
                }) => {
  /**
   * React Hooks
   */
  const fileUploader = useRef(null);
  const [signUpForm, setSignUpForm] = useState(1);
  const [showFormError, setShowFormError] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [eventId, setEventId] = useState(null);
  const [validateEmail, setValidateEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState(null);
  const [canRegister, setCanRegister] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [userDetails, setUserDetails] = useState({
    emailAddress: '',
    password: '',
    termConditions: false,
    company: '',
    firstName: '',
    lastName: '',
    designation: '',
    profileImage: '/assets/images/s-pro.png',
    sendProfileImage: '',
    about: '',
    isSocial: '0',
    token: '',
    profileType: ''
  });

  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/dashboard');
      return;
    }
    companyList();
    const obj = qs.parse(location.search);
    const userType = { 'admin': 'Admin', 'industry-partners': 'IndustryPartner', 'volunteers': 'Volunteer' };
    console.log('obj:', obj);
    setEventId(obj.eventId);
    setFormType(obj.type);
    setUserDetails({ ...userDetails, token: obj.token, profileType: userType[obj.type] });

    if (typeof obj.type === 'undefined') {
      setCanRegister(false);
    }
    // setFormType(obj.type)
  }, []);

  useEffect(() => {
    if (companyInfo?.result) {
      setCompanyData(companyInfo.result);
    }
  }, [companyInfo]);

  useEffect(() => {
    setErrorMessage(errors);
  }, [errors]);
  /**
   * Handler Functions
   */

  const validateEmailAddress = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Input changes state get updated
   * @param {*} event
   */
  const formHandleChange = (event) => {
    let { name, value } = event.target;
    setShowFormError(false);
    setValidateEmail(true);

    if (name === 'termConditions') {
      value = value === 'true' ? false : true;
    }
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  /**
   * When Go back button hits it take to prev page of signup
   */
  const formPrevPageHandler = () => {
    setSignUpForm((prevState) => prevState - 1);
  };

  /**
   * Profile picture upload function
   * @param {*} event
   */
  const uploadProfileHandler = (event) => {
    const profilePic = event.target.files;
    setUserDetails((prevState) => ({
      ...prevState,
      sendProfileImage: profilePic[0],
      profileImage: URL.createObjectURL(profilePic[0])
    }));
  };

  /**
   * When form submits if all validation are perfect then next form is called
   */
  const formSubmitHandler = () => {
    if (signUpForm === 1) {
      if (
        userDetails.emailAddress &&
        userDetails.password &&
        userDetails.termConditions
      ) {
        if (validateEmailAddress(userDetails.emailAddress)) {
          if (validateEmailAddress(userDetails.emailAddress)) {
            const {
              emailAddress,
              token,
              password,
              profileType
            } = userDetails;

            const submitObj = {
              email: emailAddress,
              password: password,
              invitation_token: token,
              profile_type: profileType
            };
            setIsLoading(true);
            console.log('submitObj:', submitObj);
            signUp(submitObj)
              .then((response) => {
                  console.log('junn', response, errors);
                  if (response && response?.status === 200) {
                    setIsLoading(false);
                    localStorage.setItem('token', response.data._meta.token);
                    localStorage.setItem('cte_user', JSON.stringify(response.data.data));
                    // Save to
                    setSignUpForm((prevState) => prevState + 1);
                    setShowFormError(false);

                  } else {
                    setIsLoading(false);
                    setRegistrationError(true);
                  }
                },
                (err) => {
                  setSignUpForm(1);
                  setIsLoading(false);
                  setRegistrationError(true);
                  console.log(errors);
                  setShowFormError(errors);
                });
          }
        } else {
          setValidateEmail(false);
          setShowFormError(true);
        }
      } else {
        if (!validateEmailAddress(userDetails.emailAddress)) {
          setValidateEmail(false);
        }
        setShowFormError(true);
      }
    }
    if (signUpForm === 2) {
      let errors = false;
      // if (formType === 'volunteers') {
      //   if (!(userDetails.firstName && userDetails.lastName)) {
      //     setShowFormError(true);
      //     errors = true;
      //   } else {
      //     setShowFormError(false);
      //   }
      // } else {
      if (!(userDetails.firstName && userDetails.lastName)) {
        setShowFormError(true);
        errors = true;
      } else {
        setShowFormError(false);
        setIsLoading(true);
        console.log(user._id);
        updateUser(user._id, { first_name: userDetails.firstName, last_name: userDetails.lastName })
          .then(response => {
            if (user.current_profile_type == 'IndustryPartner' && !user.company) {
              history.push('/settings?tab=company');
              return;
            }
            history.push('/avatar-upload');
          }, error => {
            setIsLoading(false);
            setShowFormError(true);
          });

      }
      // }

      if (!errors) {
        setShowFormError(false);
        setIsLoading(true);


      }
    }
  };

  return (
    <section className="form-body">
      <div className="form-box">
        <div className="container">
          <div className="f-inn-content">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <Logo/>
              </div>
              {eventId ? <div className="col-sm-12 col-md-6">
                <UpcomingEvent eventId={eventId}/>
              </div> : <div className={'col-sm-1 col-md-6'}/>}
              <div className={eventId ? 'col-sm-6' : 'col-sm-12 col-md-6'}>
                <div className="sign-form">

                  {signUpForm === 1 ? (
                    <>
                      {/* <CustomButton className="g-btn">
                        <img src="assets/images/g-icon.png" alt="" title="" />
                        Sign in with Google
                      </CustomButton> */}
                      {/* <div className="divider-title mr-t30 mr-b30">
                        <h5 className="text-center">
                          <span>Or, sign up with your email</span>
                        </h5>
                      </div> */}
                      <h4>Sign Up</h4>
                      <div className="form-inputs">
                        <FormInput
                          disabled={!canRegister}
                          label="Email address"
                          type="email"
                          name="emailAddress"
                          value={userDetails.emailAddress}
                          placeholder="Enter email address"
                          handleChange={formHandleChange}
                          showError={
                            showFormError &&
                            (userDetails.emailAddress === '' || !validateEmail)
                              ? true
                              : false
                          }
                          errorText={
                            userDetails.emailAddress === ''
                              ? 'Please insert Email address'
                              : !validateEmail
                              ? 'Please insert validate email address'
                              : null
                          }
                        />
                        <FormInput
                          disabled={!canRegister}
                          label="Password"
                          type="password"
                          name="password"
                          value={userDetails.password}
                          placeholder="Enter password"
                          handleChange={formHandleChange}
                          showError={
                            showFormError && userDetails.password === ''
                              ? true
                              : false
                          }
                          errorText="Please insert password"
                        />
                        <div className="t-link">
                          <label htmlFor="s1" className="cus-small-check">
                            <input
                              type="checkbox"
                              checked={
                                userDetails.termConditions === true
                                  ? true
                                  : false
                              }
                              id="s1"
                              name="termConditions"
                              value={userDetails.termConditions}
                              onChange={formHandleChange}
                            />
                            <span> I agree to the Terms & Conditions</span>
                          </label>
                        </div>
                        {showFormError &&
                        userDetails.termConditions === false ? (
                          <span className="signup-error">
                            Please agree to terms and conditions
                          </span>
                        ) : null}
                        {!canRegister && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              paddingTop: 15
                            }}
                          >
                            <span
                              className="signup-error"
                              style={{ textAlign: 'center' }}
                            >
                              You must be invited to register through an email
                            </span>
                          </div>
                        )}
                        {!!errors ? (
                          <div
                            style={{

                              justifyContent: 'center',
                              paddingTop: 15
                            }}
                          >
                            <span
                              className="signup-error"
                              style={{ textAlign: 'center' }}
                            >
                              {errors}
                            </span>
                          </div>
                        ) : null}
                        <CustomButton
                          disabled={!canRegister || isLoading}
                          className="s-btn blue-btn w-90 mr-t30 mr-b20"
                          onClick={formSubmitHandler}
                        >
                          {isLoading ? (
                            <Spin/>
                          ) : (
                            'Continue'
                          )}
                        </CustomButton>
                        <div className="f-foot-link">
                          <span className="font14 muli-bold blue-gary mr-r30">
                            Already has an account?
                          </span>
                          <Link
                            to={{
                              pathname: '/signin',
                              state: {
                                eventId: eventId
                              }
                            }}
                            className="font14 muli-bold blue-blue"
                          >
                            Sign in now
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : signUpForm === 2 ? (
                    <>
                      <h4>What's Your Name</h4>
                      <div className="form-inputs mr-b30">
                        <form>
                          <FormInput
                            readOnly=""
                            label="First Name"
                            type="text"
                            name="firstName"
                            // value={userDetails.firstName}
                            placeholder="Enter first name"
                            handleChange={formHandleChange}
                            showError={
                              showFormError && userDetails.firstName === ''
                                ? true
                                : false
                            }
                            errorText="Please insert first name"
                          />

                          <FormInput
                            readOnly=""
                            label="Last Name"
                            type="text"
                            name="lastName"
                            // value={userDetails.lastName}
                            placeholder="Enter last name"
                            handleChange={formHandleChange}
                            showError={
                              showFormError && userDetails.lastName === ''
                                ? true
                                : false
                            }
                            errorText="Please insert last name"
                          />
                        </form>
                        <CustomButton
                          disabled={isLoading}
                          className="s-btn blue-btn w-60 mr-t30 mr-b20"
                          onClick={formSubmitHandler}
                        >
                          {isLoading ? (
                            <Spin/>
                          ) : (
                            'Continue'
                          )}
                        </CustomButton>
                        <GoBack handleChange={formPrevPageHandler}/>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({
  registrationInfo: registrationDetails,
  user: userDetails,
  companyInfo: companyDetails,
  errors: userErrors
});

/**
 * Redux Dispatch action
 */
const mapDispatchToProps = (dispatch) => ({
  signUp: (userInfo) => dispatch(signUp(userInfo)),
  companyList: () => dispatch(companyList()),
  updateUser: (id, data) => dispatch(updateUser(id, data))
});

/**
 * Export SignUp Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
