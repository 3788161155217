import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import avatar from '../avatar.png';
import { jwtDecode } from '../../../jwt-decode';
import { connect } from 'react-redux';
import {
  getUsers,
  getEmployees,
  approveUser,
  unApproveUser
} from '../../../redux/employees/employees.actions';
import { parseBlobUrl } from '../../../utils/helper';
import { Button, Modal, Pagination, Spin, Tabs } from 'antd';
import { PicLeftOutlined, TeamOutlined, PlusOutlined } from '@ant-design/icons';
import { getAllEvents } from '../../../redux/event/event.action';
import EventCard from '../../events/components/EventCard';
import EmptyEvent from '../../events/empty-event/empty-event.component';

const TableListItem = ({
                         user,
                         index,
                         approveUser,
                         unApproveUser,
                         getUsers,
                         getEmployees,
                         getUsersRequest,
                         currentPage,
                         currentTab,
                         hideItem,
                         getAllEvents
                       }) => {
  const userDetail = jwtDecode();
  const { current_profile_type } = userDetail;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabLabels, setLabels] = useState([
    { label: 'Invited To', index: 0, Icon: PicLeftOutlined },
    { label: 'Attending', index: 1, Icon: TeamOutlined }
  ]);
  const [tabIndex, setTabIndex] = useState(0);
  const [pagination, setPagination] = useState({});

  const handleInviteClick = () => {
    approveUser(user._id, () => getUsersRequest(currentTab, currentPage));
  };

  // const unApproveClick = () => {
  //   const callBack =
  //     current_profile_type === 'Admin'
  //       ? () => getUsers()
  //       : () => getEmployees(userDetail._id);
  //   unApproveUser(user._id, callBack, setUsers);
  // };

  const getEventsRequest = (
    tabIndex,
    page = 1,
    per_page = 8,
    loading = true
  ) => {
    if (loading) {
      setLoading(true);
      setEventList([]);
    }

    const params = {
      page,
      per_page,
      population: JSON.stringify(['invitees'])
    };

    let currentRequest = getAllEvents;

    const tab = tabLabels[tabIndex];
    if (tab?.index === 0) {
      params.invitee_id = user._id;
    } else if (tab?.index === 1) {
      params.attendee_id = user._id;
    }
    currentRequest(params)
      .then((res) => {
        if (res.status === 200) {
          setEventList(res.data.data);
          setPagination(res.data._meta.pagination);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err :', err);
      });
  };

  const handleViewUser = e => {
    setIsModalVisible(true);
    getEventsRequest(tabIndex);
  };

  useEffect(() => {
    getEventsRequest(tabIndex);
  }, []);

  const handleTabChange = (key) => {
    setTabIndex(key);
    getEventsRequest(key);
  };

  const handlePaginationChange = (page, pageSize) => {
    getEventsRequest(tabIndex, page, pageSize, false);
  };


  const getActionContent = () => {
    // Don't need to be verified
    if (
      user.current_profile_type === 'Admin' ||
      user.current_profile_type === 'IndustryPartner'
    )
      return 'N / A';
    // Has Been verified
    if (user.verification_status === 'verified')
      return <span className="user-approved">Approved</span>;
    // Needs to be verified
    if (current_profile_type === 'Admin') {
      return (
        <button onClick={handleInviteClick} className="btn approve-userBtn">
          Approve
        </button>
      );
    } else return 'Awaiting Approval';
  };
console.log(user.current_profile_type);
  return (
    <>
      <tr style={{ cursor: 'pointer' }} onClick={handleViewUser} className={index % 2 === 0 ? 'even-table-item' : null}>
        <td>
          <div className="image-cell">
            <div className="userTable-pro-img">
              <img src={user.avatar ? parseBlobUrl(user.avatar.url) : avatar} alt="..."/>
            </div>
            <span>
            <p style={{ marginBottom: 7 }}>
              {user.first_name} {user.last_name}
            </p>
              {user.company?.title && <p style={{ marginBottom: 7 }}>
                <p style={{ fontSize: '10px' }}>
                  {user.company.title}<br/> {user.company?.location?.city}{(user.company?.location?.state) ? ', ' + user.company?.location?.state : ''} {user.company?.location?.zip}
                </p>
              </p>}
          </span>
          </div>
        </td>
        {!hideItem && <td className="pt-3">{user.email}</td>}
        {!hideItem && <td className="pt-3">{['Volunteer','IndustryPartner'].includes(user.current_profile_type) ? (user.total_logged_hours || 0) : ''}</td>}
        {!hideItem && <td className="pt-3">{user.current_profile_type}</td>}
        <td>{getActionContent()}</td>
      </tr>
      <Modal title="User Details" visible={isModalVisible} onOk={() => setIsModalVisible(false)}
             onCancel={() => setIsModalVisible(false)}
             width={800}
      >

        <div className="row">
          <div className="col-sm-12">
            <div className="white-box event-form-wrap">
              <div className="event-form-block">
                <div className="profile-container">
                  <div className="row">
                    <div className="col-sm-12 col-md-8">
                      <div className="user-info">
                        <div className="user-info__avatar">
                          <div className="avatar-uploader">
                            <input
                              type="file"
                              id="avatar"
                              name="avatar"
                              accept="image/png, image/jpeg"
                              className="d-none"
                            />
                            <div className="img-container">
                              <img src={user.avatar ? parseBlobUrl(user.avatar.url) : avatar} alt="..."/>
                            </div>
                          </div>
                        </div>
                        <div className="user-info__content">
                          <h5 className="type" style={{ fontSize: '12px' }}>Email: <span>{user.email}</span></h5>
                          <h5 className="type" style={{ fontSize: '12px' }}>
                            Status: <span>{getActionContent()}</span>
                          </h5>
                          <h5 className="type" style={{ fontSize: '12px' }}>
                            Account Type: <span>{user.current_profile_type}</span>
                          </h5>
                          {(user && user.company && (
                            <h5 className="type" style={{ fontSize: '12px' }}>
                              Company: <span>
                              {user.company.title}<br/> {user.company?.location?.city}{(user.company?.location?.state) ? ', ' + user.company?.location?.state : ''} {user.company?.location?.zip}
                              </span>
                            </h5>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TABS START */}
        <div className="col-sm-12 mx-auto">
          <Tabs
            defaultActiveKey={tabIndex}
            tabBarStyle={{
              color: '#5B6FE0',
              borderBottom: '1px solid #E7E8F2'
            }}
            onChange={handleTabChange}
          >
            {tabLabels.map(({ label, Icon }, i) => (
              <Tabs.TabPane
                tab={
                  <span>
                        <Icon/> {label}
                      </span>
                }
                key={i}
                activeKey={i}
              >
                {!loading && <div className={'row mb-2 pl-3'}>Total Events: {pagination.total_count}</div>}
                <div className="row">
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center w-100 mt-5">
                      <Spin className="mt-1 mr-3"/>{' '}
                      <span>
                            getting{' '}
                        {tabLabels[tabIndex].label.toLocaleLowerCase()}{' '}
                        events ...
                          </span>
                    </div>
                  )}

                  {!loading &&
                  (eventList !== null && eventList.length > 0 ? (
                    eventList.map((event, index) => (
                      <EventCard
                        key={index}
                        type={label}
                        event={event}
                        index={index}
                        handleDeleteClick={null}
                      />
                    ))
                  ) : (
                    <EmptyEvent
                      title={`There are currently no ${tabLabels[
                        tabIndex
                        ].label.toLocaleLowerCase()} events`}
                      subText=""
                    />
                  ))}
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
          {eventList.length > 0 && (
            <div className="d-flex justify-content-center w-100">
              <Pagination
                responsive
                current={pagination.current}
                pageSize={pagination.per_page || 8}
                total={pagination.total_count}
                onChange={handlePaginationChange}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
TableListItem.propTypes = {
  user: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  approveUser: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired
};

export default connect(null, {
  approveUser,
  getUsers,
  getEmployees,
  unApproveUser,
  getAllEvents
})(TableListItem);
