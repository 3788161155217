import jwt_decode from 'jwt-decode';

export const jwtDecode = () => {
  try {
    const token = localStorage.getItem('token');
    const decodeData = jwt_decode(token);
    const userDetail = decodeData.data;
    return userDetail;
  } catch (error) {
    localStorage.clear();
    window.location = '/signin';
  }
};
