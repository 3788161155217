export const selectFormStyles = {
  clearIndicator: (provided, state) => ({
    ...provided,
    color: '#959EAD'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff'
  }),
  menu: (provided, state) => ({
    ...provided,
    marginLeft: '-10px'
  }),
  container: () => ({
    padding: '5px 8px',
    border: '1px solid #C3CAD9',
    backgroundColor: '#fff',
    borderRadius: 5,
    position: 'relative',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.06) !important',
    width: '100%'
  }),
  control: (base) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
    position: 'relative',
    backgroundColor: '#fff'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
  option: (provided, state) => ({
    ...provided,
    letterSpacing: 0.4,
    fontWeight: state.isSelected ? 'bold' : 'normal',
    color: '#344356',
    backgroundColor: state.isSelected ? '#C9DBF6' : '#fff',
    '&:hover': {
      backgroundColor: state.isSelected ? '#C9DBF6' : '#f6f6fb'
    }
  })
};
