const searchFor = (toSearch, obj) => {
  const trimString = (s) => {
    var l = 0,
      r = s.length - 1;
    while (l < s.length && s[l] === ' ') l++;
    while (r > l && s[r] === ' ') r -= 1;
    return s.substring(l, r + 1);
  };

  const compareObjects = (o1, o2) => {
    var k = '';
    for (k in o1) if (o1[k] !== o2[k]) return false;
    for (k in o2) if (o1[k] !== o2[k]) return false;
    return true;
  };

  const itemExists = (haystack, needle) => {
    for (var i = 0; i < haystack.length; i++)
      if (compareObjects(haystack[i], needle)) return true;
    return false;
  };

  var results = [];
  toSearch = trimString(toSearch); // trim it

  for (var i = 0; i < obj.length; i++) {
    if (
      ((obj[i].first_name || '')
        .toLowerCase()
        .indexOf(toSearch.toLowerCase()) &&
        (obj[i].last_name || '')
          .toLowerCase()
          .indexOf(toSearch.toLowerCase()) &&
        (obj[i].email || '').toLowerCase().indexOf(toSearch.toLowerCase())) !==
      -1
    ) {
      if (!itemExists(results, obj[i])) results.push(obj[i]);
    }
  }

  return results;
};

export default searchFor;
