import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { notification } from 'antd';

/**
 * Import Redux functions
 */
import { getEventById, acceptDeclineEvent, markAttendenceEvent } from '../../redux/event/event.action';
import { getEventDetail } from '../../redux/event/event.selector';

import $ from 'jquery';

class CalendarModal extends React.Component {
    state = {
        eventDetail: ''
      };

    static getDerivedStateFromProps(nextProps, prevState) {
        const state = prevState;
        if (nextProps.eventInfoById !== prevState.eventInfoById) {
            state.eventDetail =
            nextProps.eventInfoById?.statusCode === 200
                ? nextProps.eventInfoById.result[0]
                : {};
        }
        return prevState === state ? null : state;
    }

    componentDidMount() {
        this.props.getEventById({eventId: this.props.eventId});
        $("#evntDetailsModal").show();
        let obj = this;
        $(".attendCheckbox").change(function() {
            if($(this).prop('checked'))
                obj._markAttendenceEvent(obj.props.id)
        });
    }

    _markAttendenceEvent(uid){
        uid = uid.toString();
        markAttendenceEvent({event_id: this.props.eventId, user_id: uid}).then((response) => {
            if (response.data.statusCode === 200) {
                $(".attendCheckbox").attr('disabled', true);
                notification.open({
                message: 'Event Operation',
                description: 'Event attended sucessfully.',
                    style: {
                        width: 600,
                        marginLeft: 335 - 600,
                        zIndex: 9999
                    },
                });
            }
        });
    }

    _acceptDeclineEvent = (status) => {
        acceptDeclineEvent({event_id: this.props.eventId, status: status}).then((response) => {
            if (response.data.statusCode === 200) {
                status = status.toLowerCase();
                notification.open({
                message: 'Event Operation',
                description: 'Event '+ status + ' sucessfully.',
                    style: {
                        width: 600,
                        marginLeft: 335 - 600,
                        zIndex: 9999
                    },
                });
            }
        });
    }

    closeModal = () => {
        this.setState({
            eventDetail : ''
        })
        this.props.handleClick();
    }
    render() {
        var event = this.state.eventDetail != undefined ? this.state.eventDetail : null;
        let created_on = null;
        let event_date = null;
        let time = null;
        let attendingData = [];
        let status = 'PENDING';
        if(event) {
            created_on = event.created_on != undefined ? event.created_on.substring(0, 10) : '';
            if(event.event_startdatetime != undefined) {
                let date = new Date(event.event_startdatetime);
                time = date.getUTCHours() + ':' + date.getUTCMinutes();
                event_date = date.toISOString().substring(0, 10);   
            }
                   
            attendingData = event.attending != undefined ? event.attending : [];
            status = event.status;
        }
        if(status == 'ATTENDED') {
            $(".attendCheckbox").attr('checked', true);
            $(".attendCheckbox").attr('disabled', true);
        }
        else if(this.props.role == 3) {
            $(".attendCheckbox").attr('disabled', true);
        }
        else {
            $(".attendCheckbox").attr('checked', false);
        }

        return (
            <div className="event-modal" id="evntDetailsModal">
                <div className="event-wrap">
                    <div className="event-head">
                        <div className="row">
                            <div className="col-sm-6">
                                {this.props.role != 3 ?
                                    <div className="event-chack">
                                        <label htmlFor="e1">
                                            <input type="checkbox" className="attendCheckbox" id="e1" />
                                            <span></span>
                                            Mark as Attended
                                        </label>
                                    </div>
                                : null
                                }
                            </div>
                            <div className="col-sm-6">
                                {status == 'PENDING' && this.props.role != 3 ?
                                    <div className="card-drop">
                                        <button type="button" className="btn btn-secondary dropdown-toggle ml-auto"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src="/assets/images/d-icon.png" alt="" title="" />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <button onClick={this._acceptDeclineEvent.bind(this, 'ACCEPTED')} className="dropdown-item" type="button">Accept Event</button>
                                            <button onClick={this._acceptDeclineEvent.bind(this, 'DECLINED')} className="dropdown-item" type="button">Declien Event</button>
                                        </div>
                                    </div>
                                 : null }
                            </div>
                        </div>
                    </div>
                    <div className="modal-event-title">
                        <h3>{event && event.event_title != undefined ? event.event_title : ''}</h3>
                        <span>Event Created on {created_on}</span>
                    </div>
                    <div className="event-body">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="event-date-info">
                                    <label>Date</label>
                                    <div className="d-flex">
                                        <span>
                                            {event_date}
                                        </span>
                                        <span>
                                            {time}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="event-date-info">
                                    {attendingData.length > 0 ?
                                    <label>Attending</label> : null }
                                    <ul className="invite-list">
                                    {attendingData.map((attendee) => (
                                        <li>
                                            <a href="#">
                                            <div className="s-pro-outer">
                                                <div className="s-pro-inn">
                                                <img
                                                    src={attendee.profile_pic}
                                                    alt=""
                                                    title=""
                                                />
                                                </div>
                                            </div>
                                            </a>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr className="mr-t30 mr-b30" />
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="event-blog">
                                    <h4>Description</h4>
                                    <p>
                                        {event ? event.event_description: null}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr className="mr-t30 mr-b30" />
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="event-blog">
                                    <h4>Locations</h4>
                                    <div className="location-input">
                                        <img src="/assets/images/location-icon.png" alt="" title="" />
                                        <input type="text" value={event ? event.event_location : ''} autoComplete="false" placeholder="Type to add your location..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="event-comm-box">
                        <input type="text" name="" id="" placeholder="Add your comment" />
                        <button type="button" className="trans-btn">
                            <img src="/assets/images/att-icon.svg" alt="" title="" />
                        </button>
                        <button type="button" className="trans-btn">
                            <img src="/assets/images/send-icon.png" alt="" title="" />
                        </button>
                        <button type="button" className="send-btn" onClick={this.closeModal}>Send</button>
                        <button type="button" className="send-btn" style={{backgroundColor: '#000'}} onClick={this.closeModal}>Close</button>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Get State from Redux
 */
const mapStateToProps = createStructuredSelector({
    eventInfoById: getEventDetail
  });
  
  /**
   * Redux Dispatch action
   */
  const mapDispatchToProps = (dispatch) => ({
    getEventById: (eventInfo) => dispatch(getEventById(eventInfo))
  });
  
  /**
   * Export Event Class
   */
  export default connect(mapStateToProps, mapDispatchToProps)(CalendarModal);