import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getSettings,
  createSettings,
  updateSettings
} from '../../../redux/settings/settings.actions';
import { notification, Checkbox } from 'antd';
import { withRouter, Route } from 'react-router-dom';
import FormInput from '../../form-input/form-input.component';
import CustomButton from '../../custom-button/custom-button.component';
import Spinner from '../../spinner/spinner.component';
import { jwtDecode } from '../../../jwt-decode';
import '../settings.styles.scss';
import api from '../../../axios';
import {updateCompany} from '../../../redux/user/user.action';
import Dashboard from '../../dashboard/dashboard.component';
import { companyDetails } from '../../../redux/company/company.selector';

const initState = {
  approve_events_on_create: false,
  require_background_check: false,
  background_check_link: '',
  password: ''
};

const CompanyComponent = ({
   company,
   updateCompany,
  error,
}) => {
  const [state, setState] = useState(initState);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // For account type checking
  const userAuth = jwtDecode();
  const { _id, current_profile_type } = userAuth;

  // useEffect(() => {
  //   api.get(`/users/${_id}?population=["account", "company"]`, {
  //     headers: { auth: localStorage.getItem('token') }
  //   }).then(response => {
  //     setLoading(false);
  //     if (response.data) {
  //       setSettings(response.data?.data?.account);
  //     }
  //   }, (error)=> {
  //    setLoading(false);
  //   });
  // }, []);



  const onChange = (e) => {
    const { value, name } = e.target;
    if (
      name !== 'title'
    ) {
      return setState({
        ...state,
        location: {...(state?.location || {}), [name]: value}
      });
    }
    setState({ ...state, [name]: value });
  };

  const notify = () => {
    notification['success']({
      message: 'Success',
      description: 'Company have been update'
    });
  };
  const notifyError = (message) => {
    notification['error']({
      message: 'Error',
      description: message
    });
  };


  const handleAccountSubmit = () => {
    const settingsObj = { ...state, user: _id };
    // If user has no settings create them
    setSubmitting(true);
    updateCompany(null, state).then((res) => {
      setSubmitting(false);
      if (res?.status === 200) notify();
      else {
        if (error) notifyError(error);
      }
    });
  };

  const {

  } = state;

  return loading ? (
    <Spinner />
  ) : (
      <div className="col-sm-12">
        <div className="white-box event-form-wrap">
          <div className="event-form-block">
              <Fragment>
                {/* Background check Link*/}
                <div className="mt-2 form-group">
                  <FormInput
                    label="Company Name"
                    type= "text"
                    name="title"
                    value={company?.title}
                    disabled = {userAuth.current_profile_type == 'Volunteer'}
                    placeholder=""
                    className="form-control"
                    handleChange={onChange}
                  />
                </div>
                <div className="mt-2 form-group">
                  <FormInput
                    label="Street Address"
                    type= "text"
                    name="street"
                    value={company?.location?.street}
                    placeholder=""
                    disabled = {userAuth.current_profile_type == 'Volunteer'}
                    className="form-control"
                    handleChange={onChange}
                  />
                </div>
                <div className="mt-2 form-group">
                  <FormInput
                    label="City"
                    type= "text"
                    name="city"
                    value={company?.location?.city}
                    placeholder=""
                    disabled = {userAuth.current_profile_type == 'Volunteer'}
                    className="form-control"
                    handleChange={onChange}
                  />
                  <FormInput
                    label="State"
                    type= "text"
                    name="state"
                    disabled = {userAuth.current_profile_type == 'Volunteer'}
                    value={company?.location?.state}
                    placeholder=""
                    className="form-control"
                    handleChange={onChange}
                  />
                  <FormInput
                    label="Zip"
                    type= "text"
                    name="zip"
                    disabled = {userAuth.current_profile_type == 'Volunteer'}
                    value={company?.location?.zip}
                    placeholder=""
                    className="form-control"
                    handleChange={onChange}
                  />
                </div>

              </Fragment>
          </div>
          {userAuth.current_profile_type == 'IndustryPartner' && <CustomButton disabled={submitting} className="btn submit-btn w-100" onClick={handleAccountSubmit}>
            Update Settings
          </CustomButton>}
        </div>
      </div>
  );
};

const mapStateToProps = (state) => ({
  company: state.user.userDetails.company,
  error:  state.user.error,
});

export default withRouter(
  connect(mapStateToProps, {updateCompany })(
    CompanyComponent
  )
);
