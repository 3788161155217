import { type } from './survey.types';
import api from '../../axios';

export const getSurvey = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.LOADING,
        payload: {}
      });

      const response = await api.get(`/survey/surveys/render/${id}`);
      dispatch({
        type: type.GET_SURVEY,
        payload: (response.data || {}).data
      });
    } catch (error) {
      dispatch({
        type: type.SURVEY_ERROR,
        error: error.data._meta.error
      });
    }
  };
};
export const submitSurvey = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.LOADING,
        payload: {}
      });

      const response = await api.post(`/survey/responses/save-response`, {survey: id, data});
      console.log('submited', response)
      dispatch({
        type: type.SUBMIT_SURVEY,
        payload: (response.data || {}).data
      });
      return response;
    } catch (error) {
      dispatch({
        type: type.SURVEY_ERROR,
        error: error.data.meta.error
      });
      return error.data.meta;
    }
  };
};
