import { type } from './setting.types';

const INITIAL_STATE = {
  isLoading: true,
  userSettings: null,
  error: null
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.GET_SETTINGS:
    case type.UPDATE_SETTINGS:
      return {
        ...state,
        isLoading: false,
        userSettings: action.payload
      };
    case type.SETTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export default settingsReducer;
