/**
 * Import NPM Packages
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, notification } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';

/**
 * Import Components
 */
import FormInput from '../../form-input/form-input.component';
import CustomButton from '../../custom-button/custom-button.component';

import { uploadUserAvatar, updateUser } from '../../../redux/user/user.action';
import { parseBlobUrl } from '../../../utils/helper';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Profile = (props) => {
  const { handleSubmit, register, errors, formState } = useForm({
    mode: 'onBlur'
  });
  const { isDirty, isSubmitting } = formState;

  const { uploadUserAvatar, updateUser, user } = props;

  const {
    userDetails: {
      _id,
      first_name,
      last_name,
      email,
      current_profile_type,
      avatar
    },
    loading
  } = user;
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
   if(avatar?.url) setImageUrl(parseBlobUrl(avatar?.url));
  }, [avatar?.url]);

  const handleAvatarUpload = (e) => {
    setUploadingAvatar(true);
    const file = e.target.files[0];

    if (!file) return;

    getBase64(file, (imageUrl) => setImageUrl(imageUrl));

    const data = new FormData();

    data.append('file', file);
    uploadUserAvatar(_id, data).then((res) => {
      setUploadingAvatar(false);
      console.log('res :', res);
      notification['success']({
        message: 'Success',
        description: 'Avatar uploaded successfully',
        style: {
          width: 600,
          marginLeft: 335 - 600
        }
      });
    });
  };

  const onSubmit = (values) => {
    console.log(values);
    updateUser(_id, {
      first_name: values.firstName,
      last_name: values.lastName
    });
  };

  const renderUpload = () => (
    <div className="avatar-uploader">
      <input
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
        className="d-none"
        onChange={handleAvatarUpload}
      />
      <div className="img-container">
        {uploadingAvatar && (
          <div className="img-container__loading">
            <LoadingOutlined />
          </div>
        )}
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
        ) : (
          <label htmlFor="avatar">
            <div className="img-container__btn">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </label>
        )}
      </div>
    </div>
  );

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="white-box event-form-wrap">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 mt-3">
              <Spin className="mt-1 mr-3" />{' '}
              <span>Preparing user details ...</span>
            </div>
          ) : (
            <div className="event-form-block">
              <div className="profile-container">
                <div className="row">
                  <div className="col-sm-12 col-md-8">
                    <div className="user-info">
                      <div className="user-info__avatar">{renderUpload()}</div>
                      <div className="user-info__content">
                        <h4 className="type">
                          Account Type: <span>{current_profile_type}</span>
                        </h4>
                        <p className="caption">
                          <span>Photos help others to recognize you.</span>
                          <label htmlFor="avatar">Upload new photo</label>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4"></div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row p-2">
                    <div className="col-sm-12">
                      <FormInput
                        label="Email"
                        type="email"
                        name="email"
                        defaultValue={email}
                        placeholder="Password"
                        className="form-control"
                        disabled
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <FormInput
                        label="First Name"
                        type="text"
                        name="firstName"
                        defaultValue={first_name}
                        placeholder="first name"
                        className="form-control"
                        showError={errors.firstName}
                        errorText={errors.firstName && 'First name is required'}
                        ref={register({
                          required: true
                        })}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <FormInput
                        label="Last Name"
                        type="text"
                        name="lastName"
                        defaultValue={last_name}
                        placeholder="last name"
                        className="form-control"
                        showError={errors.lastName}
                        ref={register({
                          required: true
                        })}
                        errorText={errors.lastName && 'Last name is required'}
                      />
                    </div>
                    <div className="col-sm-12">
                      <CustomButton
                        disabled={!isDirty || isSubmitting}
                        className="btn submit-btn"
                        type="submit"
                      >
                        Update Password
                      </CustomButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, { uploadUserAvatar, updateUser })(
  Profile
);
