export const schools = [
  {"value":"12", "text": "Pinkston"},
  {"value":"4235", "text": "Irma Lerma Rangel Young Women's Leadership School"},
  {"value":"42", "text": "William Hawley Atwell Law Academy"},
  {"value":"43", "text": "T. W. Browne MS"},
  {"value":"45", "text": "E.B. Comstock MS"},
  {"value":"46", "text": "Young Men's Leadership Academy @ Florence"},
  {"value":"47", "text": "Benjamin Franklin International Exploratory Academy"},
  {"value":"48", "text": "W. H. Gaston MS"},
  {"value":"49", "text": "W. E. Greiner Exploratory Arts Academy"},
  {"value":"50", "text": "Robert T. Hill MS"},
  {"value":"51", "text": "Oliver W.  Holmes Humanities/Communications Academy"},
  {"value":"52", "text": "Piedmont G.L.O.B.A.L. Academy"},
  {"value":"53", "text": "J.L. Long MS"},
  {"value":"54", "text": "Thomas C. Marsh Preparatory Academy"},
  {"value":"55", "text": "Thomas J. Rusk MS"},
  {"value":"56", "text": "E. D. Walker MS"},
  {"value":"58", "text": "Alex W. Spence Talented/Gifted Academy"},
  {"value":"59", "text": "L.V. Stockard MS"},
  {"value":"60", "text": "Boude Storey MS"},
  {"value":"62", "text": "Billy Earl Dade MS"},
  {"value":"68", "text": "Raul Quintanilla Sr. MS"},
  {"value":"69", "text": "Seagoville MS"},
  {"value":"71", "text": "DESA (Dallas Environmental Science Academy)"},
  {"value":"72", "text": "Sarah Zumwalt MS"},
  {"value":"73", "text": "Henry W. Longfellow Career Exploration Academy"},
  {"value":"76", "text": "Henry W. Lang Sr. MS"},
  {"value":"77", "text": "Hector P. Garcia MS"},
  {"value":"79", "text": "Francisco \"Pancho\" Medrano Medrano MS"},
  {"value":"83", "text": "Sam Tasby MS"},
  {"value":"100", "text": "Zan Wesley Holmes Jr. MS"},
  {"value":"206", "text": "Alex Sanger Prep"},
  {"value":"352", "text": "Young Women's STEAM Academy @ Balch Springs"},
  {"value":"353", "text": "Ann Richards STEAM Academy"},
  {"value":"354", "text": "Kennedy-Curry MS"},
  {"value":"360", "text": "DA Hulcy STEAM MS"},
  {"value":"363", "text": "School For The Talented and Gifted - Pleasant Grove"},
  {"value":"11", "text": "Barbara Manns"},
  {"value":"381", "text": "Barack Obama Male Leadership Academy"},
  {"value":"263", "text": "J.P. Stark Math, Science & Technology Vanguard"},
  {"value":"362", "text": "Ignite MS"},
  {"value":"134", "text": "George Bannerman Dealy Montessori Academy"},
  {"value":"359", "text": "Rosemont Upper School"},
  {"value":"212", "text": "Harry Stone Montessori"},
  {"value":"270", "text": "Eduardo Mata Montessori"},
  {"value":"306", "text": "Solar Prep"},
  {"value":"384", "text": "Sudie L. Williams TAG Academy"},
  {"value":"217", "text": "William B. Travis Academy/Vanguard for the Academically Talented and Gifted"},
  {"value":"173", "text": "Sidney Lanier Expressive Art Vanguard"},
  {"value":"186", "text": "Trinity Heights Gifted and Talented"}
].sort((a,b)=> {
  if (a.text > b.text) return 1;
  if (a.text < b.text) return -1;
  return 0;
});
export const genders = [
  {
    value: "M",
    text: "Male"
  },
  {
    value: "F",
    text: "Female"
  },
  {
    value: "O",
    text: "Other"
  },
  {
    value: "no-answer",
    text: "I choose not to say"
  }
];
export const races = [
  {
    value: "non-hispanic-white",
    text: "Non-Hispanic White"
  },
  {
    value: "black",
    text: "Black/African American"
  },
  {
    value: "hispanic_latino",
    text: "Hispanic/Latino"
  },
  {
    value: "native-american",
    text: "Native Americans/Alaska Natives"
  },
  {
    value: "native-hawaiian_pacific-islanders",
    text: "Native Hawaiians/Other Pacific Islanders"
  },
  {
    value: "asian",
    text: "Asian"
  }
];



