import { type } from './employees.types';
const INITIAL_STATE = {
  isLoading: true,
  error: null,
  users: null
};


const employeesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.GET_USERS:
      return {
        ...state,
        isLoading: false,
        error: null,
        users: action.payload.data,
        pagination: action.payload._meta.pagination
      };
    case type.EMPLOYEE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export default employeesReducer;
