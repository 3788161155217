/**
 * Import NPM Packages
 */
import React from 'react';

/**
 * Import  s
 */
import './attending.styles.scss';

/**
 * Attending Component
 */
const Attending = ({ attendingData }) => {
  if (typeof attendingData !== 'undefined') {
    return (
      <div className="member-pro-list">
        <p className="color-gary font12 muli-semiBold text-uppercase mr-b10">
          Attending
        </p>
        <ul className="pl-3 mr0 pd0">
          {/* {attendingData.map((attendee, index) => (
            <li key={index}>
              <a href="#">
                <div className="s-pro-outer">
                  <div className="s-pro-inn">
                    <img
                      src={attendee.avatar && attendee.avatar.url}
                      alt="..."
                    />
                  </div>
                </div>
              </a>
            </li>
          ))} */}
          <li>
            <div className="s-pro-outer">
              <div className="s-pro-inn">
                <h4>
                  {attendingData.length}
                  {/* {attendingData.length > 1
                      ? '+' + attendingData.length - 2
                      : 0} */}
                </h4>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  } else return <div></div>;
};

/**
 * Export Attending Component
 */
export default Attending;
