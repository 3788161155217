import React, { Fragment } from 'react';
import Select from 'react-select';
import { selectStyles } from '../selectStyles';
import PropTypes from 'prop-types';

const options = [
  { value: 'ALL', label: 'ALL' },
  { value: 'IndustryPartner', label: 'INDUSTRY PARTNERS' },
  { value: 'Volunteer', label: 'VOLUNTEERS' }
];

const SearchBar = ({
  handleUserSearch,
  userType,
  userFilterType,
  setUserFilterType
}) => {
  return (
    <Fragment>
      <div className="col-sm-4 text-right pr-0">
        <div className="input-group">
          <input
            style={{ background: '#f2f4f9', minHeight: '50px' }}
            className="form-control"
            type="search"
            placeholder="Search for email and first or last name"
            id="searchField"
            onChange={(e) => handleUserSearch(e)}
          />
          {/* <span className="input-group-append">
            <button className="btn " type="button">
              <i className="fa fa-search"></i>
            </button>
          </span> */}
        </div>
      </div>
      {userType === 'Admin' && (
        <div className="col-sm-3 pl-2">
          <div className="name-filter">
            <Select
              name="options"
              value={userFilterType}
              options={options}
              onChange={(e) => setUserFilterType(e)}
              styles={selectStyles}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
SearchBar.propTypes = {
  handleUserSearch: PropTypes.func.isRequired,
  setUserFilterType: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired
};

export default SearchBar;
